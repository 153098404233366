<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>

	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Radio Button'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="default">Default</option>
					<option value="selected-disabled">Radio button (disabled selected)</option>
					<option value="instructional">Radio button with instructional text</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<form class="" [formGroup]="form">
					<div class="page-live-example" [class.page-live-show]="currLive === 'default'">
						<kendo-formfield showHints="initial">
							<label class="k-label">Type of Confirmation</label>
							<ul class="k-radio-list">
								<li class="k-radio-item">
									<div class="k-radio-block">
										<input type="radio" #phoneCall value="phoneCall" kendoRadioButton
											formControlName="confirmation" />
										<kendo-label [for]="phoneCall" text="Phone Call"></kendo-label>
									</div>
								</li>
								<li class="k-radio-item">
									<div class="k-radio-block">
										<input type="radio" #email kendoRadioButton value="email"
											formControlName="confirmation" />
										<kendo-label [for]="email" text="Via Email"></kendo-label>
									</div>
								</li>
								<li class="k-radio-item">
									<div class="k-radio-block">
										<input type="radio" disabled name="foo" value="one" #disabledAttribute
											kendoRadioButton />
										<kendo-label [for]="disabledAttribute"
											text="Radio button disabled with the disabled attribute"></kendo-label>
									</div>
								</li>
								<li class="k-radio-item">
									<div class="k-radio-block">
										<input type="radio" value="auth" #auth kendoRadioButton
											formControlName="confirmation" />
										<kendo-label [for]="auth" text="Authenticator Application"></kendo-label>
									</div>
								</li>
							</ul>
						</kendo-formfield>
					</div>
					<div class="page-live-example"
						[class.page-live-show]="currLive === 'selected-disabled' || currLive === 'instructional'">
						<kendo-formfield showHints="initial">
							<label class="k-label">Type of Confirmation</label>
							<ul class="k-radio-list">
								<li class="k-radio-item">
									<div class="k-radio-block">
										<input type="radio" [disabled]="currLive === 'selected-disabled'" checked
											value="sms" #sms kendoRadioButton />
										<kendo-label [for]="sms" text="Via SMS"></kendo-label>
									</div>
									<ng-container *ngIf="currLive === 'instructional'">
										<kendo-formhint class="k-radio-formhint" [class.disabled]="isDisabled">Radio
											button instructional text</kendo-formhint>
									</ng-container>
								</li>
							</ul>
						</kendo-formfield>
					</div>
				</form>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark">{{ radioHTML }}</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/inputs/api/RadioButtonDirective/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Radio Button API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/radio-button#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="sectionTitleBehaviorsFocus" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li>
					A focus state replaces the browser default focus
					state for radio buttons.
				</li>
			</ul>
			<div class="page-section-content-split"></div>
		</section>
		<section class="page-sub-section" id="behaviors-disabled">
			<h3 id="sectionTitleBehaviorsDisabled" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-disabled')">
				Disable <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Disabling a radio button or radio button group removes the
					hover state and click events. Disabled radio buttons have an
					opacity of 50% on both the indicator and the label.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-instructional-text">
			<h3 id="sectionTitleBehaviorsInstructionalText" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('behaviors-instructional-text')">
				Instructional text <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Instructional text can appear below the radio button label. It should not be
					italicized as italic font is not readable.
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/radio_button/radio_button_intructional_text.png">
					</div>
					<span class="redlines-caption">Example of radio button with label and instructional text.</span>
				</li>
			</ul>
		</section>
	</section>
	
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/radio_button/radio_button_label.png">
					</div>
					<span class="redlines-caption">Example of selected radio button with label.</span>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/radio_button/radio_button_unselected.png">
					</div>
					<span class="redlines-caption">Example of unselected radio button with label.</span>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-do-list-title">Do:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Use radio buttons when users need to make a single,
							mutually exclusive choice.
						</li>
						<li class="page-section-list-item">
							Have a radio button selected by default for a radio
							button group.
						</li>
						<li class="page-section-list-item">
							Use 2 or more radio buttons.
						</li>
					</ul>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item" style="margin-top: 5px">
					<span class="page-section-do-list-title">Don't:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Use radio buttons when users can select more than
							one option, <a href="components/checkbox">use checkboxes instead</a>.
						</li>
					</ul>
				</li>
			</ul>
		</section>
		
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Radio buttons should be listed after a colon or a header
					that makes it clear to the user they're required to make a
					choice.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										Choose the account:
									</li>
									<li class="comparison-body-text-list-item">
										Personal
									</li>
									<li class="comparison-body-text-list-item">
										Shared
									</li>
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										<i>or</i>
									</li>
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										Account Options:
									</li>
									<li class="comparison-body-text-list-item">
										Personal
									</li>
									<li class="comparison-body-text-list-item">
										Shared
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Personal
									</li>
									<li class="comparison-body-text-list-item">
										Shared
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>

				<li class="page-section-list-item" style="margin-top: 10px">
					Radio buttons shouldn’t use punctuation like periods,
					parentheses, or colons. If needed, a dash can be used to add
					a definition or further context.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										Choose the account:
									</li>
									<li class="comparison-body-text-list-item">
										Personal &#8212; only you can access
									</li>
									<li class="comparison-body-text-list-item">
										Shared &#8212; everyone can access
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Personal; (only you can access).
									</li>
									<li class="comparison-body-text-list-item">
										Shared; (everyone can access).
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top: 10px">
					Radio buttons can be in title case if they are only a few
					words. Anything longer should be sentence case.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										Choose the account:
									</li>
									<li class="comparison-body-text-list-item">
										Personal Account
									</li>
									<li class="comparison-body-text-list-item">
										Shared Account
									</li>

									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										<i>or</i>
									</li>
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										Account Options:
									</li>
									<li class="comparison-body-text-list-item">
										Personal &#8212; only you can access
									</li>
									<li class="comparison-body-text-list-item">
										Shared &#8212; everyone can access
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										Choose the account:
									</li>
									<li class="comparison-body-text-list-item">
										personal account
									</li>
									<li class="comparison-body-text-list-item">
										shared account
									</li>

									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										<i>or</i>
									</li>
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										Account Options:
									</li>
									<li class="comparison-body-text-list-item">
										Personal &#8212; Only You Can Access
									</li>
									<li class="comparison-body-text-list-item">
										Shared &#8212; Everyone Can Access
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top: 10px">
					Radio buttons can also use instructional text and tooltips
					to add additional definitions or explanations. Help text and
					tooltips should add extra value &#8212; they shouldn’t just
					repeat what the radio button label says. Instructional text appears below the
					label.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										Label: Associate with Smart Rule
									</li>
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										Help text: Smart Rules are ways to
										organize assets.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										Label: Associate with Smart Rule
									</li>
									<li class="comparison-body-text-list-item" style="
											padding: 0;
											list-style-type: none;
											margin-left: -20px;
										">
										Help text: The application will be
										associated with a Smart Rule.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top: 10px">
					Radio buttons can also contain text fields and drop-downs
					that allow users to enter a selection. If a user enters
					content in a field that is not currently selected, that
					radio button automatically becomes selected.
				</li>
			</ul>
		</section>

		<section class="page-sub-section" id="usage-position">
			<h3 id="subSectionTitleUsagePosition" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-position')">
				Position <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Radio buttons should typically be vertically stacked with
					each button on its own line. If the labels are short and
					there are only 2-3 buttons, they can be horizontal and on a
					single line to save space.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/checkbox">Checkbox component</a>
			</li>
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/help-text">Help text component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tooltip">Tooltip component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
					let subItem of changelogService.getChangelogBySection(
						'Radio Button Component'
					)
				">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
						subItem.workId
					">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>