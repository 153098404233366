<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Help Text'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change example">
					<option value="default">Default</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<div class="page-live-example" [class.page-live-show]="currLive === 'default'">
					<button kendoButton [svgIcon]="circleQuestion" kendoTooltip title="Help" kendoPopoverAnchor
						[popover]="myPopover"></button>
					<kendo-popover #myPopover [animation]="myAnimation" [width]="200" title="Credentials"
						body="You must select a managed system first before associating a credential."></kendo-popover>
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-started">
			<h3 id="subSectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			Added to your project's app.module.ts file:
			<code style="margin-top: 10px">import &#123; PopoverModule &#125; from
				'&#64;progress/kendo-angular-tooltip';
			</code>
		</section>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark">
				{{ helpTextHTML }}
			</pre>
			<pre prism class="dark">
				{{ helpTextTs }}
			</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/tooltips/popover/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Popover API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/help-text#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="subSectionTitleBehaviors" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus state <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Help text appears when a user activates the container that has its trigger. When the user selects
					outside the help text box, focuses on something else, or navigates away with their keyboard, it
					disappears.
				</li>
				<li class="page-section-list-item">
					When opened, the help text is in the focused state by default to help screen readers indicate it has
					appeared. The only state help text can have is focused. When it’s not focused, it disappears.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-animation">
			<h3 id="subSectionTitleBehaviorsB" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-animation')">
				Animation <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Help text appears with an animation lasting 250ms, as mentioned in the <a
						href="guidelines/motion">motion guidelines</a>. Help text
					disappears at the speed set by Kendo.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-disabled">
			<h3 id="subSectionTitleBehaviorsB" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-disabled')">
				Disabled <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Help text and its trigger can be disabled if their containing element is disabled and the help text
					is not
					relevant in that state. However, if the help text is still relevant in some way, perhaps explaining
					why
					the container may be disabled, the triggering element and the help text can remain enabled.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title page-section-title-empty"
			title="Copy Link to Clipboard" (click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-section-content">
			There are no variations for this component.
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Help text is not meant to be used as a modal and shouldn’t be used for confirming actions or
					information.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Label</span>
					The label should be brief, no more than a few words.
					The label should be specific to what the help text addresses.
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Body</span>
					Help text shouldn’t just repeat the copy that’s already visible on the UI. It also shouldn’t contain
					critical information that the user needs to succeed.
					Instead of relying on help text to explain difficult concepts, revisit the clarity of the design and
					utilize existing component text.
				</li>
				<li class="page-section-list-item">
					Be sure to not make the content in a help text pop-up too complicated or technical. You can link a
					technical document or other help resource instead.
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Images</span>
					Help text can contain additional content like
					<a href="components/text-link">text links</a> and images. Images should not exceed 440px
					wide, including padding. Images should be informative, never decorative.
					Consider if it adds value to the user, or if it will distract them. Images need to include alt text
					to be accessible.
				</li>
			</ul>
			<ul class="page-section-comparison-list">
				<li class="page-section-comparison-list-item comparison-do">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/Status/checkmark</title>
							<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
									id="" fill="#000000" />
							</g>
						</svg>
						Try This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								You can customize the format for command line parameters.
								<a href="https://www.beyondtrust.com/docs/beyondinsight-password-safe/ps/admin/add-applications.htm"
									target="_blank">View the defaults.</a>

							</li>
						</ul>
					</div>
				</li>
				<li class="page-section-comparison-list-item comparison-do-not">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/NavObjects/close</title>
							<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
									id="add2" fill="#000000"
									transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
							</g>
						</svg>
						Not This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Optional. Specify the format for any command line parameters.
							</li>
							<li class="comparison-body-text-list-item">managed account name = %u </li>
							<li class="comparison-body-text-list-item">managed account password = %p </li>
							<li class="comparison-body-text-list-item">managed asset name = %h </li>
							<li class="comparison-body-text-list-item">managed asset IP = %i </li>
							<li class="comparison-body-text-list-item">database port = %t </li>
							<li class="comparison-body-text-list-item">database instance or asset name = %d </li>
							<li class="comparison-body-text-list-item">jump host dns = %n </li>
							<li class="comparison-body-text-list-item">database dns = %s </li>
							<li class="comparison-body-text-list-item">access URL = %w </li>
						</ul>
					</div>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-placement">
			<h3 id="subSectionTitleUsagePlacement" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-placement')">
				Placement <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Help text should appear near the trigger, but not hide it or any other relevant UI elements— if
					possible.
					Help text should be concise, consider how you can keep the character count low through word choice
					and
					<a href="components/text-link">text links</a>. When help text hides other relevant content it can
					cause users frustration.
				</li>
				<li class="page-section-list-item">
					Help text also shouldn't be cut off by the edges of the browser window in any direction.
					In these situations, move the help text to the nearest place that doesn't cover important elements
					or get cut off.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-help-text-or-tooltip">
			<h3 id="subSectionTitleUsageHelp" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-help-text-or-tooltip')">
				Help text or tooltip <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Help text:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							For longer informational content, like detailed instructions
						</li>
						<li class="page-section-list-item">
							Displayed by selecting an <a href="components/icons">icon</a>, often a question mark
						</li>
						<li class="page-section-list-item">
							Can contain different formats of content, including images
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Tooltips:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							For shorter informational content, like quick definitions and short explanations
						</li>
						<li class="page-section-list-item">
							Displayed on either focus or hover of an element
						</li>
						<li class="page-section-list-item">
							Content is text only with little to no formatting
						</li>
					</ul>
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/icons">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/motion">Motion guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/text-link">Text Link component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tooltip">Tooltip component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>

		<span class="page-related-list-title">Components and patterns that use
			help text:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/checkbox">Checkbox component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/data-grid">Data grid component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/date-picker">Date picker component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/radio-button">Radio button component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/switch">Switch component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/text-field">Text field component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/time-picker">Time picker component</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Help Text Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
