import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { shCircleCheck, shCircleInfo, shCircleSlash, shCircleExclamation } from '@beyondtrust/shield-icons';
import { shLink } from '@beyondtrust/shield-icons';
@Component({
  selector: 'sh-custom-toast',
  template: `
    <kendo-progressbar [value]="time" [animation]="true" [max]="total" [label]="false"></kendo-progressbar>
    <kendo-svg-icon [icon]="icon"></kendo-svg-icon>
    <p class="sh-toast-content">{{ content }}</p>
    <button kendoButton themeColor="primary" fillMode="flat" (click)="done()">Dismiss</button>
  `,
  styles: []
})
export class ToastTemplateComponent implements OnDestroy {
  private intervalId: any = null;
  public time: number = 0;
  public total: number = 0;
  public linkIcon = shLink;
  
  public get icon() {
    if (this.toastType === 'success') {
      return shCircleCheck;
    } else if (this.toastType === 'info') {
      return shCircleInfo;
    } else if (this.toastType === 'warning') {
      return shCircleExclamation;
    } else {
      return shCircleSlash;
    }
  }

  @Input() public toastType: string;
  @Input() public content: string = '';

  @Output() public onComplete: EventEmitter<void> = new EventEmitter();

  public ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  public start(ms: number): void {
    this.total = ms;
    this.time = ms;
    this.startCountdown();
  }

  private startCountdown(): void {
    this.intervalId = setInterval(() => {
      this.time -= 10;
      if (this.time < 0) {
        this.done();
      }
    }, 10);
  }

  public pause(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  public resume(): void {
    if (!this.intervalId) {
      this.startCountdown();
    }
  }

  public done(): void {
    this.onComplete.emit();
    clearInterval(this.intervalId);
  }
}
