<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Tag'" [includeDescription]="true" [otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="default">Default</option>
					<option value="system">System</option>
					<option value="user">User</option>
					<option value="severity">Severity</option>
					<option value="counter">Counter</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
				<!-- <div class="page-live-example-controls">
					<button class="button" (click)="resetTags()">Reset live example</button>
				</div> -->
			</div>
			<div class="example-content">
				<div class="page-live-example-controls" [class.hide]="currLive === 'counter' || currLive === 'system'">
					<button class="button" (click)="resetTags()">Reset live example</button>
				</div>
				<ng-template #tooltipTemplate let-anchor>
					<ng-container *ngIf="anchor.nativeElement.closest('.k-chip') as tag">
						<span *ngIf="anchor.nativeElement.classList.contains('k-chip-icon')">
							{{ tag.getAttribute('tooltip-label') }}
						</span>
						<span *ngIf="anchor.nativeElement.closest('.k-chip-remove-action') !== null">
							Remove tag
						</span>
					</ng-container>
				</ng-template>
				<div class="live-example" [class.page-live-show]="currLive === 'default'">
					<div kendoTooltip [tooltipTemplate]="tooltipTemplate" filter=".k-svg-icon">
						<kendo-chip *ngFor="let tag of defaultTags" [label]="tag.label" [svgIcon]="tag.icon"
							[attr.tooltip-label]="tag.tooltipLabel" [removable]="tag.removable" [removeSvgIcon]="xIcon"
							(remove)="removeTag(tag)" [ngClass]="tag.class">
						</kendo-chip>
					</div>
				</div>
				<div class="live-example" style="margin-top: 90px" [class.page-live-show]="currLive === 'system'">
					<div kendoTooltip [tooltipTemplate]="tooltipTemplate" filter=".k-svg-icon">
						<kendo-chip *ngFor="let tag of systemTags" [label]="tag.label" [svgIcon]="tag.icon"
							[attr.tooltip-label]="tag.tooltipLabel" [ngClass]="tag.class" [removeSvgIcon]="xIcon">
						</kendo-chip>
					</div>
				</div>
				<div class="live-example" [class.page-live-show]="currLive === 'user'">
					<div kendoTooltip [tooltipTemplate]="tooltipTemplate" filter=".k-svg-icon">
						<kendo-chip *ngFor="let tag of userTags" [label]="tag.label" [svgIcon]="tag.icon"
							[attr.tooltip-label]="tag.tooltipLabel" [removable]="tag.removable" 
							[removeSvgIcon]="xIcon" (remove)="removeUserTag(tag)" [ngClass]="tag.class">
						</kendo-chip>
					</div>
				</div>
				<div class="live-example" [class.page-live-show]="currLive === 'severity'">
					<ng-template #severityTemplate>Remove Tag</ng-template>
					<div kendoTooltip [tooltipTemplate]="severityTemplate" filter=".k-chip-actions .k-svg-icon">
						<kendo-chip *ngFor="let tag of severityTags"
							[label]="tag.label"
							[removable]="tag.removable"
							[removeSvgIcon]="xIcon"
							[ngClass]="tag.class"
							(remove)="removeSeverityTag(tag)">
						</kendo-chip>
					</div>
				</div>
				<div class="live-example" style="margin-top: 90px" [class.page-live-show]="currLive === 'counter'">
					<kendo-chip
						class="sh-counter-tag"
						kendoPopoverAnchor
						[popover]="myPopover"
						label="+3"
						#anchor="kendoPopoverAnchor"
						(contentClick)="anchor.toggle()"
						>
					</kendo-chip>
					<kendo-popover #myPopover position="top" [offset]="-2">
						<ng-template kendoPopoverBodyTemplate>
							<kendo-chiplist class="sh-tag-block">
								<kendo-chip class="sh-tag-system" label="Dormant Account" [svgIcon]="settings"> </kendo-chip>
								<kendo-chip class="sh-tag-system" label="Dormant Account" [svgIcon]="settings"> </kendo-chip>
								<kendo-chip class="sh-tag-system" label="Dormant Account" [svgIcon]="settings"> </kendo-chip>
							</kendo-chiplist>
						</ng-template>
					</kendo-popover>
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-code">
			<h3 id="sectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<kendo-tabstrip #tabs class="code-example-tabstrip">
				<kendo-tabstrip-tab title="Default" [selected]="true">
					<ng-template kendoTabContent>
						<div class="content">
							<pre prism class="dark page-pre-code" style="max-width: unset;">
								{{ tagsDefaultHTML }}
							</pre>
						</div>
					</ng-template>
				</kendo-tabstrip-tab>
				<kendo-tabstrip-tab title="System">
					<ng-template kendoTabContent>
						<div class="content">
							<pre prism class="dark page-pre-code" style="max-width: unset;">
								{{ tagsSystemHTML }}
							</pre>
						</div>
					</ng-template>
				</kendo-tabstrip-tab>
				<kendo-tabstrip-tab title="User">
					<ng-template kendoTabContent>
						<div class="content">
							<pre prism class="dark page-pre-code" style="max-width: unset;">
								{{ tagsUserHTML }}
							</pre>
						</div>
					</ng-template>
				</kendo-tabstrip-tab>
				<kendo-tabstrip-tab title="Severity">
					<ng-template kendoTabContent>
						<div class="content">
							<pre prism class="dark page-pre-code" style="max-width: unset;">
								{{ tagsSeverityHTML }}
							</pre>
						</div>
					</ng-template>
				</kendo-tabstrip-tab>
				<kendo-tabstrip-tab title="Counter">
					<ng-template kendoTabContent>
						<div class="content">
							<pre prism class="dark page-pre-code" style="max-width: unset;">
								{{ tagsCounterHTML }}
							</pre>
						</div>
					</ng-template>
				</kendo-tabstrip-tab>
			</kendo-tabstrip>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/buttons/api/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Chips API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/tag#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>

					<span class="page-style-list-title">Default / Counter</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">System</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultSystem" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">User</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultUser" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Low Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultLowSeverity" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Moderate Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultModerateSeverity" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">High Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultHighSeverity" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Critical Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultCriticalSeverity" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Undetermined Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultUndetermined" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>

					<span class="page-style-list-title">Default / Counter</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">System</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkSystem" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">User</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkUser" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Low Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkLowSeverity" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Moderate Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkModerateSeverity" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">High Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkHighSeverity" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Critical Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkCriticalSeverity" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Undetermined Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkUndetermined" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="sectionTitleBehaviorsRemove" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					The tag’s border color changes on focus.  
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-remove">
			<h3 id="sectionTitleBehaviorsRemove" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-remove')">
				Remove <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Tags can be configured to be removed. This appears as a small remove “X” icon that has a hover state and tooltip, 
					like the text field clear icon. Selecting this icon removes the tag. 
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-default">
			<h3 id="sectionTitleVariationsDefault" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-no-icon')">
				Default (standard) <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Known as chips in Kendo. A standard tag that can be configured to be removed.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-system">
			<h3 id="sectionTitleVariationsDefault" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-system')">
				System generated <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					System generated tags cannot be added or removed by users.  
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-user">
			<h3 id="sectionTitleVariationsDefault" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-user')">
				User generated <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					User generated tags are custom tags that users create.   
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-severity">
			<h3 id="sectionTitleVariationsSeverity" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-severity')">
				Severity <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Severity tags have a colored background and border that follows the
					<a href="guidelines/severity">severity guidelines</a>.
					Severity tags function the same as default tags.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-filter">
			<h3 id="sectionTitleVariationsFilter" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-filter')">
				Filter <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Filter tags are like label/value pairs. There is a label followed by a colon, and then the pair followed by a down arrow. 
					Users select their option from the drop-down list and the filter responds. Filter tags also have a remove icon. (These are currently only used in data tables/grids.) 
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-counter">
			<h3 id="sectionTitleVariationsFilter" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-counter')">
				Counter <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Counter tags are clickable. Once clicked, a list of hidden tags appear in a popover. 
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="sectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Use tags when grouping, sorting or labeling information.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="sectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Tag labels are typically short, 1-2 words. If more description is needed and the
					tag is longer, use sentence case. Icons can be paired with a tag label and should
					be arranged to the left of the text.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-icons">
			<h3 id="sectionTitleUsageIcons" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-icons')">
				Icons <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Icons can be paired with a tag label and should be arranged to the left of the text.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-accessibility">
			<h3 id="sectionTitleUsageIcons" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-accessibility')">
				Accessibility <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A tag’s meaning should not be communicated by color alone. Support the meaning of a tag 
					with appropriate text. This helps users who cannot see color, or who are using assistive technology.  
				</li>
				<li class="page-section-list-item">
					Color contrast ratios should pass the standards for WCAG AA 2.1 compliance.
				</li>
			</ul>
		</section>
	</section>

	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/label-value">Label/Value pair component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/severity">Severity guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>	
		</ul>
		<ul class="page-section-list page-related-list">

		</ul>
		<span class="page-related-list-title">Components and patterns that use Tags:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/data-grid">Data grid component</a>
			</li>
			<li class="page-related-list-item">
				<a href="patterns/filter">Filter pattern</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item"
				*ngFor="let subItem of changelogService.getChangelogBySection('Tag Component')">
				<span class="page-history-list-item-title">
					{{changelogService.formatDate(subItem.changeDate)}}
				</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''"
					[attr.href]="'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' + subItem.workId">
					[{{ subItem.workId }}]
				</a>
				{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
