<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<p class="page-section-content">
			Severity is the framework for scoring and differentiating the
			different degrees of risk. We use color, words, and components to
			communicate severity.
		</p>
	</section>
	<section class="page-section" id="principles">
		<h2 id="sectionTitlePrinciples" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('principles')">
			Principles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>

		<ul class="page-section-list">
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Simple</span>
				Risks are complicated and nuanced but categorizing them should
				be simple. Stick to the determined levels.
			</li>
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Consistent</span>
				Consistency in our severity framework, like consistency in all
				other areas of design, helps our users confidently navigate our
				products. Use the same risk scoring across all products.
			</li>
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Accessible</span>
				All users should be able to understand and assess risks,
				regardless of differences in ability.
			</li>
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Contextual</span>
				Severity scores are contextual, which means risk scores can be
				higher or lower depending on the business and its specific
				account privileges. We should approach severity considering
				these differences.
			</li>
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Dynamic</span>
				Severity can change once risks are mitigated. While our
				out-of-the-box solution for scoring is standard, we should
				remember that our users may have the potential and desire for
				customization in the future.
			</li>
		</ul>
	</section>

	<section class="page-section" id="demonstrating-severity">
		<h2 id="sectionTitleDemonstratingSeverity" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('demonstrating-severity')">
			Demonstrating severity <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="demonstrating-severity-with-color">
			<h3 id="subSectionTitleDemonstratingSeverityWithColor" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('demonstrating-severity-with-color')">
				Communicating severity with color <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Do use the standard colors associated with each risk level —
					these colors have been researched and tested. Don’t use the
					same colors between dark theme and light theme.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li>
										<img src="\assets\images\severity01.png" alt="" style="margin-top: 5px" />
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li>
										<img src="\assets\images\severity02.png" alt="" style="margin-top: 5px" />
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="demonstrating-severity-with-words">
			<h3 id="subSectionTitleDemonstratingSeverityWithWords" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('demonstrating-severity-with-words')">
				Communicating severity with words <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Do always use the same adjectives for the corresponding risk
					score or privilege level. Don’t use synonyms or alternative
					words.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Low
									</li>
									<li class="comparison-body-text-list-item">
										Moderate
									</li>
									<li class="comparison-body-text-list-item">
										High
									</li>
									<li class="comparison-body-text-list-item">
										Critical
									</li>
									<li class="comparison-body-text-list-item">
										No Privilege
									</li>
									<li class="comparison-body-text-list-item">
										Undetermined
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Mild
									</li>
									<li class="comparison-body-text-list-item">
										Medium
									</li>
									<li class="comparison-body-text-list-item">
										Important
									</li>
									<li class="comparison-body-text-list-item">
										Severe
									</li>
									<li class="comparison-body-text-list-item">
										Zero
									</li>
									<li class="comparison-body-text-list-item">
										Unknown
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="demonstrating-severity-with-components">
			<h3 id="subSectionTitleDemonstratingSeverityWithComponents" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="
					copyToClipboard('demonstrating-severity-with-components')
				">
				Communicating severity with components <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Dots</span>
					The amount of filled dots corresponds with the risk level. 1
					dot is low, 2 dots is moderate, and so on.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li>
										<img src="\assets\images\severity01.png" alt="" width="205" height="197"
											style="margin-top: 5px" />
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li>
										<img src="\assets\images\severity03.png" alt="" width="149" height="47"
											style="margin-top: 5px" />
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Tags</span>
					Tags with a label and a color can also communicate severity.
					Use the correct words and colors mentioned above.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li>
										<img src="\assets\images\severity--tags.png" alt="" style="margin-top: 5px" />
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li>
										<img src="\assets\images\severity--tags-dont.png" alt=""
											style="margin-top: 5px" />
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Charts</span>
					Charts that use the severity colors and labeling to display
					severity data to the user.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li>
										<img src="\assets\images\severity--charts-do.png" alt="" style="margin-top: 5px"
											height="275" />
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li>
										<img src="\assets\images\severity--charts-dont.png" alt=""
											style="margin-top: 5px" height="275" />
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<!-- <li class="page-related-list-item">
                <a href="components/tags">Tag component</a>
            </li> -->
			<li class="page-related-list-item">
				<a href="components/bar-chart">Bar chart component</a>
			</li>
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/donut-chart">Donut chart component</a>
			</li>
			<li class="page-related-list-item">
                <a href="components/line-chart">Line chart component</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/pie-chart">Pie chart component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/severity">Severity component</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
					let subItem of changelogService.getChangelogBySection(
						'Severity Guidelines'
					)
				">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
						subItem.workId
					">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>