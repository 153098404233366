<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="what-is-shield">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('what-is-shield')">
			What is Shield? <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Shield is a collaborative, research-based set of guidelines and examples to inform the design and
				development of
				BeyondTrust products.
			</li>
		</ul>
	</section>
	<section class="page-section" id="create-update-shield">
		<h2 id="sectionTitlePrinciples" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('create-update-shield')">
			Who created Shield? Who updates it?  <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Shield is managed and built by our design system team and UX engineers. It is collectively created with
				Shield
				stakeholders and users involved in the process. Requests for information to be added or updated go on
				the <a href="https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/boards/369"
					target="_blank">Shield board</a>.
			</li>
		</ul>
	</section>
	<section class="page-section" id="publishing">
		<h2 id="sectionTitleBasicsAndTools" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('publishing')">
			How does content get published in Shield?  <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Shield content combines a review of our existing products, best practices from industry experts,
				accessibility musts, our own research findings, and Kendo documentation.
			</li>
		</ul>
	</section>
	<section class="page-section" id="goals">
		<h2 id="sectionTitleBasicsAndTools" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('goals')">
			What is the goal of Shield?  <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Shield hopes to create a better employee experience by making it easier to build our products. It also
				aims to improve
				our customers’ experience by collecting the best-of-the-best UX standards that will improve the
				usability of our
				products.
			</li>
		</ul>
	</section>
	<section class="page-section" id="audience">
		<h2 id="sectionTitleBasicsAndTools" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('audience')">
			Who can use Shield?  <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Shield’s primary audience is BeyondTrust product designers and engineers, but everyone can use it.
			</li>
		</ul>
	</section>
	<section class="page-section" id="new-designs">
		<h2 id="sectionTitleBasicsAndTools" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('new-designs')">
			What if my design differs from what’s in Shield? <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				There will be exceptions to Shield and the guidance can change. If there is a specific case where you
				think the user
				experience would improve if the design deviated from Shield, contact our team or make a feature request
				in the Shield
				board. From here, the design system team will evaluate and update the guidance as needed.
			</li>
		</ul>
	</section>
	<section class="page-section" id="measuring">
		<h2 id="sectionTitleBasicsAndTools" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('measuring')">
			How are products measured against Shield and how do we determine if a system follows Shield? <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Shield serves as the standard for which our products will be measured against during Quality Assurance
				testing and
				product audits.
			</li>
		</ul>
	</section>
	<section class="page-section" id="is-shield-complete">
		<h2 id="sectionTitleBasicsAndTools" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('is-shield-complete')">
			Is Shield complete?  <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				No, Shield is constantly iterative. It will always be updated and improved as BeyondTrust evolves.
			</li>
		</ul>
	</section>
	<section class="page-section" id="kendo">
		<h2 id="sectionTitleBasicsAndTools" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('kendo')">
			Why do we use Kendo?  <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				The decision to use Kendo for complex components was made by the leaders of BeyondTrust’s Technology
				organization. Kendo
				helped increase the velocity and efficiency on Identity Security Insights.
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history  <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'FAQ'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>yeah
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>