<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Accordion'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change example">
					<option value="border-single">Bordered (single)</option>
					<option value="border-stacked">Bordered (stacked)</option>
					<option value="borderless-single">Borderless (single)</option>
					<option value="borderless-stacked">Borderless (stacked)</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<div class="page-live-example" [class.page-live-show]="currLive === 'border-single'">
					<kendo-expansionpanel [title]="step_5_title" [expanded]="false" [disabled]="false"
						[animation]="duration" [svgExpandIcon]="down" [svgCollapseIcon]="up">
						<div class="content">
							<div class="form-item">
								<div class="title">Go to <b>Okta API Scopes</b>. Grant access to:</div>
								<ul>
									<li>okta.apps.read</li>
									<li>okta.groups.read</li>
									<li>okta.idps.read</li>
									<li>okta.logs.read</li>
									<li>okta.policies.read</li>
									<li>okta.roles.read</li>
									<li>okta.users.read</li>
								</ul>
							</div>
						</div>
					</kendo-expansionpanel>
				</div>
				<div class="page-live-example" [class.page-live-show]="currLive === 'border-stacked'">
					<kendo-expansionpanel [title]="step_1_title" [expanded]="false" [disabled]="false"
						[animation]="duration" [svgExpandIcon]="down" [svgCollapseIcon]="up">
						<div class="content">
							<div class="form-item">
								<div class="title">Name the connector and enter your Okta domain.</div>
								<kendo-label class="k-form-field" text="Connector Name">
									<kendo-textbox [style.width.px]="400"></kendo-textbox>
								</kendo-label>
								<kendo-label class="k-form-field" text="Okta Domain URL">
									<kendo-textbox [style.width.px]="400"></kendo-textbox>
								</kendo-label>
							</div>
						</div>
					</kendo-expansionpanel>
					<kendo-expansionpanel [title]="step_2_title" [expanded]="false" [disabled]="false"
						[animation]="duration" [svgExpandIcon]="down" [svgCollapseIcon]="up">
						<div class="content">
							<div class="form-item">
								<div class="title">Create a new App integration</div>
								<ol>
									<li>Go to <b>Applications</b> in Okta.</li>
									<li>Select <b>Create App Integration.</b></li>
									<li>Choose the API Services option.</li>
									<li>Name the App integration “BT Insights - Connector” and Save.</li>
								</ol>
							</div>
						</div>
					</kendo-expansionpanel>
					<kendo-expansionpanel [title]="step_3_title" [expanded]="false" [animation]="duration"
						[svgExpandIcon]="down" [svgCollapseIcon]="up">
						<div class="content">
							<div class="form-item">
								<ol>
									<li>Back in <b>Client Credentials</b>, select <b>Edit</b>.</li>
									<li>Choose <b>Public key / Private key</b> next to Client authentication.</li>
									<li>Select <b>Add key</b> and <b>Generate new key</b>.</li>
									<li>Choose <b>PEM</b> under <b>Private key</b>.</li>
									<li>Select <b>Copy to clipboard</b>, then <b>Done</b>. Paste below.</li>
								</ol>
								<kendo-label class="k-form-field" text="PEM Key">
									<kendo-textarea [style.width.px]="400" [rows]="3" resizable="none"></kendo-textarea>
								</kendo-label>
							</div>
						</div>
					</kendo-expansionpanel>
				</div>
				<div class="page-live-example" [class.page-live-show]="currLive === 'borderless-single'">
					<kendo-expansionpanel [title]="step_5_title" [expanded]="false" [disabled]="false"
						[animation]="duration" [svgExpandIcon]="down" [svgCollapseIcon]="up" class="sh-accordion-flat">
						<div class="content">
							<div class="form-item">
								<div class="title">Go to <b>Okta API Scopes</b>. Grant access to:</div>
								<ul>
									<li>okta.apps.read</li>
									<li>okta.groups.read</li>
									<li>okta.idps.read</li>
									<li>okta.logs.read</li>
									<li>okta.policies.read</li>
									<li>okta.roles.read</li>
									<li>okta.users.read</li>
								</ul>
							</div>
						</div>
					</kendo-expansionpanel>
				</div>
				<div class="page-live-example" [class.page-live-show]="currLive === 'borderless-stacked'">
					<kendo-expansionpanel [title]="step_1_title" [expanded]="false" [disabled]="false"
						[animation]="duration" [svgExpandIcon]="down" [svgCollapseIcon]="up" class="sh-accordion-flat">
						<div class="content">
							<div class="form-item">
								<div class="title">Name the connector and enter your Okta domain.</div>
								<kendo-label class="k-form-field" text="Connector Name">
									<kendo-textbox [style.width.px]="400"></kendo-textbox>
								</kendo-label>
								<kendo-label class="k-form-field" text="Okta Domain URL">
									<kendo-textbox [style.width.px]="400"></kendo-textbox>
								</kendo-label>
							</div>
						</div>
					</kendo-expansionpanel>
					<kendo-expansionpanel [title]="step_2_title" [expanded]="false" [disabled]="false"
						[animation]="duration" [svgExpandIcon]="down" [svgCollapseIcon]="up" class="sh-accordion-flat">
						<div class="content">
							<div class="form-item">
								<div class="title">Create a new App integration</div>
								<ol>
									<li>Go to <b>Applications</b> in Okta.</li>
									<li>Select <b>Create App Integration.</b></li>
									<li>Choose the API Services option.</li>
									<li>Name the App integration “BT Insights - Connector” and Save.</li>
								</ol>
							</div>
						</div>
					</kendo-expansionpanel>
					<kendo-expansionpanel [title]="step_3_title" [expanded]="false" [animation]="duration"
						[svgExpandIcon]="down" [svgCollapseIcon]="up" class="sh-accordion-flat">
						<div class="content">
							<div class="form-item">
								<ol>
									<li>Back in <b>Client Credentials</b>, select <b>Edit</b>.</li>
									<li>Choose <b>Public key / Private key</b> next to Client authentication.</li>
									<li>Select <b>Add key</b> and <b>Generate new key</b>.</li>
									<li>Choose <b>PEM</b> under <b>Private key</b>.</li>
									<li>Select <b>Copy to clipboard</b>, then <b>Done</b>. Paste below.</li>
								</ol>
								<kendo-label class="k-form-field" text="PEM Key">
									<kendo-textarea [style.width.px]="400" [rows]="3" resizable="none"></kendo-textarea>
								</kendo-label>
							</div>
						</div>
					</kendo-expansionpanel>
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-started">
			<h3 id="subSectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			Added to your project's app.module.ts file:
			<code style="margin-top: 10px">import &#123; LayoutModule &#125; from
				'&#64;progress/kendo-angular-layout';
			</code>
			<code style="margin-top: 10px">import &#123; ExpansionPanelComponent &#125; from
				'&#64;progress/kendo-angular-layout';
			</code>
		</section>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark">{{ accordionHTML }}</pre>
			<pre prism class="dark">{{ accordionTS }}</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/layout/api/ExpansionPanelComponent/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Accordion API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/accordion#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="subSectionTitleBehaviorsFocus" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A focus state replaces the browser default focus state for accordions. The focus state is
					around the entire accordion — not just the <a href="components/icon">icon</a> and title.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-hover">
			<h3 id="subSectionTitleBehaviorsHover" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-hover')">
				Hover <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					The hover state is around the accordion header — not the entire accordion.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-disable">
			<h3 id="subSectionTitleBehaviorsDisable" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-disable')">
				Disable <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Accordions can be disabled. If disabled, the header has 50% opacity and can’t be interacted with at
					all. Disabled accordions are typically closed.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-animation">
			<h3 id="subSectionTitleBehaviorsAnimation" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-animation')">
				Animation <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					The animation between changing states should be 250ms, per our <a href="guidelines/motion">motion
						guidelines</a>. The chevron arrow
					icon does not have a separate animation or visual change.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-content">
			<h3 id="subSectionTitleBehaviorsContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-content')">
				Content toggle <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Selecting the accordion’s title toggles between collapsing and expanding the list of content. The
					chevron arrow icon is to the right of the accordion title.
				</li>
				<li class="page-section-list-item">
					<img class="guidance-image" src="\assets\images\accordion_dimension.png" alt="" />
					<span class="redlines-caption">Example of a collapsed and an expanded accordion.</span>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-collapse">
			<h3 id="subSectionTitleBehaviorsCollapse" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-collapse')">
				Collapse <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Accordions are first displayed in a collapsed state to give users an overview of the content. When
					the accordion is collapsed, the list is hidden. The chevron arrow pointing down indicates collapsed.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-expand">
			<h3 id="subSectionTitleBehaviorsExpand" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-expand')">
				Expand <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When the accordion is expanded the list is visible. The chevron arrow pointing up indicates
					expanded. Users can expand multiple accordions at once.
				</li>
				<li class="page-section-list-item">
					Use full expand mode if the section has a limited height. Otherwise, use the single or multi-expand
					modes depending on use case.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-border">
			<h3 id="subSectionTitleVariationsBorder" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-border')">
				Bordered accordion <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					An accordion with a border.
				</li>
				<li class="page-section-list-item">
					There’s a border around accordion headers when it’s collapsed and around the
					header and content when it’s expanded.
				</li>
				<li class="page-section-list-item">
					There is no space between the stacked accordions.
				</li>
				<li class="page-section-list-item">
					<img class="guidance-image" src="\assets\images\accordion_border_dimension.png" alt="" />
					<span class="redlines-caption">Example of a stacked, bordered accordion.</span>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-borderless">
			<h3 id="subSectionTitleVariationsBorderless" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-borderless')">
				Borderless accordion <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					An accordion with no border.
				</li>
				<li class="page-section-list-item">
					There is a space between the stacked borderless accordions.
				</li>
				<li class="page-section-list-item">
					<img class="guidance-image" src="\assets\images\accordion_borderless_dimension.png" alt="" />
					<span class="redlines-caption">Example of a stacked, borderless accordion.</span>
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					There are two expand/collapse patterns for pages with multiple accordions. One pattern has multiple
					accordions open at a time. The other pattern has only one accordion open at a time. Use one pattern
					or the other.
					Do not mix the patterns in a single view and try to be consistent about when each pattern is used
					across an application.
				</li>
				<li class="page-section-list-item">
					An accordion’s content shouldn’t extend past the accordion and should be fully hidden when closed.
				</li>
				<li class="page-section-list-item">
					Do not stack bordered and borderless accordions together.
				</li>
				<li class="page-section-list-item">
					Horizontal scrolling in accordions should be avoided.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-do-list-title">Do use accordions:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							For content that’s not critical for a user to read
						</li>
						<li class="page-section-list-item">
							For small blocks of content, but whole sections
						</li>
						<li class="page-section-list-item">
							If there’s enough space to scroll vertically
						</li>
						<li class="page-section-list-item">
							To split up a form
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top: 5px">
					<span class="page-section-do-list-title">Don’t use accordions:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							If all the content on a page is necessary to read
						</li>
						<li class="page-section-list-item">
							In place of panels (which can contain whole sections)
						</li>
						<li class="page-section-list-item">
							To contain <a href="components/data-grid">data grids</a>, cards, panels or <a
								href="guidelines/data-visualization">charts</a> — data tables are fine
						</li>
						<li class="page-section-list-item">
							To contain a form’s submission buttons — unless it’s a wizard
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Accordion titles should be brief and specific. Aim for 3 or fewer words that clearly describe the
					accordion’s content. Titles should be in sentence case.
				</li>
				<li class="page-section-list-item">
					Most content format can go inside accordions. Be careful to not confuse accordions with panels that
					can contain whole sections of an application. Do not use grids, cards, panels, or charting in an
					accordion. Data tables are fine.
				</li>
				<li class="page-section-list-item">
					Accordions can be used to split up a form, but should not contain the form’s submission buttons.
					Wizards are an exception, as they may have an entire step in an accordion that includes the
					submission buttons.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-position">
			<h3 id="subSectionTitleUsagePosition" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-position')">
				Position <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Accordions can be on the main section of a page, or inside a side panel or tile. An accordion can,
					but does not have to, fill its container. There is no minimum or maximum height or width of an
					accordion.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/motion">Motion guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>

		<!-- <span class="page-related-list-title">Components and patterns that use
			accordions:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/overview">Placeholder component</a>
			</li>
			<li class="page-related-list-item">
				<a href="patterns/overview">Placeholder pattern</a>
			</li>
		</ul> -->
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Accordion Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
