import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { shCheck, shXmark, shLink } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-content',
	templateUrl: './content.component.html',
	styleUrls: ['./content.component.less'],
})
export class ContentComponent {
	public currVisible = 'overview';

	public doThisIcon = shCheck;
	public notThisIcon = shXmark;
	public linkIcon = shLink;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Voice and tone',
			value: 'voice-tone',
			empty: false,
			children: [
				{
					name: 'Voice principles',
					value: 'principles',
					empty: false,
					children: [],
				},
				{
					name: 'How to talk to users',
					value: 'talking-users',
					empty: false,
					children: [],
				},
				{
					name: 'Tone at different touchpoints',
					value: 'touchpoints',
					empty: false,
					children: [],
				}
			],
		},
		{
			name: 'Grammar and spelling',
			value: 'grammar-spelling',
			empty: false,
			children: [
				{
					name: 'Sentence structure',
					value: 'sentence',
					empty: false,
					children: [],
				},
				{
					name: 'Punctuation',
					value: 'punctuation',
					empty: false,
					children: [],
				},
				{
					name: 'Capitalization',
					value: 'capitalization',
					empty: false,
					children: [],
				},
				{
					name: 'Formatting',
					value: 'formatting',
					empty: false,
					children: [],
				},
			],
		},
		{
			name: 'Word choice',
			value: 'word-choice',
			empty: false,
			children: [
				{
					name: 'Calls to action (CTAs)',
					value: 'actions',
					empty: false,
					children: [],
				},
				{
					name: 'Links and labels',
					value: 'links-labels',
					empty: false,
					children: [],
				},
			],
		},
		{
			name: 'Accessiblity and inclusion',
			value: 'accessibility-inclusion',
			empty: false,
			children: [],
		},
	
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
