<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Data Grid'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example
			 <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="single">Single-select</option>
					<option value="multiple">Multi-select</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<kendo-grid 
					#singleGridDataBinding 
					[class.page-live-show]="currLive === 'single'"
					[kendoGridBinding]="singleGridView" 
					[selectable]="singleSelectableSettings" 
					[navigable]="true"
					[style.height]="gridHeight"
					[pageSize]="pageSize"
					[pageable]="true"
					[sortable]="true"
					[reorderable]="true"
					[resizable]="true"
					[scrollable]="'none'">

					<ng-template kendoGridNoRecordsTemplate>
						<div class="no-records">
							<p>There are no records to display.</p>
						</div>
					</ng-template>
				
					<ng-template kendoGridToolbarTemplate>
						<div class="sh-custom-toolbar">
							<div class="sh-toolbar-actions">
								<kendo-textbox [style.width.px]="250" placeholder="Quick Filter" [clearButton]="true"
									(valueChange)="onFilter($event)">
									<ng-template kendoTextBoxPrefixTemplate>
										<kendo-svg-icon [icon]="filterIcon"></kendo-svg-icon>
									</ng-template>
								</kendo-textbox>
								<kendo-button [svgIcon]="downloadIcon"></kendo-button>
								<kendo-button [svgIcon]="columnsIcon"></kendo-button>
								<kendo-button [svgIcon]="settingsIcon"></kendo-button>
							</div>
							<div class="sh-item-count-container">
								<strong class="sh-item-count">{{ singleGridView.length }} items</strong>
							</div>
						</div>
					</ng-template>

					<kendo-grid-checkbox-column [width]="45" [minResizableWidth]="20" [showSelectAll]="false"></kendo-grid-checkbox-column>
					<kendo-grid-column [minResizableWidth]="20" field="ProductName" title="Product Name">
						<ng-template kendoGridCellTemplate let-dataItem>
							<div class="sh-grid-row-cell">
							  {{ dataItem.ProductName }}
							</div>
						</ng-template>
					</kendo-grid-column>
					<kendo-grid-column [minResizableWidth]="20" field="UnitsInStock" title="Units In Stock" class="sh-grid-cell-right-align"
						headerClass="sh-grid-header-right-align"></kendo-grid-column>
					<kendo-grid-column [minResizableWidth]="20" field="UnitsOnOrder" title="Units On Order" class="sh-grid-cell-right-align"
						headerClass="sh-grid-header-right-align"></kendo-grid-column>
					<kendo-grid-column [minResizableWidth]="20" field="ReorderLevel" title="Reorder Level" class="sh-grid-cell-right-align"
						headerClass="sh-grid-header-right-align"></kendo-grid-column>

					<ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
						<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
						<kendo-pager-input></kendo-pager-input>
						<kendo-pager-next-buttons></kendo-pager-next-buttons>
						<kendo-pager-info></kendo-pager-info>
						<kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
					</ng-template>
				</kendo-grid>

				<kendo-grid 
					#multiGridDataBinding 
					[class.page-live-show]="currLive === 'multiple'"
					[kendoGridBinding]="multiGridView" 
					[selectable]="multiSelectableSettings" 
					[navigable]="true"
					[style.height]="gridHeight"
					[pageSize]="pageSize" 
					[pageable]="true" 
					[sortable]="true" 
					[reorderable]="true"
					[resizable]="true" [rowSelected]="isRowSelected" (selectionChange)="selectionChange($event)" 
					[scrollable]="'none'">
					<ng-template kendoGridNoRecordsTemplate>
						<div class="no-records">
							<p>There are no records to display.</p>
						</div>
					</ng-template>

					<ng-template kendoGridToolbarTemplate>
						<div class="sh-custom-toolbar">
							<div class="sh-toolbar-actions">
								<kendo-textbox [style.width.px]="250" placeholder="Quick Filter" [clearButton]="true"
									(valueChange)="onFilter($event)">
									<ng-template kendoTextBoxPrefixTemplate>
										<kendo-svg-icon [icon]="filterIcon"></kendo-svg-icon>
									</ng-template>
								</kendo-textbox>
								<kendo-button [svgIcon]="downloadIcon"></kendo-button>
								<kendo-button [svgIcon]="columnsIcon"></kendo-button>
								<kendo-button [svgIcon]="settingsIcon"></kendo-button>
							</div>
							<div class="sh-item-count-container">
								<strong class="sh-item-count">{{ multiGridView.length }} items</strong>
								{{ '(' }}{{ selectedRows.length }} selected |
								<a class="sh-item-count-selection" tabindex="0" aria-disabled="false"
									aria-haspopup="false" type="button" (click)="
										onSelectAllChange(
											selectAllState === 'checked'
												? 'unchecked'
												: 'checked'
										)
									">{{ selectedRows.length === multiGridView.length ? 'Deselect All' : 'Select all ' + multiGridView.length + '
									rows' }}</a>{{ ')' }}
							</div>
						</div>
					</ng-template>
					<kendo-grid-checkbox-column [width]="45" [minResizableWidth]="20">
						<ng-template kendoGridHeaderTemplate>
							<input #selectAllCheckbox type="checkbox" kendoCheckBox id="selectAllCheckbox"
								kendoGridSelectAllCheckbox [state]="selectAllState"
								(selectAllChange)="onSelectAllChange($event)" />
							<label class="k-checkbox-label" for="selectAllCheckbox"></label>
						</ng-template>
					</kendo-grid-checkbox-column>
					<kendo-grid-column [minResizableWidth]="20" field="ProductName" title="Product Name">
						<ng-template kendoGridCellTemplate let-dataItem>
							<div class="sh-grid-row-cell">
							  {{ dataItem.ProductName }}
							</div>
						</ng-template>
					</kendo-grid-column>
					<kendo-grid-column [minResizableWidth]="20" field="UnitsInStock" title="Units In Stock" class="sh-grid-cell-right-align"
						headerClass="sh-grid-header-right-align"></kendo-grid-column>
					<kendo-grid-column [minResizableWidth]="20" field="UnitsOnOrder" title="Units On Order" class="sh-grid-cell-right-align"
						headerClass="sh-grid-header-right-align"></kendo-grid-column>
					<kendo-grid-column [minResizableWidth]="20" field="ReorderLevel" title="Reorder Level" class="sh-grid-cell-right-align"
						headerClass="sh-grid-header-right-align"></kendo-grid-column>

					<ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
						<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
						<kendo-pager-input></kendo-pager-input>
						<kendo-pager-next-buttons></kendo-pager-next-buttons>
						<kendo-pager-info></kendo-pager-info>
						<kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
					</ng-template>
				</kendo-grid>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark page-pre-code" [hidden]="currLive !== 'single'">
				{{ singleGridHTML }}
			</pre>

			<pre prism class="dark page-pre-code" [hidden]="currLive !== 'multiple'">
				{{ multiGridHTML }}
			</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/api/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Grid API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/data-grid#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Checkbox</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultCheckbox" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Icon</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultIcon" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Pagination</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultPagination" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Grid text button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultTextLink" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Checkbox</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkCheckbox" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Icon</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkIcon" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Pagination</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkPagination" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Grid text button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkTextLink" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-empty">
			<h3 id="subSectionTitleBehaviorsEmpty" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-empty')">
				Empty state <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A grid is in an empty state when there is no data to
					display, an error happens when loading the data, or a filter
					has no matching rows.
				</li>
				<li class="page-section-list-item">
					There’s a difference between a grid that’s still loading
					data and a grid that has no data to display.
				</li>
				<li class="page-section-list-item">
					A message in the middle of the grid notifies the user why
					the grid is empty. This can include a button to fix the
					problem, an icon to indicate there was an error, or just a
					brief message.
				</li>
				<li class="page-section-list-item">Minimum height for an empty state grid/table is dependent on the scenario. If vertical space permits the set height should be set to 400px.</li>
				<li class="page-section-list-item">
					Kendo UI has
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/accessories/no-records-template/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>a template</a>
					for empty states, but we should customize it.
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="\assets\images\redlines\data_grid\datagrid_empty.png" width="700" alt="Empty data grid" />
					</div>
					<span class="redlines-caption">Example of data grid in empty state.</span>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-loading">
			<h3 id="sectionTitleBehaviorsLoading" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-loading')">
				Loading state <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When data is loading a repeating progress indicator line
					appears directly above the grid header. This progress
					indicator is the full width of the grid.
				</li>
				<li class="page-section-list-item">
					Until the loading is done, all functionality is disabled.
					This includes filters, grid headers, and pagination. Once
					loading finishes the functionality is restored and the
					progress indicator disappears.
				</li>

				<li class="page-section-list-item">
					You can override
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/accessories/loading-template/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo’s default loading template</a>
					with a progress indicator.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-row-selection">
			<h3 id="sectionTitleBehaviorsRowSelection" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-row-selection')">
				Row selection <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					On both single and multi-select grids, any area in a grid
					row that isn't used for an interactive element can toggle
					the row selection.
				</li>
				<li class="page-section-list-item">
					All rows in a Kendo multi-select grid are de-selected when a
					user clicks or taps anywhere in the row but the <a href="components/checkbox">checkbox</a>.
					That row is then selected instead.
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/selection/row-selection/#toc-single-row-selection"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Row Selection</a>
				</li>
				<li class="page-section-list-item">
					On multi-select grids, users can also select the <a href="components/checkbox">checkbox</a> to
					the left of the first column or the
					indeterminate checkbox in the header. Indeterminate
					checkboxes are not checked or unchecked, but rather display
					a dash in the middle and control the selection of all rows.
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/selection/row-selection/#toc-select-all-checkbox"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo guide on select-all checkbox.</a>
				</li>
				<li class="page-section-list-item">
					Rows stay selected after a user performs an action. This
					applies to any number of rows across any number of pages.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-row-actions">
			<h3 id="sectionTitleBehaviorsRowActions" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-row-actions')">
				Row actions <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					The last column in a grid is used for the actions for that
					row. If there are no additional actions besides viewing the
					details, an informational icon (i) is shown. If there are
					several row actions, a vertical ellipsis icon is shown and
					opens a menu of those actions.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-bulk-actions">
			<h3 id="sectionTitleBehaviorsBulkActions" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-bulk-actions')">
				Bulk actions <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					On multi-select grids that support bulk actions, a user can
					select one or more rows and the bulk action options appear
					above the grid header to the top left.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-grid-actions">
			<h3 id="sectionTitleBehaviorsGridActions" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-grid-actions')">
				Grid actions <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Grid actions appear in the top right of the component, above
					the header.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Filter</span>
					A grid filter is included in the actions by default. This
					might be removed in favor of using the larger filter
					pattern. On the user side, the filter can directly work on
					the data in the grid, but on the server-side the developer
					needs to reach back out to the server to perform filtering.
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/filtering/basics/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Filtering Basics</a>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Refresh</span>
					When selected, refreshes the data in the grid.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Download</span>
					Users can download the grid data to a CSV file. Filters do
					not change the dataset downloaded — the whole data grid
					contents are downloaded. Like the grid filter, server-side
					downloads need to be activated from the server using the
					provided event emitters.
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/#toc-export-to-pdf-and-excel"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Exporting Basics</a>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Column chooser</span>
					A drop-down appears when selecting the columns icon that
					lets users toggle column visibility. This should be kept
					linked to the current user so when they revisit the data
					grid it remembers their chosen columns.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Grid configuration</span>
					A menu appears that holds any extra options of the data
					grid, including the ability to switch back to the defaults
					for column positions, widths, and number of columns
					displayed.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-expand-collapse">
			<h3 id="subSectionTitleBehaviorsExpandCollapse" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('behaviors-expand-collapse')">
				Expand and collapse <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					If a row in the data grid has child rows, there’s a down
					chevron icon after the selected column.
				</li>
				<li class="page-section-list-item">
					This chevron expands a section below the current row that
					shows the child rows. At this point the chevron icon points
					up and selecting it again collapses the child rows.
				</li>
				<li class="page-section-list-item">
					Child rows have the same columns as their parent.
				</li>
				<li class="page-section-list-item">
					A border is used to divide the last child row from the
					parent row. Lines to the left of the child rows link back to
					the parent row to show the relationship between parent and
					child rows.
				</li>
				<li class="page-section-list-item">
					The total data grid row counts do not take child rows into
					consideration.
				</li>
				<li class="page-section-list-item">
					Kendo uses a plus and minus sign for expand and collapse by
					default. These should be changed to chevrons.
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/master-detail/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Expand and Collapse</a>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-item-selection-counts">
			<h3 id="sectionTitleBehaviorsItemSelectionCounts" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('behaviors-item-selection-counts')">
				Item and selection counts <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					In the top left of the grid header is the count of items in
					the grid, followed by the number of selected rows.
					Single-select and multi-select data grids show this
					differently. If the data grid has child rows, keep in mind
					the item count doesn't include them.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Single-select data grids</span>
					These grids only show the grid total until a row is
					selected. When a row is selected it changes to add
					<strong>(1 Selected)</strong> after the row count.
				</li>
				<li class="page-section-list-item">
					Example: <strong>50 Items</strong> (1 Selected)
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Multi-select data grids</span>
					Multi-select grids show the item total, the number of rows
					selected, and a text link that lets a user select all rows
					in the grid, not just the current page.
				</li>
				<li class="page-section-list-item">
					Example: <strong>50 Items</strong> (4 Selected |
					<span style="font-size: 16px; text-decoration: underline">Select all 50 items</span>)
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Exceptions</span>
					Exceptions are when the grid is in an error state or has no
					data, both single-select and multi-select grids show
					<strong>0 Items</strong>.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-sorting">
			<h3 id="sectionTitleBehaviorsSorting" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-sorting')">
				Sorting <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Clicking on a sortable column header begins (or reverses)
					the sorting on that column. Columns sort ascending on first
					click, and a second click switches it to descending. A third
					click removes the sorting completely on that column.
				</li>
				<li class="page-section-list-item">
					The column titles appear as selectable when hovered over
					with the pointer cursor. If a sort is applied, a small up or
					down arrow appears next to the column title. If the arrow is
					up, the order is ascending, if the arrow is down, the order
					is descending.
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/sorting/basics/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Sorting Basics</a>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-column-resizing">
			<h3 id="sectionTitleBehaviorsColumnResizing" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-column-resizing')">
				Column resizing <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Clicking and dragging a column header, if drag and drop is
					enabled, allows a user to drag that column to a new
					location. When the user drops the column header in the new
					position, this immediately changes the ordering of all data
					in the grid.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-column-reordering">
			<h3 id="sectionTitleBehaviorsColumnReordering" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-column-reordering')">
				Column reordering <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					If drag and drop is enabled, dragging a column header allows
					a user to drag that column to a new location. When the user
					drops the column header in the new position, this
					immediately changes the ordering of all data in the grid.
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/columns/reordering/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Column Reordering</a>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-hidden-columns">
			<h3 id="sectionTitleBehaviorsHiddenColumns" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-hidden-columns')">
				Hidden columns <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					To help with smaller browser sizes and large numbers of
					columns, data grids should allow for the visibility of a
					column to be turned off – making it hidden. This list of
					hidable columns should appear in the data grid configuration
					above the grid header.
				</li>
				<li class="page-section-list-item">
					Only a limited number of columns should be visible by
					default – whatever makes sense for the dataset and product.
					The columns a user chooses to show should remain when they
					revisit the page.
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/columns/hidden/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Hiding Columns</a>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-scrolling">
			<h3 id="sectionTitleBehaviorsHiddenColumns" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-scrolling')">
				Scrolling <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Data grids fill their parent container but can also scroll
					vertically and, if it can't be avoided, horizontally.
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/scroll-modes/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Scrolling Basics</a>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Vertical</span>
					If there is even one row in a data grid, a vertical
					scrollbar is visible. This makes it easier for the header to
					be in alignment with the rows.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Horizontal</span>
					Horizontal scrollbars should be avoided whenever possible
					but can appear just above the pagination controls. This lets
					users see the columns that disappear off to the right of the
					visible data grid. Use sticky or locked columns.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-sticky-locked-columns">
			<h3 id="sectionTitleBehaviorsStickyLockedColumns" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('behaviors-sticky-locked-columns')">
				Sticky and locked columns <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Kendo has two features for "freezing" specific columns while
					scrolling — sticky and locked. They are the same concept but
					different code. Both options have the same functionality,
					except locked has certain limitations.
				</li>
				<li class="page-section-list-item">
					Since there is nothing locked can do that sticky can't, use
					sticky columns for the majority of use cases — unless you’re
					certain locked is the correct approach.
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/columns/sticky/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Sticky Columns</a>
				</li>
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/grid/columns/locked/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Locked Columns</a>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-pagination">
			<h3 id="sectionTitleBehaviorsPagination" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-pagination')">
				Pagination <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Every grid with at least one row of data has a pagination
					control at the bottom. The pagination is disabled until the
					grid has finished loading.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-disabled">
			<h3 id="sectionTitleBehaviorsDisabled" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-disabled')">
				Disabled <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Disabled grids have a 50% opacity. They cannot be clicked,
					focused, or interacted with in any way. This applies to the
					header, grid actions, and pagination too.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-single-select">
			<h3 id="sectionTitleVariationsSingleSelect" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-single-select')">
				Single-select <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					The first data column has an empty column to the left of it
					to allow for a checkmark icon that shows which row is
					selected. This column doesn't have a header title.
				</li>
				<li class="page-section-list-item">
					If the grid is both single-select and there is only one row
					action, clicking on the row performs this action. This
					action is usually <strong>Show Details</strong>.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-multi-select">
			<h3 id="subSectionTitleVariationsMultiSelect" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-multi-select')">
				Multi-select <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Grids that allow for multi-select show a <a href="components/checkbox">checkbox</a> in the
					selection column for individual rows, and an indeterminate
					checkbox in the header to select every row.
				</li>
				<li class="page-section-list-item">
					The grid header information in the top right always displays
					the number of selected rows and a text link that lets users
					select all rows in the grid.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title" style="margin-bottom: 5px">Column titles</span>
					Column header titles should briefly and accurately describe
					the column contents. They should be informative but
					scannable.
					<ul class="page-section-do-list" style="margin-top: 5px">
						<li class="page-section-list-item">
							Aim for a few words
						</li>
						<li class="page-section-list-item">
							No more than 2 lines
						</li>
						<li class="page-section-list-item">Never truncate</li>
						<li class="page-section-list-item">
							Title case
						</li>
						<li class="page-section-list-item">
							Include units of measurement, if applicable
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Cell values</span>
					<ul class="page-section-do-list" style="margin-top: 5px">
						<li class="page-section-list-item">Be concise</li>
						<li class="page-section-list-item">
							Truncate longer values or wrap by no more than 2
							lines (unless unavoidable)
						</li>
						<li class="page-section-list-item">
							No cell should be empty. Cells without a value
							should show two hyphens <strong>--</strong>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title" style="margin-bottom: 5px">Components in cells</span>
					Cells can also contain components:
					<ul class="page-section-do-list" style="margin-top: 5px">
						<li class="page-section-list-item">Buttons</li>
						<li class="page-section-list-item">Checkboxes</li>
						<li class="page-section-list-item">
							Icons (paired with text)
						</li>
						<li class="page-section-list-item">
							Menus and vertical ellipsis menu icon
						</li>
						<li class="page-section-list-item">Severity</li>
						<li class="page-section-list-item">Tags</li>
						<li class="page-section-list-item">Text Links</li>
					</ul>
					Try not to mix and match components in a single cell.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Icons</span>
					<ul class="page-section-list">
						<li class="page-section-list-item">
							Icons that don’t use color to indicate meaning, like
							product logos, can be a range of colors. There is a
							default icon color depending on light theme or dark
							theme.
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-placement">
			<h3 id="subSectionTitleUsagePlacement" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-placement')">
				Placement <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Data grids extend to the edge of their parent container,
					ignoring padding and margins. However, there should be a gap
					between the bottom of the grid and the bottom of the browser
					or page.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content-order">
			<h3 id="subSectionTitleUsageContentOrder" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content-order')">
				Content order <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Information should be organized in a logical, meaningful
					way, such as chronologically or alphabetically.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-cell-alignment">
			<h3 id="subSectionTitleUsageCellAlignment" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-cell-alignment')">
				Cell alignment <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					In most cases the column title and column cells should share
					the same alignment. Different rules exist based on types of
					value are in the cell:
				</li>
				<li class="page-section-list-item">
					<span class="page-section-do-list-title">Left-aligned:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Text that is read left-to-right, like English. This
							includes text paired with icons.
						</li>
						<li class="page-section-list-item">
							Numbers that are more abstract, like IP addresses
							and port numbers.
						</li>
					</ul>

					<span class="page-section-do-list-title">Right-aligned:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Text that is read right-to-left, like Arabic. This
							includes text paired with icons.
						</li>
						<li class="page-section-list-item">
							Numbers like counts, totals, and calculated values.
						</li>
						<li class="page-section-list-item">Menu Icons.</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-data-grid-vs-data-table">
			<h3 id="subSectionTitleUsageDataGridVsDataTable" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('usage-data-grid-vs-data-table')">
				Data grid or data table <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Data grids and data tables have some overlap in features.
					Use a data grid when presenting datasets where row selections,
					row actions, or bulk actions are necessary. Data tables have more
					limited capabilities than data grids and should be used for
					read-only datasets where row actions or selections are not necessary.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="guidelines/accessibility">Accessibility guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/checkbox">Checkbox component</a>
			</li>
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/data-table">Date Table component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/drop-down">Drop-down component</a>
			</li>
			<li class="page-related-list-item">
				<a href="patterns/filter">Filter pattern</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>

			<li class="page-related-list-item">
				<a href="components/menu">Menu component</a>
			</li>
			<!--
			<li class="page-related-list-item">
				<a href="components/pagination">Pagination component</a>
			</li>-->
			<li class="page-related-list-item">
				<a href="components/progress-indicator">Progress indicator component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/severity">Severity component</a>
			</li>

			<li class="page-related-list-item">
				<a href="components/tag">Tag component</a>
			</li>

			<li class="page-related-list-item">
				<a href="components/text-link">Text link component</a>
			</li>

			<li class="page-related-list-item">
				<a href="components/text-field">Text field component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tooltip">Tooltip component</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
					let subItem of changelogService.getChangelogBySection(
						'Data Grid Component'
					)
				">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
						subItem.workId
					">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
