<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>

	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Tab'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body example-container-dark">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="default">Horizontal</option>
					<option value="error">Horizontal with error and icon</option>
					<option value="vertical">Vertical</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<div class="page-live-example" [class.page-live-show]="currLive === 'default' || currLive === 'error'">
					<kendo-tabstrip #tabs>
						<kendo-tabstrip-tab title="Accounts" [selected]="true">
							<ng-template kendoTabContent>
								<div class="header">
									<h2>Privilege, Detections, and Recommendations</h2>
								</div>
							</ng-template>
						</kendo-tabstrip-tab>
						<kendo-tabstrip-tab title="Connectors">
							<ng-template kendoTabContent>
								<div class="header">
									<h2>Available Connectors</h2>
								</div>
							</ng-template>
						</kendo-tabstrip-tab>
						<kendo-tabstrip-tab title="Detections">
							<ng-template kendoTabContent>
								<div class="header">
									<h2>Overview</h2>
								</div>
							</ng-template>
						</kendo-tabstrip-tab>
						<kendo-tabstrip-tab title="Identities">
							<ng-template kendoTabContent>
								<div class="header">
									<h2>Privilege, Detections, and Recommendations</h2>
								</div>
							</ng-template>
						</kendo-tabstrip-tab>
						<kendo-tabstrip-tab [class]="currLive === 'error' ? 'sh-tab-error' : ''">
							<ng-template kendoTabTitle> <kendo-svg-icon [icon]="error" *ngIf="currLive === 'error'"
									themeColor="error"></kendo-svg-icon>Recommendations </ng-template>
							<ng-template kendoTabContent>
								<div class="header">
									<h2>Overview</h2>
								</div>
							</ng-template>
						</kendo-tabstrip-tab>
					</kendo-tabstrip>
				</div>
				<div class="page-live-example" [class.page-live-show-vertical]="currLive === 'vertical'">
					<kendo-tabstrip [tabPosition]="position">
						<kendo-tabstrip-tab title="Accounts" [selected]="true">
							<ng-template kendoTabContent>
								<div class="content">
									<h2>Privilege, Detections, and Recommendations</h2>
								</div>
							</ng-template>
						</kendo-tabstrip-tab>
						<kendo-tabstrip-tab title="Connectors">
							<ng-template kendoTabContent>
								<div class="content">
									<h2>Available Connectors</h2>
								</div>
							</ng-template>
						</kendo-tabstrip-tab>
						<kendo-tabstrip-tab title="Detections">
							<ng-template kendoTabContent>
								<div class="content">
									<h2>Overview</h2>
								</div>
							</ng-template>
						</kendo-tabstrip-tab>
						<kendo-tabstrip-tab title="Identities">
							<ng-template kendoTabContent>
								<div class="content">
									<h2>Privilege, Detections, and Recommendations</h2>
								</div>
							</ng-template>
						</kendo-tabstrip-tab>
						<kendo-tabstrip-tab title="Recommendations">
							<ng-template kendoTabContent>
								<div class="content">
									<h2>Overview</h2>
								</div>
							</ng-template>
						</kendo-tabstrip-tab>
					</kendo-tabstrip>
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-started">
			<h3 id="subSectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			Added to your project's app.module.ts file:
			<code style="margin-top: 10px">import &#123; LayoutModule &#125; from
							'&#64;progress/kendo-angular-layout';</code>
		</section>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark page-pre-code" style="max-width: unset;">
		        {{ tabsHTML }}
			</pre>
		</section>
		<!-- <section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<details class="page-api-detail">
				<summary class="page-api-summary">Input Bindings</summary>
				<ul class="page-api-list">
					<li class="page-api-list-item">
						<span class="page-api-list-item-span"><b>disabled</b>: boolean = false</span>
						<p>
							Disables the Tab by removing click events, hover cursor, and adding an opacity style.
						</p>
					</li>
					<li class="page-api-list-item">
						<span class="page-api-list-item-span"><b>selection</b>: string</span>
						<p>
							The label to appear to the right of the icon.
							Optional.
						</p>
					</li>
					<li class="page-api-list-item">
						<span class="page-api-list-item-span"><b>selectionChangeInterceptor</b>: () =>
							Promise&lt;void&gt;</span>
						<p>Intercept selection changes asynchronously with the ability to conditionally prevent them
							from happening.</p>
					</li>
				</ul>
			</details>
			<details class="page-api-detail">
				<summary class="page-api-summary">Output Bindings</summary>
				<ul class="page-api-list">
					<li class="page-api-list-item">
						<span class="page-api-list-item-span"><b>selectionChange</b>:
							EventEmitter&lt;string&gt;</span>
						<p>Outputs the id of the active Tab after changing. This can be caused by keyboard or mouse
							selections of the Tab.</p>
					</li>
				</ul>
			</details>
		</section> -->
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
					<!-- <span class="page-style-list-title">Vertical</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of verticalStylesDefault" [data]="item"></app-style-row>
					</ul> -->
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
					<!-- <span class="page-style-list-title">Vertical</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of verticalStylesDark" [data]="item"></app-style-row>
					</ul> -->
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<span class="page-style-list-title">Horizontal</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Vertical</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of verticalStylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="sectionTitleBehaviorsFocus" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A tab that's currently being focused on with a keyboard, or
					other accessibility tools that emulate keyboards.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-hover">
			<h3 id="sectionTitleBehaviorsHover" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-hover')">
				Hover <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A tab that's currently being hovered over with a mouse, or
					other accessibility tools that emulate a mouse.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-active">
			<h3 id="sectionTitleBehaviorsActive" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-active')">
				Active <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A tab that's currently selected. Also known as default.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-inactive">
			<h3 id="sectionTitleBehaviorsInactive" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-inactive')">
				Inactive <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A tab that's not currently selected.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-error-active">
			<h3 id="sectionTitleBehaviorsErrorActive" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-error-active')">
				Error (active) <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A tab that's selected that has an error in its content.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-error-inactive">
			<h3 id="sectionTitleBehaviorsErrorInactive" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-error-inactive')">
				Error (inactive) <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A tab that's not selected that has an error in its content.
				</li>
				<li class="page-section-list-item">
					Both active and inactive tabs in an error state should have
					an error icon. Once the error is corrected in a tab, remove
					that tab's error icon.
				</li>
				<li class="page-section-list-item">
					Within an active error tab, the first field with an error
					should be put in a focused state, allowing the user to start
					correcting it right away.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-required-fields">
			<h3 id="sectionTitleBehaviorsRequiredFields" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-required-fields')">
				Required fields <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					If one or more of the tabs is a form with required fields,
					users should be able to switch between tabs without being
					prompted with a dialog box to save their work. What they've
					entered should save between navigating tabs.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-horizontal">
			<h3 id="subSectionTitleVariationsHorizontal" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-horizontal')">
				Horizontal tabs <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Horizontal tabs appear horizontally above the corresponding content.
					There’s no gap between the tabs and the content. Horizontal tabs always
					have a currently selected tab that has an indicator bar on top.
				</li>
				<li class="page-section-list-item">
					Use horizontal for smaller numbers of tabs. Try to be consistent with
					your approach — don’t mix and match horizontal and vertical for similar use cases.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-vertical">
			<h3 id="subSectionTitleVariationsVertical" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-vertical')">
				Vertical tabs <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Vertical tabs are stacked vertically to the left of the content. There’s typically a
					small gap between vertical tabs and the content. Vertical tabs always have a currently
					selected tab that has an indicator bar to the left.
				</li>
				<li class="page-section-list-item">
					Tab labels can wrap on vertical tabs, which increases the tab height.
				</li>
				<li class="page-section-list-item">
					Use the vertical variation when the number of tabs is larger. Try to be consistent
					with your approach — don’t mix and match horizontal and vertical for similar use cases.
				</li>
				<li class="page-section-list-item">
					<img class="guidance-image" src="\assets\images\vertical_tab.png" alt="" />
					<span class="redlines-caption">Example of a vertical tab with dimensions.</span>
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-do-list-title">Do:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Use tabs to display longer content that can be
							categorized.
						</li>
						<li class="page-section-list-item">
							Use tabs for content that's similar but distinctly
							different, like a form and a data grid that are
							related.
						</li>
						<li class="page-section-list-item">
							Display a single tab by default.
						</li>
					</ul>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item" style="margin-top: 5px">
					<span class="page-section-do-list-title">Don't:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Use tabs as the primary navigation in an
							application.
						</li>
						<li class="page-section-list-item">
							Use tabs to filter content that's the same—use
							button groups instead.
						</li>
						<li class="page-section-list-item">
							Split content between tabs that a user needs to view
							at the same time.
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Tab labels should be descriptive nouns and should be short
					and scannable. 1-2 words maximum. Icons can be paired with a
					tab's label and should be arranged to the left of the text.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-position">
			<h3 id="subSectionTitlUsagePosition" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-position')">
				Position <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Tabs can be used on full page layouts or in components such
					as modals, cards, or side panels.
				</li>
				<li class="page-section-list-item">
					Tab contents can be vertically scrollable if their length
					exceeds the screen. The tabs should remain fixed regardless
					of scrolling.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-accessibility">
			<h3 id="subSectionTitleUsageAccessibility" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-accessibility')">
				Accessibility <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Each tab should have a unique title that differentiates it
					from the others. The title needs to be descriptive to help
					users with screen readers navigate the content.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-tabs-or-button-groups">
			<h3 id="subSectionTitleUsageOr" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-tabs-or-button-groups')">
				Tabs or button groups <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					While tabs and button groups behave mostly the same, each has a specific use case.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-do-list-title">Use tabs:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							To organize content that’s distinctly different.
						</li>
						<li class="page-section-list-item">
							If your interface is particularly complex.
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					For example, one tab contains a group of labels and values, another tab contains a data grid, and
					another contains a form.
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item" style="margin-top: 5px">
					<span class="page-section-do-list-title">Use button groups:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							To filter content that’s mostly the same.
						</li>
						<li class="page-section-list-item">
							If your interface has the same or similar arrangements and components.
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					For example, each button displays a specific type of data in a grid.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/button-group">Button group component</a>
			</li>
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
					let subItem of changelogService.getChangelogBySection(
						'Tab Component'
					)
				">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
						subItem.workId
					">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
