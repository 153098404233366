<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Button Group'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="default">Default</option>
					<option value="withIcon">Button with icon</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<kendo-buttongroup [class.page-live-show]="currLive === 'default'" selection="single"
					[navigable]="true">
					<button *ngFor="let button of buttons" [svgIcon]="button.svgIcon" kendoButton [toggleable]="true"
						[selected]="button.selected" fillMode="outline" [disabled]="button.disabled">
						<span>
							{{ button.text }}
						</span>
					</button>
				</kendo-buttongroup>

				<kendo-buttongroup [class.page-live-show]="currLive !== 'default'" selection="single"
					[navigable]="true">
					<button *ngFor="let button of buttonsWithIcons" [svgIcon]="button.svgIcon" kendoButton
						[toggleable]="true" [selected]="button.selected" fillMode="outline"
						[disabled]="button.disabled">
						<span>
							{{ button.text }}
						</span>
					</button>
				</kendo-buttongroup>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title page-section-title-empty"
				title="Copy Link to Clipboard" (click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<section class="page-section-content">
				This section will be added in an upcoming SDS release.
			</section>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/buttons/api/ButtonGroupComponent/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Button Group API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/button-group#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Button with icon</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultIcon" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Button with icon</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkIcon" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Button with icon</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructureIcon" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-selected">
			<h3 id="subSectionTitleBehaviorsSelected" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-selected')">
				Select <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Button groups have one button selected by default and only one can be selected at a
					time.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="subSectionTitleBehaviorsFocus" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A focus state replaces the browser default focus state for button groups.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-disabled">
			<h3 id="subSectionTitleBehaviorsDisabled" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-disabled')">
				Disable <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Disabled buttons have a 50% opacity. Any combination of buttons in a button group
					can be disabled.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-default">
			<h3 id="subSectionTitleVariationsDefault" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-default')">
				Default <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Buttons in a button group contain just a label by default.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-button-with-icon">
			<h3 id="subSectionTitleVariationsButtonWithIcon" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('variations-button-with-icon')">
				Button with icon <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Any number of buttons in the button group can have an icon to the left of the label. These icons can
					be logos, which can use their own brand colors and are exempt from accessibility contrast rules. If
					the icons are not logos, they follow the same color standards as the <a href="components/icons">icon
						component</a>.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					The content guidance for button groups is the same as the <a
						href="components/button#usage-content">guidance for buttons</a>.
				</li>
				<li class="page-section-list-item">
					Also, try to label all the buttons in a group consistently so that they feel thematically linked.
					The relationship
					between the buttons should be clear and the text should follow a pattern. For example, use just
					nouns to label every
					button, or a verb and a noun for each—not a combination of both options.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
									width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
									<title>Icon/Status/checkmark</title>
									<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
										fill-rule="evenodd">
										<path
											d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
											id="" fill="#000000" />
									</g>
								</svg>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Favorites
									</li>
									<li class="comparison-body-text-list-item">
										Recently Used
									</li>
									<li class="comparison-body-text-list-item">
										Systems
									</li>
									<li class="comparison-body-text-list-item">
										Databases
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
									width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
									<title>Icon/NavObjects/close</title>
									<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
										fill-rule="evenodd">
										<path
											d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
											id="add2" fill="#000000"
											transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
									</g>
								</svg>
								Not This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										My Favorites
									</li>
									<li class="comparison-body-text-list-item">
										Go to Recently Used
									</li>
									<li class="comparison-body-text-list-item">
										View Systems
									</li>
									<li class="comparison-body-text-list-item">
										Databases
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-wrapping">
			<h3 id="subSectionTitleUsageWrapping" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-wrapping')">
				Wrapping <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Try to avoid button groups wrapping whenever possible, so limit the number of buttons in a group.
					Button groups wrap by
					starting a new row of buttons just below the first row with a small gap between them.
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/button_group/button-group.png">
					</div>
					<span class="redlines-caption">Example of button group wrapping under a section header and corner radius dimensions.</span>
				</li>
			</ul>
		</section>

		<section class="page-sub-section" id="usage-buttongroups-or-tabs">
			<h3 id="subSectionTitleUsageButtongroupsOrTabs" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-buttongroups-or-tabs')">
				Button groups or tabs <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					While tabs and button groups behave mostly the same, each has a specific use case.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Use button groups:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							To filter content that’s mostly the same
						</li>
						<li class="page-section-list-item">
							If your interface has the same or similar arrangements and components
						</li>
					</ul>
					For example, each button displays a specific type of data in a grid.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Use tabs:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							To organize content that’s distinctly different
						</li>
						<li class="page-section-list-item">
							If your interface is particularly complex
						</li>
					</ul>
					For example, one tab contains a group of labels and values, another tab contains a data grid, and
					another contains a form.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Size</span>
					Buttons in a group aren't all the same size.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tab">Tab component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>

		<span class="page-related-list-title">Components and patterns that use
			button groups:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="patterns/filter">Filter pattern</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
					let subItem of changelogService.getChangelogBySection(
						'Button Group Component'
					)
				">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
						subItem.workId
					">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>