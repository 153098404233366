import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shLink } from '@beyondtrust/shield-icons';

// Data Sources
import {
	lineChartStylesDefaultTheme,
	lineChartStylesDefaultThemeSeverity,
	lineChartStylesDefaultThemeStatus,
	lineChartStylesDarkTheme,
	lineChartStylesDarkThemeSeverity,
	lineChartStylesDarkThemeStatus,
	lineChartStylesTypography,
	lineChartStylesStructure
} from './line-chart-styles';
import { tooltipStylesDarkTheme, tooltipStylesDefaultTheme, tooltipStylesStructure, tooltipStylesTypography } from '../tooltip/tooltip-styles';
import { LegendItemVisualArgs, Series } from '@progress/kendo-angular-charts';

enum DataTypes {
	default = 'default',
	severity = 'severity',
	status = 'status'
};
@Component({
	selector: 'app-line-chart',
	templateUrl: './line-chart.component.html',
	styleUrls: ['./line-chart.component.less']
})
export class LineChartComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public linkIcon = shLink;

	// Imported Style Variables
	public stylesDefault = lineChartStylesDefaultTheme;
	public stylesDefaultSeverity = lineChartStylesDefaultThemeSeverity;
	public stylesDefaultStatus = lineChartStylesDefaultThemeStatus;
	public stylesDark = lineChartStylesDarkTheme;
	public stylesDarkSeverity = lineChartStylesDarkThemeSeverity;
	public stylesDarkStatus = lineChartStylesDarkThemeStatus;
	public stylesTypography = lineChartStylesTypography;
	public stylesStructure = lineChartStylesStructure;

	// Imported Toolips Styles Variables
	public stylesTooltipDefault = tooltipStylesDefaultTheme;
	public stylesTooltipDark = tooltipStylesDarkTheme;
	public stylesTooltipTypography = tooltipStylesTypography;
	public stylesTooltipStructure = tooltipStylesStructure;

	public dataTypes = DataTypes;
	public isOverLegend = false;

	public defaultLightSeries: Series[] = [
		{ name: '2020', data: [100, 27, 310, 59, 345, 120, 50], color: 'var(--color-chart-palette-1)' },
		{ name: '2021', data: [123, 276, 310, 212, 240, 156, 98], color: 'var(--color-chart-palette-4)' },
		{ name: '2022', data: [165, 210, 287, 144, 190, 167, 212], color: 'var(--color-chart-palette-6)' },
		{ name: '2023', data: [56, 140, 195, 46, 123, 78, 115], color: 'var(--color-chart-palette-10)' },
		{ name: '2024', data: [23, 76, 110, 132, 140, 156, 198], color: 'var(--color-chart-palette-16)' },
		{ name: '2025 (projected)', dashType: 'dash', data: [6, 340, 9, 346, 150, 298, 5], color: 'var(--color-chart-palette-15)' }
	];

	public severityLightSeries: Series[] = [
		{ name: 'Critical', data: [323, 296, 260, 200, 140, 56, 8], color: 'var(--color-severity-critical)' },
		{ name: 'High', data: [165, 210, 287, 144, 190, 167, 212], color: 'var(--color-severity-high)' },
		{ name: 'Moderate', data: [123, 276, 310, 212, 240, 156, 98], color: 'var(--color-severity-moderate)' },
		{ name: 'Low', data: [65, 110, 187, 44, 290, 267, 212], color: 'var(--color-severity-low)' },
		{ name: 'Informational', data: [45, 10, 100, 84, 190, 367, 170], color: 'var(--color-severity-info)' }
	];

	public statusLightSeries: Series[] = [
		{ name: 'Success', data: [123, 276, 310, 212, 240, 156, 98], color: 'var(--color-status-success)' },
		{ name: 'Warning', data: [165, 210, 287, 144, 190, 167, 212], color: 'var(--color-status-warning)' },
		{ name: 'Error', data: [5, 10, 87, 100, 90, 67, 21], color: 'var(--color-status-error)' },
		{ name: 'Informational', data: [56, 140, 195, 46, 123, 78, 115], color: 'var(--color-status-info)' }
	];

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: []
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: []
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started'
				},
				{
					name: 'Code examples',
					value: 'code'
				},
				{
					name: 'API',
					value: 'api'
				}
			]
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: []
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Legend visibility',
					value: 'legend-visibility'
				},
				{
					name: 'Tooltip',
					value: 'tooltip'
				}
			]
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general'
				},
				{
					name: 'Content',
					value: 'content'
				},
				{
					name: 'Severity',
					value: 'severity'
				},
				{
					name: 'Status',
					value: 'status'
				},
			]
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: []
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: []
		}
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}
	
	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url'
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public labelsVisual(args: LegendItemVisualArgs): any {
		return args.createVisual();
	}

	public lineChartHTML = `
<kendo-chart
	[transitions]="true"
	[ngClass]="{ 'hand-cursor': isOverLegend }"
	(legendItemHover)="isOverLegend = true"
	(legendItemLeave)="isOverLegend = false"
	[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }"
>
	<kendo-chart-value-axis>
		<kendo-chart-value-axis-item [title]="{ text: 'Connector count' }">
		</kendo-chart-value-axis-item>
	</kendo-chart-value-axis>
	<kendo-chart-category-axis>
		<kendo-chart-category-axis-item
			[categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']"
			[majorGridLines]="{visible: false}"
			[title]="{ text: 'Months' }"
		> </kendo-chart-category-axis-item>
	</kendo-chart-category-axis>
	<kendo-chart-series>
		<kendo-chart-series-item
			*ngFor="let item of defaultLightSeries"
			[type]="'line'"
			[name]="item.name"
			[data]="item.data"
			[dashType]="item.dashType"
			[color]="'#' + item.color"
		>
			<kendo-chart-series-item-tooltip>
				<ng-template
					let-category="category"
					let-value="value"
					let-series="series"
				>
					<div class="toolTip">
						<div>{{ category }} {{ series.name }}</div>
						<div>
							{{ value }}
						</div>
					</div>
				</ng-template>
			</kendo-chart-series-item-tooltip>
		</kendo-chart-series-item>
	</kendo-chart-series>
	<kendo-chart-legend
		position="right"
		orientation="vertical"
	>
		<kendo-chart-legend-item
			[visual]="labelsVisual"
			cursor="pointer"
		> </kendo-chart-legend-item>
	</kendo-chart-legend>
</kendo-chart>`;

	public lineChartTs = `
import { LegendItemVisualArgs, Series } from '@progress/kendo-angular-charts';

enum DataTypes {
	default = 'default',
	severity = 'severity',
	status = 'status'
};

...

public dataTypes = DataTypes;
public isOverLegend = false;

public defaultDarkSeries: Series[] = [
	{ name: '2019', data: [123, 276, 310, 212, 240, 156, 98], color: 'var(--color-chart-palette-1)' },
	{ name: '2020', data: [123, 276, 310, 212, 240, 156, 98], color: 'var(--color-chart-palette-2)' },
	{ name: '2021', data: [165, 210, 287, 144, 190, 167, 212], color: 'var(--color-chart-palette-3)' },
	{ name: '2022', data: [56, 140, 195, 46, 123, 78, 115], color: 'var(--color-chart-palette-4)' },
	{ name: '2023', data: [23, 76, 110, 132, 140, 156, 198], color: 'var(--color-chart-palette-5)' },
	{ name: '2024', data: [200, 210, 200, 220, 200, 210, 212], color: 'var(--color-chart-palette-6)' },
	{ name: '2025 (projected)', dashType: 'dash', data: [6, 340, 9, 346, 123, 298, 5], color: 'var(--color-chart-palette-7)' }
];

public labelsVisual(args: LegendItemVisualArgs): any {
	return args.createVisual();
}
`;
}
