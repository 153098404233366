import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shLink } from '@beyondtrust/shield-icons';

// Data Sources
import {
	dataTableStylesDefaultTheme,
	dataTableStylesDarkTheme,
	dataTableStylesTypography,
	dataTableStylesStructure,
} from './data-table-styles';
import { data } from './data';
import { paginationStylesDarkTheme, paginationStylesDefaultTheme } from '../pagination/pagination-styles';

@Component({
	selector: 'app-data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.less'],
})
export class DataTableComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public gridHeight = "100%";
	// Imported Style Variables
	public stylesDefault = dataTableStylesDefaultTheme;
	public stylesDefaultPagination = paginationStylesDefaultTheme;
	public stylesDark = dataTableStylesDarkTheme;
	public stylesDarkPagination = paginationStylesDarkTheme;
	public stylesTypography = dataTableStylesTypography;
	public stylesStructure = dataTableStylesStructure;

	public dataTableData: unknown[] = data;
	public linkIcon = shLink;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code'
				}
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Empty state',
					value: 'empty'
				},
				{
					name: 'Loading state',
					value: 'loading'
				},
				{
					name: 'Column reordering',
					value: 'reordering'
				},
				{
					name: 'Column resizing',
					value: 'resizing'
				},
				{
					name: 'Filter',
					value: 'filter'
				},
				{
					name: 'Pagination',
					value: 'pagination'
				},
				{
					name: 'Sorting',
					value: 'sorting'
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Content',
					value: 'content'
				},
				{
					name: 'Position',
					value: 'position'
				},
				{
					name: 'Data table or data grid',
					value: 'table-vs-grid'
				},
				{
					name: 'Data table or list',
					value: 'table-vs-list'
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public dataTableHTML: any = `
<kendo-grid
	[kendoGridBinding]="dataTableData"
	style="max-height: 500px;"
	class="sh-data-table"
	[pageSize]="10"
	[pageable]="{ type: 'input', pageSizes: [25, 50, 100, 200] }"
	[sortable]="true"
	[reorderable]="true"
	[resizable]="true"
	[sortable]="true"
	[scrollable]="'none'">
	<kendo-grid-column [width]="117" field="Name" title="Name"></kendo-grid-column>
	<kendo-grid-column [width]="105" field="Status" title="Status"></kendo-grid-column>
	<kendo-grid-column [width]="135" field="DaysDisconnected" title="Days disconnected" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
	<kendo-grid-column [width]="195" field="GroupName" title="Group name"></kendo-grid-column>
	<kendo-grid-column [width]="152" field="AssignedPolicy" title="Assigned policy"></kendo-grid-column>
	<kendo-grid-column [width]="157" field="PolicyStatus" title="Policy status"></kendo-grid-column>
</kendo-grid>`;
}
