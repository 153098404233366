<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Slider'" [includeDescription]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change example">
					<option value="horizontal">Horizontal</option>
					<option value="vertical">Vertical</option>
					<option value="disabled">Disabled</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<div
					class="page-live-example sh-slider-container"
					style="width: 250px"
					[class.page-live-show]="currLive === 'horizontal'"
				>
					<kendo-label
						text="Session duration (hours): {{ horizontalSliderValue }}"
						[for]="horizontalSlider"
					>
					</kendo-label>
					<kendo-slider
						#horizontalSlider
						name="horizontalSlider"
						[largeStep]="1"
						[min]="1"
						[max]="10"
						[showButtons]="false"
						[smallStep]="1"
						[(ngModel)]="horizontalSliderValue"
					></kendo-slider>
					<kendo-formhint>Select a number between 1 and 10.</kendo-formhint>
				</div>
				<div class="page-live-example sh-slider-container"	
					style="height: 250px" 
					[class.page-live-show]="currLive === 'vertical'">
					<kendo-label
						text="Session duration (hours): {{ verticalSliderValue }}"
						[for]="verticalSlider"
					>
					</kendo-label>
					<kendo-slider
						#verticalSlider
						name="verticalSlider"
						[largeStep]="1"
						[min]="1"
						[max]="10"
						[showButtons]="false"
						[smallStep]="1"
						[vertical]="true"
						[(ngModel)]="verticalSliderValue"
					></kendo-slider>
					<kendo-formhint>Select a number between 1 and 10.</kendo-formhint>
				</div>
				<div
					class="page-live-example sh-slider-container"
					style="width: 250px"
					[class.page-live-show]="currLive === 'disabled'"
				>
					<kendo-label
						text="Session duration (hours): 1"
						[for]="disabledSlider"
					>
					</kendo-label>
					<kendo-slider
						#disabledSlider
						name="disabledSlider"
						[disabled]="true"
						[largeStep]="1"
						[min]="1"
						[max]="10"
						[showButtons]="false"
						[smallStep]="1"
					></kendo-slider>
					<kendo-formhint>Select a number between 1 and 10.</kendo-formhint>
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-started">
			<h3 id="subSectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<p>Added to your project's app.module.ts file:</p>
			<code style="margin-top: 10px">import &#123; InputsModule &#125; from
				'&#64;progress/kendo-angular-inputs';</code>
		</section>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<div class="content">
				<kendo-tabstrip
					#tabs
					class="code-example-tabstrip"
				>
					<kendo-tabstrip-tab
						title="Horizontal"
						[selected]="currLive === 'horizontal'"
					>
						<ng-template kendoTabContent>
							<div class="content">
								<pre
									prism
									class="dark"
								>
									{{ horizontalSliderHTML }}
								</pre>
							</div>
						</ng-template>
					</kendo-tabstrip-tab>
					<kendo-tabstrip-tab
						title="Vertical"
						[selected]="currLive === 'vertical'"
					>
						<ng-template kendoTabContent>
							<div class="content">
								<pre
									prism
									class="dark"
								>
									{{ verticalSliderHTML }}
								</pre>
							</div>
						</ng-template>
					</kendo-tabstrip-tab>
					<kendo-tabstrip-tab 
						title="Disabled"
						[selected]="currLive === 'disabled'"
					>
						<ng-template kendoTabContent>
							<div class="content">
								<pre
									prism
									class="dark"
								>
									{{ disabledSliderHTML }}
								</pre>
							</div>
						</ng-template>
					</kendo-tabstrip-tab>
				</kendo-tabstrip>
			</div>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/inputs/api/SliderComponent/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Slider API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/slider#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Horizontal</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructureHorizontal" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Vertical</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructureVertical" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="subSectionTitleBehaviorsFocus" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Only the handle of the slider has a focus state.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-hover">
			<h3 id="subSectionTitleBehaviorsHover" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-hover')">
				Hover <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Only the handle of the slider has a hover state.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-disable">
			<h3 id="subSectionTitleBehaviorsDisable" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-disable')">
				Disable <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Sliders are at 50% opacity when disabled and can’t be interacted
					with.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-select">
			<h3 id="subSectionTitleBehaviorsSelect" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-select')">
				Select <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					The handle on the slider track indicates the current value. The handle can
					be moved on the track to change the value by:
					<ul class="page-section-do-list" style="margin-top: 5px">
						<li class="page-section-list-item">Clicking and dragging the handle.</li>
						<li class="page-section-list-item">Selecting a tick mark.</li>
						<li class="page-section-list-item">Selecting a tick mark label.</li>
						<li class="page-section-list-item">Using the arrow keys.</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-values">
			<h3 id="subSectionTitleBehaviorsValues" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-values')">
				Values <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Sliders always have a default selected value and have a minimum and maximum value.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-horizontal">
			<h3 id="subSectionTitleVariationsHorizontal" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-horizontal')">
				Horizontal <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Most sliders are horizontal. Horizontal sliders have a handle that 
					moves left and right along a track. Labels for the ticks are displayed
					under the track.
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="\assets\images\redlines\slider\slider-horizontal-dimensions.png" alt="" />
					</div>
					<span class="redlines-caption">Example of horizontal slider dimensions.</span>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-vertical">
			<h3 id="subSectionTitleVariationsVertical" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-vertical')">
				Vertical <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Vertical sliders have a handle that moves up and down along a 
					track. Labels for the ticks are displayed to the right of the track.
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="\assets\images\redlines\slider\slider-vertical-dimensions.png" alt="" />
					</div>
					<span class="redlines-caption">Example of vertical slider dimensions.</span>
				</li>
			</ul>
		</section>

	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Sliders allow users to choose single value from a fixed, sequential set of
					numeric values.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Labels</span>
					Slider labels for both horizontal and vertical variations appear above the
					slider and left aligned.
				</li>
				<li class="page-section-list-item">
					Slider labels should be short, only a few words. They should indicate to the
					user what value they’re selecting.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Instructional text</span>
					If instructional text is required, it should be short and verb-first. 
					It’s not meant to replace the label or be used for examples. Instructional 
					text for both horizontal and vertical variations appears below the slider.
				</li>
			</ul>
		</section>
		<!-- <section class="page-sub-section" id="usage-slider-vs-range-slider-vs-number-field">
			<h3 id="subSectionTitleUsageSliderVersusRangeSliderVersusNumberField" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-slider-vs-range-slider-vs-number-field')">
				Slider versus range slider versus number field <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Slider</span>
					For choosing a single value from a fixed, sequential set of numeric values.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Range slider</span>
					For choosing a range between two values in a fixed, sequential set of numeric values.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Number field</span>
					For choosing a single numeric value.
				</li>
			</ul>
		</section> -->
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Slider Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>