<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Filter'" [includeDescription]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event);resetFilters();"
					title="Change Example">
					<option value="default">Basic filter</option>
					<option value="filter-with-grid">Basic filter with grid</option>
					<option value="advanced-filter">Advanced filter with grid
					</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<section [class.page-live-show]="currLive === 'default'" class="filter-container">
					<ng-container *ngFor="let selectedItem of selectedItems">
						<kendo-dropdownlist #subddl *ngIf="isCheckboxItem(selectedItem)"
							class="sh-filter-dropdown sh-filter-optional" textField="text" valueField="value"
							[data]="$any(selectedItem).data" [filterable]="true"
							[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }"
							(filterChange)="onSubFilterChange($event, selectedItem)"
							(valueChange)="onSubValueChange($event, subddl, selectedItem)" (open)="onOpen(subddl)"
							(close)="onClose($event)" (blur)="onBlur(subddl)" rounded="medium">
							<ng-template kendoDropDownListValueTemplate>
								<b>{{ selectedItem.text }}: </b>
								<ng-container
									*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl">
									<span><ng-container
											*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last">{{
											v.text }}{{ isLast ? "" : ", "
											}}</ng-container></span>
								</ng-container>
								<ng-template #allTpl>All</ng-template>
								<kendo-svg-icon kendoTooltip [icon]="xMark" (click)="removeFilter(selectedItem.text)"
									title="Remove Filter"></kendo-svg-icon>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<input #checkbox type="checkbox" kendoCheckBox
									[checked]="$any(selectedItem).selectedItems?.includes(dataItem)"
									class="sh-filter-item-checkbox" />
								<kendo-label [for]="checkbox" [text]="dataItem.text" class="k-checkbox-label"
									style="pointer-events: none;"></kendo-label>
							</ng-template>
							<ng-template kendoDropDownListNoDataTemplate>No
								results were found.</ng-template>
						</kendo-dropdownlist>

						<kendo-dropdownlist #subddl *ngIf="isTextItem(selectedItem)"
							class="sh-filter-dropdown sh-text-filter sh-filter-optional" textField="text"
							valueField="value" [data]="[{}]"
							[popupSettings]="{ popupClass: 'sh-filter-popup sh-text-filter-popup', width: 'auto' }"
							(open)="onOpen(subddl); $any(selectedItem).textboxValue = $any(selectedItem).filter"
							(close)="onClose($event)" rounded="medium">
							<ng-template kendoDropDownListValueTemplate>
								<b>{{ selectedItem.text }}: </b> <span>{{
									$any(selectedItem).filter || "All" }}</span>
								<kendo-svg-icon kendoTooltip [icon]="xMark" (click)="removeFilter(selectedItem.text)"
									title="Remove Filter"></kendo-svg-icon>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<kendo-textbox #textboxFilter [(value)]="$any(selectedItem).textboxValue"
									[clearButton]="true" fillMode="outline"
									(keydown)="$any($event).key === 'Enter' ? updateFilter(selectedItem, subddl) : true"
									(blur)="updateFilter(selectedItem, subddl)">
								</kendo-textbox>
							</ng-template>
							<ng-template kendoDropDownListFooterTemplate>
								<div class="sh-filter-dropdown-buttons">
									<kendo-button themeColor="primary"
										(click)="updateFilter(selectedItem, subddl)">Apply Filter</kendo-button>
									<kendo-button themeColor="secondary" (click)="onBlur(subddl)">Close</kendo-button>
								</div>
							</ng-template>
							<ng-template kendoDropDownListNoDataTemplate></ng-template>
						</kendo-dropdownlist>

						<kendo-dropdownlist #subddl *ngIf="isDateItem(selectedItem)"
							class="sh-filter-dropdown sh-filter-optional" textField="text" valueField="value"
							[data]="$any(selectedItem).data" [filterable]="true"
							[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }"
							(filterChange)="onSubFilterChange($event, selectedItem)"
							(valueChange)="onSubValueChange($event, subddl, selectedItem)" (open)="onOpen(subddl)"
							(close)="onClose($event)" (blur)="onBlur(subddl)" rounded="medium">
							<ng-template kendoDropDownListValueTemplate>
								<b>{{ selectedItem.text }}: </b>
								<ng-container
									*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl">
									<span><ng-container
											*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last">{{
											v.text }}{{ isLast ? "" : ", "
											}}</ng-container></span>
								</ng-container>
								<ng-template #allTpl>All</ng-template>
								<kendo-svg-icon kendoTooltip [icon]="xMark" (click)="removeFilter(selectedItem.text)"
									title="Remove Filter"></kendo-svg-icon>
							</ng-template>
							<ng-template kendoDropDownListNoDataTemplate>No
								results were found.</ng-template>
						</kendo-dropdownlist>
					</ng-container>

					<div class="sh-filter-buttons-container">
						<kendo-dropdownlist #ddl class="sh-filter-button" textField="text" valueField="value"
							[data]="listItems" [filterable]="true"
							[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }" (open)="onOpen(ddl)"
							(close)="onClose($event)" (blur)="onBlur(ddl)" (filterChange)="onFilterChange($event)"
							(valueChange)="onValueChange($event, ddl)">
							<ng-template kendoDropDownListValueTemplate>
								<kendo-button themeColor="primary" [svgIcon]="filterIcon">Add Filters</kendo-button>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<input #checkbox type="checkbox" kendoCheckBox
									[checked]="selectedItems.includes(dataItem)" class="sh-filter-item-checkbox" />
								<kendo-label [for]="checkbox" [text]="dataItem.text" class="k-checkbox-label"
									style="pointer-events: none;"></kendo-label>
							</ng-template>
							<ng-template kendoDropDownListNoDataTemplate>No
								results were found.</ng-template>
						</kendo-dropdownlist>
						<!-- <button kendoButton themeColor="primary" fillMode="flat" class="button-text-secondary"
							*ngIf="selectedItems.length > 0" (click)="resetFilters()">
							Reset Filters
						</button> -->
						<kendo-button
							class="sh-filter-reset-button"
							[svgIcon]="shClearFilter"
							themeColor="primary"
							fillMode="flat"
							*ngIf="selectedItems.length"
							(click)="resetFilters()">
							Reset Filters
						</kendo-button>
					</div>
				</section>
				<section [class.page-live-show]="currLive === 'filter-with-grid'" class="filter-container">
					<ng-container *ngFor="let selectedItem of selectedItems2">
						<kendo-dropdownlist #subddl *ngIf="selectedItem.text === 'Category'"
							class="sh-filter-dropdown sh-filter-optional" textField="text" valueField="value"
							[data]="$any(selectedItem).data" [filterable]="true"
							[popupSettings]="{popupClass: 'sh-filter-popup', width: 'auto'}"
							(filterChange)="onSubFilterChange($event, selectedItem)"
							(valueChange)="onSubValueChange($event, subddl, selectedItem)" (open)="onOpen(subddl)"
							(close)="onClose($event)" (blur)="onBlur(subddl)" rounded="medium">
							<ng-template kendoDropDownListValueTemplate>
								<b>{{ selectedItem.text }}: </b>
								<ng-container
									*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl">
									<span><ng-container
											*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last">{{
											v.text }}{{ isLast ? "" : ", "
											}}</ng-container></span>
								</ng-container>
								<ng-template #allTpl>All</ng-template>
								<kendo-svg-icon kendoTooltip [icon]="xMark" (click)="removeFilter(selectedItem.text)"
									title="Remove Filter"></kendo-svg-icon>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<input #checkbox type="checkbox" kendoCheckBox
									[checked]="$any(selectedItem).selectedItems?.includes(dataItem)"
									class="sh-filter-item-checkbox" />
								<kendo-label [for]="checkbox" [text]="dataItem.text" class="k-checkbox-label"
									style="pointer-events: none;"></kendo-label>
							</ng-template>
							<ng-template kendoDropDownListNoDataTemplate>No
								results were found.</ng-template>
						</kendo-dropdownlist>

						<kendo-dropdownlist #subddl *ngIf="selectedItem.text === 'Product Name'"
							class="sh-filter-dropdown sh-text-filter sh-filter-optional" textField="text"
							valueField="value" [data]="[{}]"
							[popupSettings]="{popupClass: 'sh-filter-popup sh-text-filter-popup', width: 'auto'}"
							(open)="onOpen(subddl);$any(selectedItem).textboxValue = $any(selectedItem).filter"
							(close)="onClose($event)" rounded="medium">
							<ng-template kendoDropDownListValueTemplate>
								<b>{{ selectedItem.text }}: </b> <span>{{
									$any(selectedItem).filter || "All" }}</span>
								<kendo-svg-icon kendoTooltip [icon]="xMark" (click)="removeFilter(selectedItem.text)"
									title="Remove Filter"></kendo-svg-icon>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<kendo-textbox #textboxFilter [(value)]="$any(selectedItem).textboxValue"
									[clearButton]="true" fillMode="outline"
									(keydown)="$any($event).key === 'Enter' ? updateFilter(selectedItem, subddl) : true"
									(blur)="updateFilter(selectedItem, subddl)"> </kendo-textbox>
							</ng-template>
							<ng-template kendoDropDownListFooterTemplate>
								<div class="sh-filter-dropdown-buttons">
									<kendo-button (click)="updateFilter(selectedItem, subddl)">Apply
										Filter</kendo-button>
									<kendo-button themeColor="secondary" (click)="onBlur(subddl)">Close</kendo-button>
								</div>
							</ng-template>
							<ng-template kendoDropDownListNoDataTemplate></ng-template>
						</kendo-dropdownlist>

						<kendo-dropdownlist #subddl *ngIf="selectedItem.text === 'First Ordered On'"
							class="sh-filter-dropdown  sh-filter-optional" textField="text" valueField="value"
							[data]="$any(selectedItem).data" [filterable]="true"
							[popupSettings]="{popupClass: 'sh-filter-popup', width: 'auto'}"
							(filterChange)="onSubFilterChange($event, selectedItem)"
							(valueChange)="onSubValueChange($event, subddl, selectedItem)" (open)="onOpen(subddl)"
							(close)="onClose($event)" (blur)="onBlur(subddl)" rounded="medium">
							<ng-template kendoDropDownListValueTemplate>
								<b>{{ selectedItem.text }}: </b>
								<ng-container
									*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl">
									<span><ng-container
											*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last">{{
											v.text }}{{ isLast ? "" : ", "
											}}</ng-container></span>
								</ng-container>
								<ng-template #allTpl>All</ng-template>
								<kendo-svg-icon kendoTooltip [icon]="xMark" (click)="removeFilter(selectedItem.text)"
									title="Remove Filter"></kendo-svg-icon>
							</ng-template>
							<ng-template kendoDropDownListNoDataTemplate>No
								results were found.</ng-template>
						</kendo-dropdownlist>
					</ng-container>

					<div class="sh-filter-buttons-container">
						<kendo-dropdownlist #ddl2 class="sh-filter-button" textField="text" valueField="value"
							[data]="listItems2" [filterable]="true"
							[popupSettings]="{popupClass: 'sh-filter-popup', width: 'auto'}" (open)="onOpen(ddl2)"
							(close)="onClose($event)" (blur)="onBlur(ddl2)" (filterChange)="onFilterChange($event)"
							(valueChange)="onValueChange($event, ddl2)">
							<ng-template kendoDropDownListValueTemplate>
								<kendo-button [svgIcon]="filterIcon">Add
									Filters</kendo-button>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<input #checkbox type="checkbox" kendoCheckBox
									[checked]="selectedItems2.includes(dataItem)" class="sh-filter-item-checkbox" />
								<kendo-label [for]="checkbox" [text]="dataItem.text" class="k-checkbox-label"
									style="pointer-events: none;"></kendo-label>
							</ng-template>
							<ng-template kendoDropDownListNoDataTemplate>No
								results were found.</ng-template>
						</kendo-dropdownlist>
						<!-- <button kendoButton themeColor="primary" fillMode="flat" class="button-text-secondary"
							*ngIf="selectedItems2.length > 0" (click)="resetFilters()">
							Reset Filters
						</button> -->
						<kendo-button
							class="sh-filter-reset-button"
							[svgIcon]="shClearFilter"
							themeColor="primary"
							fillMode="flat"
							*ngIf="selectedItems2.length"
							(click)="resetFilters()">
							Reset Filters
						</kendo-button>
					</div>

					<kendo-grid 
						#singleGridDataBinding 
						[style.margin-top.px]="15" 
						[kendoGridBinding]="singleGridView"
						[selectable]="singleSelectableSettings" 
						[navigable]="true" 
						[style.height]="gridHeight" 
						[pageSize]="25"
						[pageable]="$any(singleGridView).length === 0
							? false
							: {
								type: 'input',
								pageSizes: [10, 25, 50, 100, 200]
							}" 
						[sortable]="true" 
						[reorderable]="true" 
						[resizable]="true"
						[scrollable]="'none'">
							<ng-template kendoGridNoRecordsTemplate>
								<div class="no-records">
									<p>There are no records to display.</p>
								</div>
							</ng-template>
							<ng-template kendoGridToolbarTemplate>
								<div class="sh-custom-toolbar">
									<div class="sh-toolbar-actions">
										<kendo-textbox [style.width.px]="0" [style.border-width]="0"
											placeholder="Quick Filter" [clearButton]="true">
											<ng-template kendoTextBoxPrefixTemplate>
												<kendo-svg-icon [icon]="searchIcon"></kendo-svg-icon>
											</ng-template>
										</kendo-textbox>
										<kendo-button [svgIcon]="downloadIcon"></kendo-button>
										<kendo-button [svgIcon]="columnsIcon"></kendo-button>
										<kendo-button [svgIcon]="settingsIcon"></kendo-button>
									</div>
									<div class="sh-item-count-container">
										<strong class="sh-item-count">{{
											singleGridView.length }} items</strong>
									</div>
								</div>
							</ng-template>
							<kendo-grid-checkbox-column [width]="45" [showSelectAll]="false"></kendo-grid-checkbox-column>
							<kendo-grid-column field="ProductName" title="Product Name"></kendo-grid-column>
							<kendo-grid-column field="Category" title="Category"></kendo-grid-column>
							<kendo-grid-column field="FirstOrderedOn" title="First Ordered On"><ng-template
									kendoGridCellTemplate let-dataItem>
									{{dataItem.FirstOrderedOn | date:
									'MM/dd/yyyy&nbsp;&nbsp;hh:mm a'}}
							</ng-template></kendo-grid-column>
					</kendo-grid>
				</section>
				<section [class.page-live-show]="currLive === 'advanced-filter'" class="filter-container">
					<div class="sh-filter-toolbar">
						<div *ngIf="activeFilterType === 'basic'">
							<ng-container *ngFor="let selectedItem of selectedItems3">
								<kendo-dropdownlist #subddl *ngIf="selectedItem.text === 'Category'"
									class="sh-filter-dropdown sh-filter-optional" textField="text" valueField="value"
									[data]="$any(selectedItem).data" [filterable]="true"
									[popupSettings]="{popupClass: 'sh-filter-popup', width: 'auto'}"
									(filterChange)="onSubFilterChange($event, selectedItem)"
									(valueChange)="onSubValueChange($event, subddl, selectedItem)"
									(open)="onOpen(subddl)" (close)="onClose($event)" (blur)="onBlur(subddl)"
									rounded="medium">
									<ng-template kendoDropDownListValueTemplate>
										<b>{{ selectedItem.text }}: </b>
										<ng-container
											*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl">
											<span><ng-container
													*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last">{{
													v.text }}{{ isLast ? "" : ", "
													}}</ng-container></span>
										</ng-container>
										<ng-template #allTpl>All</ng-template>
										<kendo-svg-icon kendoTooltip [icon]="xMark"
											(click)="removeFilter(selectedItem.text)"
											title="Remove Filter"></kendo-svg-icon>
									</ng-template>
									<ng-template kendoDropDownListItemTemplate let-dataItem>
										<input #checkbox type="checkbox" kendoCheckBox
											[checked]="$any(selectedItem).selectedItems?.includes(dataItem)"
											class="sh-filter-item-checkbox" />
										<kendo-label [for]="checkbox" [text]="dataItem.text" class="k-checkbox-label"
											style="pointer-events: none;"></kendo-label>
									</ng-template>
									<ng-template kendoDropDownListNoDataTemplate>No
										results were found.</ng-template>
								</kendo-dropdownlist>

								<kendo-dropdownlist #subddl *ngIf="selectedItem.text === 'Product Name'"
									class="sh-filter-dropdown sh-text-filter sh-filter-optional" textField="text"
									valueField="value" [data]="[{}]"
									[popupSettings]="{popupClass: 'sh-filter-popup sh-text-filter-popup', width: 'auto'}"
									(open)="onOpen(subddl);$any(selectedItem).textboxValue = $any(selectedItem).filter"
									(close)="onClose($event)" rounded="medium">
									<ng-template kendoDropDownListValueTemplate>
										<b>{{ selectedItem.text }}: </b> <span>{{
											$any(selectedItem).filter || "All" }}</span>
										<kendo-svg-icon kendoTooltip [icon]="xMark"
											(click)="removeFilter(selectedItem.text)"
											title="Remove Filter"></kendo-svg-icon>
									</ng-template>
									<ng-template kendoDropDownListItemTemplate let-dataItem>
										<kendo-textbox #textboxFilter [(value)]="$any(selectedItem).textboxValue"
											[clearButton]="true" fillMode="outline"
											(keydown)="$any($event).key === 'Enter' ? updateFilter(selectedItem, subddl) : true"
											(blur)="updateFilter(selectedItem, subddl)"> </kendo-textbox>
									</ng-template>
									<ng-template kendoDropDownListFooterTemplate>
										<div class="sh-filter-dropdown-buttons">
											<kendo-button (click)="updateFilter(selectedItem, subddl)">Apply
												Filter</kendo-button>
											<kendo-button themeColor="secondary"
												(click)="onBlur(subddl)">Close</kendo-button>
										</div>
									</ng-template>
									<ng-template kendoDropDownListNoDataTemplate></ng-template>
								</kendo-dropdownlist>

								<kendo-dropdownlist #subddl *ngIf="selectedItem.text === 'First Ordered On'"
									class="sh-filter-dropdown  sh-filter-optional" textField="text" valueField="value"
									[data]="$any(selectedItem).data" [filterable]="true"
									[popupSettings]="{popupClass: 'sh-filter-popup', width: 'auto'}"
									(filterChange)="onSubFilterChange($event, selectedItem)"
									(valueChange)="onSubValueChange($event, subddl, selectedItem)"
									(open)="onOpen(subddl)" (close)="onClose($event)" (blur)="onBlur(subddl)"
									rounded="medium">
									<ng-template kendoDropDownListValueTemplate>
										<b>{{ selectedItem.text }}: </b>
										<ng-container
											*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl">
											<span><ng-container
													*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last">{{
													v.text }}{{ isLast ? "" : ", "
													}}</ng-container></span>
										</ng-container>
										<ng-template #allTpl>All</ng-template>
										<kendo-svg-icon kendoTooltip [icon]="xMark"
											(click)="removeFilter(selectedItem.text)"
											title="Remove Filter"></kendo-svg-icon>
									</ng-template>
									<ng-template kendoDropDownListNoDataTemplate>No
										results were found.</ng-template>
								</kendo-dropdownlist>
							</ng-container>
							<div class="sh-filter-buttons-container">
								<kendo-dropdownlist #ddl3 class="sh-filter-button" textField="text" valueField="value"
									[data]="listItems3" [filterable]="true"
									[popupSettings]="{popupClass: 'sh-filter-popup', width: 'auto'}"
									(open)="onOpen(ddl3)" (close)="onClose($event)" (blur)="onBlur(ddl3)"
									(filterChange)="onFilterChange($event)" (valueChange)="onValueChange($event, ddl3)">
									<ng-template kendoDropDownListValueTemplate>
										<kendo-button [svgIcon]="filterIcon">Add Filters</kendo-button>
									</ng-template>
									<ng-template kendoDropDownListItemTemplate let-dataItem>
										<input #checkbox type="checkbox" kendoCheckBox
											[checked]="selectedItems3.includes(dataItem)"
											class="sh-filter-item-checkbox" />
										<kendo-label [for]="checkbox" [text]="dataItem.text" class="k-checkbox-label"
											style="pointer-events: none;"></kendo-label>
									</ng-template>
									<ng-template kendoDropDownListNoDataTemplate>No
										results were found.</ng-template>
								</kendo-dropdownlist>

								<!-- <button kendoButton themeColor="primary" fillMode="flat" class="button-text-secondary"
									*ngIf="selectedItems3.length > 0" (click)="resetFilters()">
									Reset Filters
								</button> -->
								<kendo-button
									class="sh-filter-reset-button"
									[svgIcon]="shClearFilter"
									themeColor="primary"
									fillMode="flat"
									*ngIf="selectedItems3.length"
									(click)="resetFilters()">
									Reset Filters
								</kendo-button>
							</div>
						</div>

						<!--This has to be placed outside the kendo-filter below since it lives in a popup and the popup is rendered outside scope of this component-->
						<ng-template let-currentItem #advFilterFieldTemplate>
							<kendo-dropdownlist [data]="['Beverages',
								'Condiments',
								'Confections',
								'Dairy Products',
								'Grains/Cereals',
								'Meat/Poultry',
								'Produce',
								'Seafood']" [value]="currentItem.value" (valueChange)="editorValueChange(currentItem, $event)">
							</kendo-dropdownlist>
						</ng-template>
						<ng-container *ngIf="activeFilterType === 'advanced'">
							<div class="sh-filter-buttons-container">
								<div class="filtered" *ngIf="advFilterValues.filters.length > 0">
									Advanced Filters: {{ advFilterValues.filters.length }}
								</div>

								<kendo-dropdownlist #ddl4 class="sh-filter-button" [data]="[]"
									[popupSettings]="{popupClass: 'sh-advanced-filter-popup'}"
									(close)="onClose($event)">
									<ng-template kendoDropDownListValueTemplate>
										<kendo-button [svgIcon]="filterIcon">Add Filters</kendo-button>
									</ng-template>
									<ng-template kendoDropDownListNoDataTemplate>
										<button kendoTooltip kendoButton style="float: right;" title="Close"
											[svgIcon]="xMark" (click)="onBlur(ddl4)"></button>

										<kendo-filter #advFilter class="sh-filter" [filters]="advFilters"
											[value]="advFilterValues">
										</kendo-filter>

										<kendo-button themeColor="primary" (click)="applyAdvFilters(ddl4)">Apply
											Filters</kendo-button>
										<kendo-button themeColor="secondary" (click)="onBlur(ddl4)"
											class="action-buttons">Discard Changes</kendo-button>
									</ng-template>
								</kendo-dropdownlist>
								<kendo-button
									[svgIcon]="shClearFilter"
									themeColor="primary"
									fillMode="flat"
									*ngIf="advFilterValues.filters.length"
									(click)="resetFilters()">
									Reset Filters
								</kendo-button>
								<!-- <button kendoButton themeColor="primary" fillMode="flat" class="button-text-secondary"
									*ngIf="advFilterValues.filters.length > 0" (click)="resetFilters()">
									Reset Filters
								</button> -->
							</div>
						</ng-container>

						<button kendoButton themeColor="primary" fillMode="flat" class="button-text-secondary"
							(click)="showModal = true">
							Switch to {{ activeFilterType === 'basic' ? 'Advanced' : 'Basic' }}
						</button>
						<kendo-dialog *ngIf="showModal" (close)="closeModal()">
							<div class="k-dialog-titlebar">
								<div class="k-dialog-title">
									<h2>Confirm switch filter type</h2>
								</div>
							</div>
							<p>Warning: you will lose all applied filters if switching to {{activeFilterType
								=== 'basic' ? 'Advanced' : 'Basic' }}. Are you sure you want to continue?
							</p>
							<kendo-dialog-actions [layout]="'end'">
								<button kendoButton (click)="closeModal()" themeColor="secondary">No</button>
								<button kendoButton (click)="switchFilters()" themeColor="primary">Yes</button>
							</kendo-dialog-actions>
						</kendo-dialog>
					</div>

					<kendo-grid 
						#singleGridDataBinding2 
						[style.margin-top.px]="15" 
						[kendoGridBinding]="singleGridView2"
						[selectable]="singleSelectableSettings"
						[navigable]="true"
						[style.height]="gridHeight"
						[pageSize]="25"
						[pageable]="$any(singleGridView2).length === 0
							? false
							: {
								type: 'input',
								pageSizes: [10, 25, 50, 100, 200]
							}" 
						[sortable]="true" 
						[reorderable]="true" 
						[resizable]="true" 
						[scrollable]="'none'">
							<ng-template kendoGridNoRecordsTemplate>
								<div class="no-records">
									<p>There are no records to display.</p>
								</div>
							</ng-template>
							<ng-template kendoGridToolbarTemplate>
								<div class="sh-custom-toolbar">
									<div class="sh-toolbar-actions">
										<kendo-textbox [style.width.px]="0" [style.border-width]="0"
											placeholder="Quick Filter" [clearButton]="true">
											<ng-template kendoTextBoxPrefixTemplate>
												<kendo-svg-icon [icon]="searchIcon"></kendo-svg-icon>
											</ng-template>
										</kendo-textbox>
										<kendo-button [svgIcon]="downloadIcon"></kendo-button>
										<kendo-button [svgIcon]="columnsIcon"></kendo-button>
										<kendo-button [svgIcon]="settingsIcon"></kendo-button>
									</div>
									<div class="sh-item-count-container">
										<strong class="sh-item-count">{{
											singleGridView2.length }} items</strong>
									</div>
								</div>
							</ng-template>
							<kendo-grid-checkbox-column [width]="45" [showSelectAll]="false"></kendo-grid-checkbox-column>
							<kendo-grid-column field="ProductName" title="Product Name"></kendo-grid-column>
							<kendo-grid-column field="Category" title="Category"></kendo-grid-column>
							<kendo-grid-column field="FirstOrderedOn" title="First Ordered On"><ng-template
									kendoGridCellTemplate let-dataItem>
									{{dataItem.FirstOrderedOn | date:
									'MM/dd/yyyy'}}
								</ng-template>
							</kendo-grid-column>
					</kendo-grid>
				</section>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>

			<pre prism class="dark" [hidden]="currLive !== 'default'">
				{{ filterHTML }}
			</pre>

			<pre prism class="dark" [hidden]="currLive !== 'filter-with-grid'">
				{{ filterWithGridHTML }}
			</pre>

			<pre prism class="dark" [hidden]="currLive !== 'advanced-filter'">
				{{ advancedFilterWithGridHTML }}
			</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/filter/api/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Filter API</a>
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Color (light theme)
					</summary>
					<span class="page-style-list-title">Drop Down</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultDropDown" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Text Field</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultTextField" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Checkbox</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultCheckbox" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Primary Button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultPrimaryButton" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Text Button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultTextButton" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Color (dark theme)
					</summary>
					<span class="page-style-list-title">Drop Down</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkDropDown" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Text Field</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkTextField" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Checkbox</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkCheckbox" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Primary Button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkPrimaryButton" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Text Button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkTextButton" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<span class="page-style-list-title">Drop Down</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypographyDropDown" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Text Field</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypographyTextField" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Checkbox</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypographyCheckbox" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Button - All variations
						(Except icon only)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypographyButton" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Drop Down (Basic Filter)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructureDropDownBasic" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Drop Down (Advanced Filter)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructureDropDownAdvanced"
							[data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Text Field (Basic Filter)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructureTextFieldBasic" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Text Field (Advanced Filter)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructureTextFieldAdvanced"
							[data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Checkbox</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructureCheckbox" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Button - All variations
						(Except Icon only)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color
								/ Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructureButton" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-adding-filter">
			<h3 id="sectionTitleBehaviorsAddingFilter" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-adding-filter')">
				Adding a filter <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Filters are added by an <strong>Add Filters</strong> button
					that appears above the dataset.
					Selecting this button displays a drop-down
					list of all available filters and a search field.
				</li>
				<li class="page-section-list-item">
					Selecting an option from the drop-down immediately adds the
					filter to the left of the <strong>Add
						Filters</strong> button. Any time a new
					filter is added, it appears to the right of the existing
					filters. The filter name also has a
					checkmark next to it in the
					drop-down list when the user selects the <strong>Add
						Filters</strong> button again. This indicates
					to
					the user it’s already been added.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-applying-filter">
			<h3 id="sectionTitleBehaviorsApplyingFilter" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-applying-filter')">
				Applying a filter <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Once added, the new filter is instantly expanded and
					actionable. Users see a drop-down list of the
					criteria options or
					can find a value by typing into a text field. There is a
					checkbox if users can select more than one
					option, and no
					checkbox if users can only select one.
				</li>
				<li class="page-section-list-item">
					When using the text field at the top of the drop-down,
					options are narrowed down as the user types.
					If what the user
					types doesn’t match any criteria, no options appear.
				</li>
				<li class="page-section-list-item">
					When the options or values are selected, the dataset should
					update immediately. If longer than a
					second, use a progress
					indicator.
				</li>
				<li class="page-section-list-item">
					The data that fits the criteria will show, and the data that
					doesn’t is removed.
				</li>
				<li class="page-section-list-item">
					If the selected criteria does not return any results, an
					empty state message is shown. If the
					dataset is large enough, a
					loading state and message might be needed.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-removing-filter">
			<h3 id="sectionTitleBehaviorsRemovingFilter" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-removing-filter')">
				Removing a filter <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Users can remove an optional filter by selecting the X icon.
					The icon is labeled <strong>Remove
						Filter</strong> and is inside the field
					to the right.
				</li>
				<li class="page-section-list-item">Users can also unselect the
					checkbox next to the filter name from the
					<strong>Add Filters</strong> drop-down.
				</li>
				<li class="page-section-list-item">Once one or more optional
					filters is applied, a button labelled
					<strong>Reset Filters</strong> appears to the right of the
					<strong>Add Filters</strong> button. When
					selected, this button
					removes all optional filters.
				</li>
				<li class="page-section-list-item">As filters are removed, the
					<strong>Add Filters</strong> button moves
					to the left, so
					it’s always directly beside the newest remaining
					filter.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-default">
			<h3 id="sectionTitleBehaviorsDefault" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-default')">
				Default <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Depending on the dataset, some filters may already be
					applied and can’t be
					removed. For more information, <a href="patterns/filter#selection-methods-required">go to Required
						Selection Methods</a>.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-refreshing">
			<h3 id="sectionTitleBehaviorsRefreshing" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-refreshing')">
				Refreshing <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Filters are persistent. This means a user’s filter selection
					and the filtered
					data remains when the page is refreshed.
				</li>
			</ul>
		</section>
	</section>

	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariation" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-basic-filter">
			<h3 id="sectionTitleVariationBasicFilter" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-basic-filter')">
				Basic filter <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					On some grids—not all—users can switch between basic and advanced filtering. Basic is the default,
					regular filtering
					method for simple use cases. Advanced filters are for datasets that can be divided using more
					complex criteria.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-advanced-filter">
			<h3 id="sectionTitleVariationAdvancedFilter" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-advanced-filter')">
				Advanced filter <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Users can apply advanced filters by selecting <strong>Switch to Advanced</strong>. This
					clears any existing applied filters. Users then select <strong>Add Filters</strong>. A popup appears
					with the advanced filter options.
				</li>
				<li class="page-section-list-item">
					Advanced filters are not applied until the <strong>Apply Filters</strong> button is selected.
				</li>
				<li class="page-section-list-item">
					Selecting <strong>Discard Changes</strong> discards any changes that have not been applied.
					<strong>Reset Filters</strong> resets all applied filters.
				</li>
				<li class="page-section-list-item">
					<strong>Switch to Basic</strong> clears any applied filters.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="selection-methods">
		<h2 id="sectionTitleSelectionMethods" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('selection-methods')">
			Selection methods <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="selection-methods-single-select">
			<h3 id="sectionTitleSelectionMethodsSingleSelect" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('selection-methods-single-select')">
				Single-select <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Single-select filters only let users choose one option from
					the drop-down.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="selection-methods-multi-select">
			<h3 id="sectionTitleSelectionMethodsMultipleSelect" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('selection-methods-multi-select')">
				Multi-select <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Multi-select filters let users choose more than one option from
					the drop-down. Checkboxes are to the
					left
					of the options.
				</li>
				<li class="page-section-list-item">
					Both single select and multi-select filters should follow
					the same style as drop-downs.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="selection-methods-text-based">
			<h3 id="sectionTitleSelectionMethodsTextBased" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('selection-methods-text-based')">
				Text based <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Text based filters let users find options instead of selecting from a populated drop-down.

					The user must press a button labeled <strong>Apply Filter</strong> for the text-based filter to
					apply.

					If using a keyboard, the user can also press the <strong>Enter</strong> key.
				</li>
				<li class="page-section-list-item">
					Text based filters follow the same style as text fields.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="selection-methods-optional">
			<h3 id="sectionTitleSelectionMethodsOptional" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('selection-methods-optional')">
				Optional <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Optional filters are selectable and removable if the user no
					longer needs them. They usually don't
					have a default value
					or selection.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="selection-methods-required">
			<h3 id="sectionTitleSelectionMethodsRequired" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('selection-methods-required')">
				Required <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Required filters behave like optional filters but can’t be removed. Required
					filters always have a default value, like “All ___”. For example, Detection
					Status: All. For example, Detection Status:
					All.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-content">
			<h3 id="sectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-title"><strong>Filter options</strong></li>
				<li class="page-section-list-item">
					Filters should be research-based and driven by user need.
					Generally, there's a filter for each data
					point found in the
					data set.
				</li>
				<li class="page-section-list-item">
					For example, if the grid had columns for Name, Domain,
					Operating System, and Solution, you would
					want to have a filter
					for each column. If research shows that a particular column
					is unlikely to be filtered, it can be
					excluded from the
					options.
				</li>
				<li class="page-section-list-item">
					The options should be concise and written in Title Case.
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-title"><strong>Ordering</strong></li>
				<li class="page-section-list-item">
					The list of filters, as well as the list of criteria within
					a filter, should be ordered logically. A
					common order is
					A-Z, but other orders can be chronological or by importance
					to the user.
				</li>
				<li class="page-section-list-item">
					For example, a Detected Date column would display the most
					recent date at the top, and Detection
					Status
					might display New at the top if the user would likely want
					to see new detections first. Use data to
					inform these decisions.
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-title"><strong>Help text and tooltip</strong></li>
				<li class="page-section-list-item">
					You can include a <a href="components/tooltip">tooltip</a> or <a href="components/help-text">help
						text</a> to the right of the
					primary filter field's label. This
					content should
					be centered around the meaning of the label.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="resources">
		<h2 id="sectionTitleResources " class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('resources')">
			Resources
			 <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item resource-list-item">
				<a href="https://www.nngroup.com/articles/progress-indicators/" target="_blank">Progress Indicators Make
					a Slow System Less Insufferable</a><span class="resource-source">NN/g Nielsen Norman Group</span>
			</li>
		</ul>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/button-group">Button group component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/checkbox">Checkbox component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/data-grid">Data grid component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/drop-down">Drop-down component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/help-text">Help text component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/modal">Modal component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tag">Tag component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/text-field">Text field component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tooltip">Tooltip component</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
							let subItem of changelogService.getChangelogBySection(
								'Filter Pattern'
							)
						">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
								'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
								subItem.workId
							">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
