<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>

		<app-external-resource [componentName]="'Text Link'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="default">Default</option>
					<!-- <option value="disabled">Disabled</option> -->
					<option value="with-icon">With icon</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<a href="components/text-link#" [class.page-live-show]="currLive === 'default'">View Full Details</a>
				<!-- <a
					aria-disabled="true"
					disabled
					[class.page-live-show]="currLive === 'disabled'"
					>View Full Details</a
				> -->
				<a href="components/text-link#" [class.page-live-show]="currLive === 'with-icon'">Go to External Site
					<kendo-svg-icon [icon]="linkIconSquare"></kendo-svg-icon></a>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark" [hidden]="this.currLive !== 'default'">{{
				linkDefaultHTML
			}}</pre>
			<pre prism class="dark" [hidden]="this.currLive !== 'disabled'">{{
				linkDisabledHTML
			}}</pre>
			<pre prism class="dark" [hidden]="this.currLive !== 'with-icon'">{{
				linkIconHTML
			}}</pre>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="subSectionTitleBehaviorsFocus" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A focus state replaces the browser default focus
					state for text links.
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/text_link/textlink_focus_active.png">
					</div>
					<span class="redlines-caption">Example of text link focus state.</span>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-visited">
			<h3 id="subSectionTitleBehaviorsVisited" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-visited')">
				Visited <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Our applications don't use a visited state for text links.
					Websites use them as a helpful indicator to show when you've
					already visited a page, but users in applications don't use
					links as regularly.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="sectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General use <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Text links are commonly used for the following purposes:
					<ul class="bulleted-list">
						<li class="page-section-list-item">
							Navigating to an external or internal page
						</li>
						<li class="page-section-list-item">
							Performing some type of action
						</li>
						<li class="page-section-list-item">
							Directing users to another area in the product or
							system, like a form field
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="sectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Text links need to be clear about where they'll take the
					user. The link itself should be 3 or fewer words.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Learn more about
										<a href="components/text-link#">Password Management.</a>
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										<a href="components/text-link#">Click here to learn more.</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>

				<li class="page-section-list-item">
					Text links can be title case when they're standalone and
					only a few words. Text links should not be capitalized when
					they're part of a sentence unless they're a proper noun.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										<a href="components/text-link#">Contact Administrator</a>
									</li>

									<li class="comparison-body-text-list-item">
										If you'd like help setting up an API
										account,
										<a href="components/text-link#">read our guidelines.</a>
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										<a href="components/text-link#">Contact Your Administrator To Learn
											More</a>
									</li>

									<li class="comparison-body-text-list-item">
										If you'd like help setting up an API
										account,
										<a href="components/text-link#">Read Our Guidelines.</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-position">
			<h3 id="sectionTitleUsagePosition" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-position')">
				Position <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Standalone text links are by themselves, usually directly
					after a piece of content. Standalone links shouldn't be so
					long that they wrap.
				</li>
				<li class="page-section-list-item">
					Inline text links are inside a paragraph or sentence.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-icons">
			<h3 id="sectionTitleUsageIcons" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-icons')">
				Text links with icons <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Adding an <a href="components/icon">icon</a> to a text link
					can help users to understand its purpose. External links can
					have a “pop-out” icon to show that it will take the user out
					of the application.
				</li>
				<li class="page-section-list-item">
					Text link icons are smaller than regular icons (no bigger
					than 16px by 16px) to match the text they're paired with.
					Icons are to the right of the text link.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tooltip">Tooltip component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>
		<span class="page-related-list-title">Components and patterns that use text links:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/breadcrumb">Breadcrumb component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/data-grid">Data grid component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/toast">Toast component</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
					let subItem of changelogService.getChangelogBySection(
						'Text Link Component'
					)
				">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
						subItem.workId
					">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>