<main class="page-main">
    <app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
    <section class="page-section" id="overview">
        <h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('overview')">
            Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <ul class="page-section-list">
            <li class="page-section-list-item">
                Data visualization, or charting, shows information in a graph.
                We use data visualization to display complex and dense data in a
                way that's easy for users to understand. Data visualization
                helps users see patterns, answer questions, and make decisions.
            </li>
        </ul>
    </section>
    <section class="page-section" id="principles">
        <h2 id="sectionTitlePrinciples" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('principles')">
            Principles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <ul class="page-section-list">
            <li class="page-section-list-item">
                Effective data visualization should:
            </li>
            <li class="page-section-list-item">
                <span class="page-section-list-item-sub-title">Adapt to
                    different users</span>
                Different users have different data needs. Data visualization
                should factor in granularity and scalability &#8212; how will a chart
                change with variations in detail, or with more or fewer data
                points?
            </li>
            <li class="page-section-list-item">
                <span class="page-section-list-item-sub-title">Assist in telling
                    a story</span>
                Data visualization should communicate messages clearly. Users
                should be able to explore and interpret data in a way that's
                engaging and intuitive.
            </li>
            <li class="page-section-list-item">
                <span class="page-section-list-item-sub-title">Accurately
                    represent information</span>
                Users should be able to understand the message without
                distortion. Data visualization should keep the integrity of the
                original data.
            </li>
        </ul>
    </section>
    <section class="page-section" id="choosing-the-right-chart">
        <h2 id="sectionTitleChoosingTheRightChart" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('choosing-the-right-chart')">
            Choosing the right chart <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <ul class="page-section-list">
            <li class="page-section-list-item">
                When deciding how to visualize data, ask yourself what kind of data you’re presenting and what message
                you’re trying to
                communicate.
            </li>
        </ul>

        <section class="page-sub-section" id="choosing-the-right-chart-data-type">
            <h3 id="subSectionTitleChoosingTheRightChartDataType" class="page-sub-section-title"
                title="Copy Link to Clipboard" (click)="copyToClipboard('choosing-the-right-chart-data-type')">
                What type of data is it? <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    Data that can be split into groups is categorical data. Use
                    a <a href="components/bar-chart">bar</a> or <a href="components/pie-chart">pie chart</a> for
                    categorical data.
                </li>
                <li class="page-section-list-item">
                    Nominal numbers are used to identify someone or something,
                    not to denote an actual value or quantity, such as the
                    number on the back of a footballer's shirt, the number on a
                    racing car, or a house number. Ordinal number defining a
                    thing's position in a series, such as first, second or
                    third. Use <a href="components/pie-chart">bar charts</a> for ordinal data, <a
                        href="components/pie-chart">pie charts</a> for
                    nominal.
                </li>
                <li class="page-section-list-item">
                    Discrete data has a finite number of values. Use bar charts
                    for discrete data, and line charts for continuous data.
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="choosing-the-right-chart-trying-to-say">
            <h3 id="subSectionTitleChoosingTheRightChartTryingToSay" class="page-sub-section-title"
                title="Copy Link to Clipboard" (click)="copyToClipboard('choosing-the-right-chart-trying-to-say')">
                What are you trying to say about it? <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    <span class="page-section-list-item-sub-title">Make a
                        comparison</span>
                    If you want to compare values, use a <a href="components/pie-chart">pie chart</a> for relative
                    comparison or a <a href="components/bar-chart">bar chart</a> for precise comparison.
                </li>
                <li class="page-section-list-item">
                    If you want to compare volumes, use an area chart or a
                    bubble chart.
                </li>
                <li class="page-section-list-item">
                    <span class="page-section-list-item-sub-title">Show
                        correlation and changes</span>
                    If you want to demonstrate trends, patterns, or changes over
                    time, use a line chart, <a href="components/bar-chart">bar chart</a>, or scatter plot.
                </li>
                <li class="page-section-list-item">
                    <span class="page-section-list-item-sub-title">Relationships</span>
                    If you want to show a relationship between data, choose
                    scatter plots, bubble charts, or line charts.
                </li>
            </ul>
        </section>

        <section class="page-sub-section" id="choosing-the-right-chart-bar">
            <h3 id="subSectionTitleChoosingTheRightChartBar" class="page-sub-section-title"
                title="Copy Link to Clipboard" (click)="copyToClipboard('choosing-the-right-chart-bar')">
                Bar <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    <span class="page-section-do-list-title">When to use:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            Comparing parts of a bigger set of data,
                            highlighting different categories, or showing change
                            over time.
                        </li>
                        <li class="page-section-list-item">
                            Have categories with long labels—<a href="components/bar-chart">bar charts</a> offer
                            space.
                        </li>
                        <li class="page-section-list-item">
                            If you want to illustrate both positive and negative
                            values in the dataset.
                        </li>
                    </ul>
                </li>
                <li class="page-section-list-item">
                    <span class="page-section-do-list-title">When to
                        avoid:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            If you're using multiple data points.
                        </li>
                        <li class="page-section-list-item">
                            If you have many categories, avoid overloading your
                            graph. Your graph shouldn't have more than 7 bars.
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="choosing-the-right-chart-donut-and-pie">
            <h3 id="subSectionTitleChoosingTheRightChartDonutAndPie" class="page-sub-section-title"
                title="Copy Link to Clipboard" (click)="copyToClipboard('choosing-the-right-chart-donut-and-pie')">
                Donut and pie <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    <span class="page-section-do-list-title">When to use:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            To show relative proportions and percentages of a
                            whole data set.
                        </li>
                        <li class="page-section-list-item">
                            If you have a small dataset.
                        </li>
                        <li class="page-section-list-item">
                            To compare the effect of one factor on different
                            categories.
                        </li>
                        <li class="page-section-list-item">
                            If you have up to 6 categories.
                        </li>
                        <li class="page-section-list-item">
                            If the data is nominal and not ordinal.
                        </li>
                    </ul>
                </li>
                <li class="page-section-list-itngem">
                    <span class="page-section-do-list-title">When to
                        avoid:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            If you have a big dataset.
                        </li>
                        <li class="page-section-list-item">
                            If you want to make a precise or absolute comparison
                            between values.
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
        <section class="page-sub-section" id="choosing-the-right-chart-line">
            <h3 id="subSectionTitleChoosingTheRightChartLine" class="page-sub-section-title"
                title="Copy Link to Clipboard" (click)="copyToClipboard('choosing-the-right-chart-line')">
                Line <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
            </h3>
            <ul class="page-section-list">
                <li class="page-section-list-item">
                    <span class="page-section-do-list-title">When to use:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            If you have a continuous dataset that changes over
                            time.
                        </li>
                        <li class="page-section-list-item">
                            If your dataset is too big for a bar chart.
                        </li>
                        <li class="page-section-list-item">
                            If you want to display multiple series for the same
                            timeline.
                        </li>
                        <li class="page-section-list-item">
                            If you want to visualize trends instead of exact
                            values.
                        </li>
                    </ul>
                </li>
                <li class="page-section-list-item">
                    <span class="page-section-do-list-title">When to
                        avoid:</span>
                    <ul class="page-section-do-list">
                        <li class="page-section-list-item">
                            Line charts work better with bigger datasets, so, if
                            you have a small one, use a <a href="components/bar-chart">bar chart</a> instead.
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
    </section>
    <section class="page-section" id="related">
        <h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('related')">
            Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <span class="page-related-list-title">Referenced in this section:</span>
        <ul class="page-section-list page-related-list">
            <li class="page-related-list-item">
                <a href="components/bar-chart">Bar chart component</a>
            </li>
            <li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
            <li class="page-related-list-item">
                <a href="components/donut-chart">Donut chart component</a>
            </li>
            <li class="page-related-list-item">
                <a href="components/line-chart">Line chart component</a>
            </li>
            <li class="page-related-list-item">
                <a href="components/pie-chart">Pie chart component</a>
            </li>
            <li class="page-related-list-item">
                <a href="guidelines/typography">Typography guidelines</a>
            </li>
        </ul>
    </section>
    <section class="page-section" id="history">
        <h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
            (click)="copyToClipboard('history')">
            Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
        </h2>
        <ul class="page-history-list">
            <li class="page-history-list-item" *ngFor="
    					let subItem of changelogService.getChangelogBySection(
    						'Data Visualization Guidelines'
    					)
    				">
                <span class="page-history-list-item-title">{{
                    changelogService.formatDate(subItem.changeDate)
                    }}</span>
                <a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
    						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
    						subItem.workId
    					">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
            </li>
        </ul>
    </section>
</main>