import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCheck, shXmark, shLink } from '@beyondtrust/shield-icons';

// Data Sources
import {
	numberFieldStylesDefaultTheme,
	numberFieldStylesDarkTheme,
	numberFieldStylesTypography,
	numberFieldStylesStructure,
} from './number-field-styles';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'app-number-field',
	templateUrl: './number-field.component.html',
	styleUrls: ['./number-field.component.less'],
})
export class NumberFieldComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';

	public doThisIcon = shCheck;
	public notThisIcon = shXmark;
	public linkIcon = shLink;

	// Imported Style Variables
	public stylesDefault = numberFieldStylesDefaultTheme;
	public stylesDark = numberFieldStylesDarkTheme;
	public stylesTypography = numberFieldStylesTypography;
	public stylesStructure = numberFieldStylesStructure;

	public numericValue: number;
	public minimumValue = 0;
	public maximumValue = 50;
	public isDisabled = false;
	public autoCorrect = true;

	public form: FormGroup = new FormGroup({
		maxAccounts: new FormControl(),
	});

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Hover',
					value: 'hover',
				},
				{
					name: 'Error',
					value: 'error',
				},
				{
					name: 'Disable',
					value: 'disable',
				},
				{
					name: 'Help text',
					value: 'help-text',
				},
				{
					name: 'Instructional text',
					value: 'instructional-text',
				},
				{
					name: 'Buttons',
					value: 'buttons',
				},
				{
					name: 'Values',
					value: 'values',
				},
				{
					name: 'Null state',
					value: 'null-state',
				},
				{
					name: 'Minimum and maximum values',
					value: 'min-max',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Accessibility',
					value: 'accessibility',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public numberFieldHTML = `
<kendo-formfield showHints="initial" showErrors="initial">
	<kendo-label text="Maximum Accounts (Required)" [for]="maxAccounts"></kendo-label>
	<kendo-numerictextbox 
	#maxAccounts 
	required
	formControlName="maxAccounts"
	format="##" 
	[min]="minimumValue" 
	[max]="maximumValue" 
	[value]="numericValue" 
	[disabled]="isDisabled" 
	[style.width.px]="207">
	</kendo-numerictextbox>
	<kendo-formhint>Select the maximum number of accounts allowed for this group.</kendo-formhint>
	<kendo-formerror>Maximum accounts is required.</kendo-formerror>
</kendo-formfield>
	`;
}
