import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shLink } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.less'],
})
export class FaqComponent {
	public currVisible = 'overview';
	public linkIcon = shLink;
	
	public pageSubNavData = [
		{
			name: 'What is Shield?',
			value: 'what-is-shield',
			empty: false,
			children: [],
		},
		{
			name: 'Who created Shield? Who updates it?',
			value: 'create-update-shield',
			empty: false,
			children: [],
		},
		{
			name: 'How does content get published in Shield?',
			value: 'publishing',
			empty: false,
			children: [],
		},
		{
			name: 'What is the goal of Shield?',
			value: 'goals',
			empty: false,
			children: [],
		},
		{
			name: 'Who can use Shield?',
			value: 'audience',
			empty: false,
			children: [],
		},
		{
			name: 'What if my design differs from what’s in Shield?',
			value: 'new-designs',
			empty: false,
			children: [],
		},
		{
			name: 'How are products measured against Shield and how do we determine if a system follows Shield?',
			value: 'measuring',
			empty: false,
			children: [],
		},
		{
			name: 'Is Shield complete?',
			value: 'is-shield-complete',
			empty: false,
			children: [],
		},
		{
			name: 'Why do we use Kendo?',
			value: 'kendo',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
