// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const buttonGroupStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: '9da4ae',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Icon (selected)',
		property: 'color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Border (selected)',
		property: 'border-color',
		value: '1c63a5',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Border: hover',
		property: 'border-color',
		value: '1771c6',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	}
];

export const buttonGroupStylesDefaultThemeIcon = [
	{
		name: 'Icon (non-logo)',
		property: 'color',
		value: '081826',
		isColorTile: true,
	}
];

export const buttonGroupStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: '949494',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Icon (selected)',
		property: 'color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Border (selected)',
		property: 'border-color',
		value: '53b1fd',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Border: hover',
		property: 'border-color',
		value: '7cc3fd',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const buttonGroupStylesDarkThemeIcon = [
	{
		name: 'Icon (non-logo)',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	}
];

export const buttonGroupStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];

export const buttonGroupStylesStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: '90px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: 'unset',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '36px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Border',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: 'Border-bottom (selected)',
		property: 'border-width',
		value: '3px',
		isColorTile: false,
	},
	{
		name: '* All outside corners',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Outline: focus',
		property: 'solid',
		value: '2px',
		isColorTile: false,
	},
];
export const buttonGroupStylesStructureIcon = [
	{
		name: 'Icon',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
];
