<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Time Picker'" [includeDescription]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body" [formGroup]="form">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change example">
					<option value="default">Default</option>
					<option value="disabled">Disabled</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<kendo-formfield>
					<kendo-label [for]="timeOfDay" text="Time of Day">
					</kendo-label>
					<kendo-timepicker #timeOfDay formControlName="timeOfDay" [disabled]="disabled"
						[incompleteDateValidation]="true" [(ngModel)]="timeValue"></kendo-timepicker>
					<kendo-formhint>hh:mm am/pm</kendo-formhint>
					<kendo-formerror *ngIf="timeValue === null && !timeControl.errors?.incompleteDate">
						Time of day is required. Enter a time.
					</kendo-formerror>
					<kendo-formerror *ngIf="timeControl.errors?.incompleteDate">
						Time of day must not be incomplete.
					</kendo-formerror>
				</kendo-formfield>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>

			<pre prism class="dark">{{timePickerHTML}}</pre>
			<pre prism class="dark">{{timePickerTS}}</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/dateinputs/api/TimePickerComponent/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo TimePicker API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/time-picker#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Primary text button (“Now” button)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of buttonStylesDefaultText" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Primary button (“Set” button)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of buttonStylesDefaultPrimary" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Secondary button (“Cancel” button)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of buttonStylesDefaultSecondary" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Text field</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of textFieldStylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Primary text button (“Now” button)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of buttonStylesDarkText" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Primary button (“Set” button)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of buttonStylesDarkPrimary" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Secondary button (“Cancel” button)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of buttonStylesDarkSecondary" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Text field</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of textFieldStylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Text field</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesTextFieldTypography" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Primary, secondary and primary text button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesButtonTextTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="subSectionTitleBehaviorsFocus" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A focus state replaces the browser default focus state for time pickers.
					The border also changes color on focus.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-text-field">
			<h3 id="subSectionTitleTextField" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-text-field')">
				Text field
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Users enter a value by typing in the text field. Time pickers use input masks that
					only allow for characters that match what is expected. A time picker should tell the
					user what the expected time format is.
				</li>
				<li class="page-section-list-item">
					A clock <a href="components/icon">icon</a> is inside the text box to the right to show it's a time
					picker.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-clock-popup">
			<h3 id="subSectionTitleTextField" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-text-field')">
				Clock pop-up
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Selecting the clock <a href="components/icon">icon</a> inside the text field opens a pop-up that
					allows users to pick a time.
					The pop-up mimics a digital clock and not an analog clock. Users can choose the time, minute, and
					AM/PM.
					Users then hit the <b>Set </b> button. When the pop-up is reopened, this set time displays in the
					top left corner.
					If no time has been selected, a default time should be set appropriate to the use case. A <b>Now</b>
					<a href="components/button"> text button</a>
					inputs the current time.
				</li>
				<li class="page-section-list-item">

				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-masking">
			<h3 id="subSectionTitleMasking" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-masking')">
				Input masking
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When typing in the time picker, each section of the value has an input mask to prevent
					characters that don't match a 12-hour clock. The time picker allows numbers 1-12 for hours,
					0-59 for minutes, and either AM or PM.
				</li>
				<li class="page-section-list-item">
					After a user completes a section, focus should jump to the next section. For example, when a user
					enters an hour focus will then jump to minutes, followed by AM or PM.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-error">
			<h3 id="subSectionTitleError" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-error')">
				Error
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Error states in a time picker change the color of the border and display an error message
					below the field. When the error condition is fixed, the border returns to normal and the
					error message is removed.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-disabled">
			<h3 id="subSectionTitleDisabled" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-disabled')">
				Disabled
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When disabled, the time picker field is displayed at 50% opacity. The field and
					icons cannot be clicked, focused, or interacted with in any way.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-help-text">
			<h3 id="subSectionTitleHelpText" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-help-text')">
				Help text
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Time picker labels can give extra context or instructions using a <a
						href="components/help-text">help text component</a>.
					This appears as a question mark icon to the right of the label.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-instructional-text">
			<h3 id="subSectionTitleInstructionalText" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-instructional-text')">
				Instructional text
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Instructional text appears below the time picker. It should
					only be used for very short messages. The text can wrap if it's longer than the field. If more
					instruction is needed, use <a href="components/help-text">help text</a>.
				</li>
			</ul>
		</section>
	</section>

	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title page-section-title-empty"
			title="Copy Link to Clipboard" (click)="copyToClipboard('variations')">
			Variations
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-section-content">
			There are no variations for this component.
		</section>
	</section>

	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-time-format">
			<h3 id="subSectionTitleTimeFormat" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-time-format')">
				Time format
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					As outlined in <a href="guidelines/formatting">the formatting guidelines</a>, time pickers
					follow an hours: minutes format, followed by AM or PM. Sometimes a user’s browser or system
					settings may override the AM or PM formatting rule and replace them with a.m. or p.m., but
					our designs should follow the guidelines.
				</li>
				<li class="page-section-list-item">
					We use a 12-hour clock, which is the default and should be used unless a 24-hour clock is
					unavoidable.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content
				<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Label</span>
					A time picker label should be short, no more than a few words.
					The label should indicate what will happen at the time the user selects.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="checkIcon"></kendo-svg-icon>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Start Time
									</li>
									<li class="comparison-body-text-list-item">
										Scan Time
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Instructional Text</span>
					You can give more guidance in instructional text, like the format. This helps prevent errors.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="checkIcon"></kendo-svg-icon>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										These times are in your local time zone.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Help Text</span>
					If there are longer instructions that could give users more context, use <a
						href="components/help-text">help text</a>. Don’t use
					help text for information that the user needs to know to be successful, like the format.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="checkIcon"></kendo-svg-icon>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										This is the time the next batch of computers will update.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="xMarkIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Use the 12-hour clock.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Error Message</span>
					Error text appears below the time picker.
					It should be concise and jargon-free. Be specific to what went wrong and tell the user
					how to move forward. The text can wrap if it's longer than the field.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="checkIcon"></kendo-svg-icon>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										You can't select a time in the past.
									</li>
									<li class="comparison-body-text-list-item">
										Pick a future time.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="xMarkIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Oops! Try again.
									</li>
									<li class="comparison-body-text-list-item">
										Error. Could not validate entry.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/date-picker">Date picker component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/formatting">Formatting guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/help-text">Help text component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/text-field">Text field component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>

		<!-- <span class="page-related-list-title">Components and patterns that use time pickers:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/overview">Placeholder component</a>
			</li>
			<li class="page-related-list-item">
				<a href="patterns/overview">Placeholder pattern</a>
			</li>
		</ul> -->
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history
			<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Time Picker Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
