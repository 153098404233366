// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const filterStylesStructure = [
	{
		name: 'Filter Tag',
		property: 'margin-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '300px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Text Field in Drop Down',
		property: 'margin',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Text Button (Reset Filter)',
		property: 'margin-left',
		value: '16px',
		isColorTile: false,
	},
];

export const dropDownFilterStylesStructureBasic = [
	{
		name: 'Container',
		property: 'min-width',
		value: '240px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Container (area before first item row)',
		property: 'padding-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Container (area after last item row)',
		property: 'padding-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Item Row',
		property: 'height',
		value: '46px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '14px',
		isColorTile: false,
	},
];

export const dropDownFilterStylesStructureAdvanced = [
	{
		name: 'Container',
		property: 'min-width',
		value: '200px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Item Row',
		property: 'height',
		value: '46px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '14px',
		isColorTile: false,
	},
];

export const textFieldFilterStylesTypography = [
	{
		name: 'Text (Input)',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];

export const textFieldFilterStylesStructureBasic = [
	{
		name: 'Container (Text Filter)',
		property: 'width',
		value: '204px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '32px',
		isColorTile: false,
	},
	{
		name: 'Container (Drop Down Search)',
		property: 'width',
		value: '222px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '30px',
		isColorTile: false,
	},
	{
		name: 'Container (Both)',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
];

export const textFieldFilterStylesStructureAdvanced = [
	{
		name: 'Container',
		property: 'min-width',
		value: '200px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '32px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
];
