import { Component, TemplateRef, ViewChild, OnDestroy} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import {
	NotificationRef,
	NotificationService,
} from '@progress/kendo-angular-notification';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';

// Data Sources
import {
	toastStylesDefaultThemeAll,
	toastStylesDefaultThemeInfo,
	toastStylesDefaultThemeSuccess,
	toastStylesDefaultThemeWarning,
	toastStylesDefaultThemeError,
	toastStylesDarkThemeAll,
	toastStylesDarkThemeInfo,
	toastStylesDarkThemeSuccess,
	toastStylesDarkThemeWarning,
	toastStylesDarkThemeError,
	toastStylesTypography,
	toastStylesStructure,
	toastsTextLinkStylesStructure,
	toastTextLinkStylesTypography
} from './toast-styles';

import {
	textLinkStylesDefaultTheme,
	textLinkStylesDarkTheme,
} from '../text-link/text-link-styles';

import {
	buttonStylesDefaultThemeSecondary,
	buttonStylesDarkThemeSecondary,
	buttonStylesTypography,
	buttonStylesStructure,
} from '../button/button-styles';
import { ToastTemplateComponent } from './toast-template.component';
import { ChangelogService } from 'src/app/services/changelog.service';
import {
	shCheck,
	shCirclePlay,
	shCircleStop,
	shCircleXmark,
	shLink,
	shXmark,
} from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.less'],
})
export class ToastComponent implements OnDestroy {
	@ViewChild('toastControlsTemplate', { read: TemplateRef })
	public notificationTemplate: TemplateRef<unknown>;

	public currLive = 'success';
	public currTheme = 'default';
	public currVisible = 'overview';
	public linkIcon = shLink;
	public doThisIcon = shCheck;
	public notThisIcon = shXmark;
	public pauseIcon = shCircleStop;
	public resumeIcon = shCirclePlay;
	public closeIcon = shCircleXmark;

	public toastRefs: NotificationRef[] = [];
	public controlsRef?: NotificationRef;

	// Imported Style Variables
	public stylesDefaultAll = toastStylesDefaultThemeAll;
	public stylesDefaultInfo = toastStylesDefaultThemeInfo;
	public stylesDefaultSuccess = toastStylesDefaultThemeSuccess;
	public stylesDefaultWarning = toastStylesDefaultThemeWarning;
	public stylesDefaultError = toastStylesDefaultThemeError;
	public stylesDarkAll = toastStylesDarkThemeAll;
	public stylesDarkInfo = toastStylesDarkThemeInfo;
	public stylesDarkSuccess = toastStylesDarkThemeSuccess;
	public stylesDarkWarning = toastStylesDarkThemeWarning;
	public stylesDarkError = toastStylesDarkThemeError;
	public stylesTypography = toastStylesTypography;
	public stylesStructure = toastStylesStructure;
	public textLinkStylesDefault = textLinkStylesDefaultTheme;
	public textLinkStylesDark = textLinkStylesDarkTheme;
	public textLinkStylesTypography = toastTextLinkStylesTypography;
	public textLinkStylesStructure = toastsTextLinkStylesStructure;
	public buttonStylesDefaultSecondary = buttonStylesDefaultThemeSecondary;
	public buttonStylesDarkSecondary = buttonStylesDarkThemeSecondary;
	public buttonStylesTypography = buttonStylesTypography;
	public buttonStylesStructure = buttonStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Duration',
					value: 'duration',
				},
				{
					name: 'Pause/resume',
					value: 'pause-resume',
				},
				{
					name: 'Multiple toasts',
					value: 'multiple',
				},
				{
					name: 'Actions and dismiss',
					value: 'actions',
				},
				{
					name: 'Dismiss all',
					value: 'dismiss-all',
				},
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Animation',
					value: 'animation',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Informational',
					value: 'info',
				},
				{
					name: 'Success',
					value: 'success',
				},

				{
					name: 'Warning',
					value: 'warning',
				},
				{
					name: 'Error',
					value: 'error',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Positioning',
					value: 'positioning',
				},
				{
					name: 'Icons',
					value: 'icons',
				},
				{
					name: 'Toasts or banners',
					value: 'toasts-or-banners',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		scrollService: WindowScrollService,
		private notificationService: NotificationService,
		public changelogService: ChangelogService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	private showControls(): void {
		this.controlsRef = this.notificationService.show({
			content: this.notificationTemplate,
			closable: true,
			cssClass: 'sh-hide-x',
			type: { style: 'none', icon: false },
			width: 384,
		});
	}

	public ngOnDestroy(): void {
		document.documentElement.setAttribute('data-theme', 'light');
		this.dismissAll();
	}

	private initToast(
		ref: NotificationRef,
		type: 'success' | 'info' | 'warning' | 'error'
	) {
		this.toastRefs.push(ref);
		ref.content!.instance.toastType = type;
		if (type === 'success') {
		 	ref.content!.instance.content = 'You saved the changes.';
		} else if (type === 'info') {
			ref.content!.instance.content = 'You didn’t make any changes, so there’s nothing to save.';
		} else if (type === 'warning') {
			ref.content!.instance.content = 'There will be system maintenance at 2:30 EST. You won’t be able to make any changes during this time.';
		} else if (type === 'error') {
		  	ref.content!.instance.content = 'Changes not saved. That file name already exists.';
		}
		ref.content!.instance.start(8000);
		ref.content!.instance.onComplete.subscribe(() => {
			ref.hide();
			this.toastRefs.splice(this.toastRefs.indexOf(ref), 1);
			if (this.toastRefs.length === 0) {
				this.controlsRef?.hide();
			}
		});
	}

	public showToast() {
		if (this.toastRefs.length === 0) {
			this.showControls();
		}
		const type = this.currLive as any;
		const ref = this.notificationService.show({
			content: ToastTemplateComponent,
			closable: true,
			animation: { type: 'slide', duration: 250 },
			cssClass: 'sh-hide-x',
			type: { style: type, icon: false },
			width: 384,
		});
		this.initToast(ref, type);
	}

	public pauseAll() {
		for (const toast of this.toastRefs) {
			toast.content.instance.pause();
		}
	}

	public resumeAll() {
		for (const toast of this.toastRefs) {
			toast.content.instance.resume();
		}
	}

	public dismissAll() {
		for (const toast of this.toastRefs) {
			toast.hide();
		}
		this.toastRefs = [];
		this.controlsRef?.hide();
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public toastHTML: any = `
<ng-template #toastControlsTemplate>
	<div class="sh-toast-controls">
		<kendo-button [svgIcon]="pauseIcon" themeColor="primary" fillMode="flat" (click)="pauseAll()"> Pause </kendo-button>
		<kendo-button [svgIcon]="resumeIcon" themeColor="primary" fillMode="flat" (click)="resumeAll()"> Resume </kendo-button>
		<kendo-button [svgIcon]="closeIcon" themeColor="primary" fillMode="flat" (click)="dismissAll()"> Dismiss all </kendo-button>
	</div>
</ng-template>
<div class="toast-type-buttons">
    <kendo-button (click)="showToast()" themeColor="primary"> Show toast </kendo-button>
</div>`;

	public toastTs = `
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { ToastTemplateComponent } from './toast-template.component';
import { shCirclePlay, shCircleStop, shCircleXmark } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss']
})
export class ToastComponent {
	@ViewChild('toastControlsTemplate', { read: TemplateRef }) public notificationTemplate: TemplateRef<unknown>;

	public pauseIcon = shCircleStop;
	public resumeIcon = shCirclePlay;
	public closeIcon = shCircleXmark;

	private toastRefs: NotificationRef[] = [];
	private controlsRef?: NotificationRef;

	constructor(private notificationService: NotificationService) {}

	private showControls(): void {
		this.controlsRef = this.notificationService.show({
			content: this.notificationTemplate,
			closable: true,
			cssClass: 'sh-hide-x',
		  	type: { style: 'none', icon: false },
			width: 384
		});
	}

	private initToast(ref: NotificationRef, type: 'success' | 'info' | 'warning' | 'error') {
		this.toastRefs.push(ref);
		ref.content!.instance.toastType = type;
		ref.content!.instance.title = type[0].toUpperCase() + type.slice(1);
		if (type === 'success') {
			ref.content!.instance.content = 'You saved the changes.';
		} else if (type === 'info') {
			ref.content!.instance.content = 'You didn’t make any changes, so there’s nothing to save.';
		} else if (type === 'warning') {
			ref.content!.instance.content = 'There will be system maintenance at 2:30 EST. You won’t be able to make any changes during this time.';
		} else if (type === 'error') {
			ref.content!.instance.content = 'Changes not saved. That file name already exists.';
		}
		ref.content!.instance.start(8000);
		ref.content!.instance.onComplete.subscribe(() => {
		  	ref.hide();
		  	this.toastRefs.splice(this.toastRefs.indexOf(ref), 1);
		  	if (this.toastRefs.length === 0) {
				this.controlsRef?.hide();
		  	}
		});
	}

	public showToast(type: 'success' | 'info' | 'warning' | 'error') {
		this.controlsRef?.hide();
		const ref = this.notificationService.show({
			content: ToastTemplateComponent,
			closable: true,
			cssClass: 'sh-hide-x',
			animation: { type: 'slide', duration: 250 },
			type: { style: type, icon: false },
			width: 384
		});
		this.initToast(ref, type);
		this.showControls();
	}

	public pauseAll() {
		for (const toast of this.toastRefs) {
		  	toast.content.instance.pause();
		}
	}

	public resumeAll() {
		for (const toast of this.toastRefs) {
		  	toast.content.instance.resume();
		}
	}

	public dismissAll() {
		for (const toast of this.toastRefs) {
			toast.hide();
		}
		this.toastRefs = [];
		this.controlsRef?.hide();
	}
}`;

	public toastTemplateTs = `
import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { shCircleCheck, shCircleInfo, shCircleSlash, shCircleExclamation } from '@beyondtrust/shield-icons';

@Component({
	selector: 'sh-custom-toast',
	template: \`
		<kendo-progressbar [value]="time" [animation]="true" [max]="total" [label]="false"></kendo-progressbar>
		<kendo-svg-icon [icon]="icon"></kendo-svg-icon>
		<p class="sh-toast-content">{{ content }}</p>
		<button kendoButton themeColor="primary" fillMode="flat" (click)="done()">Dismiss</button>
	\`,
	styles: []
})
export class ToastTemplateComponent implements OnDestroy {
	private intervalId: any = null;
	public time: number = 0;
	public total: number = 0;

	public get icon() {
		if (this.toastType === 'success') {
			return shCircleCheck;
		} else if (this.toastType === 'info') {
			return shCircleInfo;
		} else if (this.toastType === 'warning') {
			return shCircleExclamation;
		} else {
			return shCircleSlash;
		}
	}

	@Input() public toastType: string;
	@Input() public content: string = '';

	@Output() public onComplete: EventEmitter<void> = new EventEmitter();

	public ngOnDestroy(): void {
		clearInterval(this.intervalId);
	}

	public start(ms: number): void {
		this.total = ms;
		this.time = ms;
		this.startCountdown();
	}

	private startCountdown(): void {
		this.intervalId = setInterval(() => {
			this.time -= 10;
			if (this.time < 0) {
				this.done();
			}
		}, 10);
	}

	public pause(): void {
		if (this.intervalId) {
			clearInterval(this.intervalId);
			this.intervalId = null;
		}
	}

	public resume(): void {
		if (!this.intervalId) {
			this.startCountdown();
		}
	}

	public done(): void {
		this.onComplete.emit();
		clearInterval(this.intervalId);
	}
}`;
}
