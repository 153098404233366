import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './common/notfound/notfound.component';

// General
import { ChangelogComponent } from './general/changelog/changelog.component';
import { ContactComponent } from './general/contact/contact.component';
import { ContributeComponent } from './general/contribute/contribute.component';
import { FaqComponent } from './general/faq/faq.component';
import { GeneralComponent } from './general/general.component';
import { GlossaryComponent } from './general/glossary/glossary.component';

// Guidelines
import { AccessibilityComponent } from './guidelines/accessibility/accessibility.component';
import { BrandingComponent } from './guidelines/branding/branding.component';
import { ColorsComponent } from './guidelines/colors/colors.component';
import { ContentComponent } from './guidelines/content/content.component';
import { DataVisualizationComponent } from './guidelines/data-visualization/data-visualization.component';
import { FormattingComponent } from './guidelines/formatting/formatting.component';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { IconsComponent } from './guidelines/icons/icons.component';
import { MotionComponent } from './guidelines/motion/motion.component';
import { SeverityGuidelineComponent } from './guidelines/severity/severity.component';
import { SpacingComponent } from './guidelines/spacing/spacing.component';
import { TypographyComponent } from './guidelines/typography/typography.component';

// Components
import { AccordionComponent } from './components/accordion/accordion.component';
import { BannerComponent } from './components/banner/banner.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonComponent } from './components/button/button.component';
import { ButtonGroupComponent } from './components/button-group/button-group.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { ComponentsComponent } from './components/components.component';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { HelpTextComponent } from './components/help-text/help-text.component';
import { LabelValueComponent } from './components/label-value/label-value.component';
import { MenuComponent } from './components/menu/menu.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { ListComponent } from './components/list/list.component';
import { ModalComponent } from './components/modal/modal.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NumberFieldComponent } from './components/number-field/number-field.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PasswordFieldComponent } from './components/password-field/password-field.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { PopupComponent } from './components/popup/popup.component';
import { ProgressIndicatorComponent } from './components/progress-indicator/progress-indicator.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { SeverityComponent } from './components/severity/severity.component';
import { SliderComponent } from './components/slider/slider.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TabComponent } from './components/tab/tab.component';
import { TagComponent } from './components/tag/tag.component';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { TextLinkComponent } from './components/text-link/text-link.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { ToastComponent } from './components/toast/toast.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';

// Patterns
import { ApplicationHeaderComponent } from './patterns/application-header/application-header.component';
import { ApplicationMenuComponent } from './patterns/application-menu/application-menu.component';
import { CardComponent } from './patterns/card/card.component';
import { DashboardComponent } from './patterns/dashboard/dashboard.component';
import { ErrorPageComponent } from './patterns/error-page/error-page.component';
import { EmptyStateComponent } from './patterns/empty-state/empty-state.component';
import { FilterComponent } from './patterns/filter/filter.component';
import { FormComponent } from './patterns/form/form.component';
import { LoginComponent } from './patterns/login/login.component';
import { NotificationComponent } from './patterns/notification/notification.component';
import { PanelComponent } from './patterns/panel/panel.component';
import { PatternsComponent } from './patterns/patterns.component';
import { TreeBrowserComponent } from './patterns/tree-browser/tree-browser.component';
import { WizardComponent } from './patterns/wizard/wizard.component';
import { IconComponent } from './components/icon/icon.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { BadgeComponent } from './components/badge/badge.component';

const routes: Routes = [
	{
		path: 'not-found',
		component: NotFoundComponent,
	},
	{
		path: '',
		component: GeneralComponent,
	},
	{
		path: 'login/callback',
		component: OktaCallbackComponent
	},
	{
		path: 'general/changelog',
		component: ChangelogComponent,
	},
	{
		path: 'general/faq',
		component: FaqComponent,
	},
	{
		path: 'general/glossary',
		component: GlossaryComponent,
	},
	{
		path: 'general/contribute',
		component: ContributeComponent,
	},
	{
		path: 'general/contact',
		component: ContactComponent,
	},
	{
		path: 'guidelines/overview',
		component: GuidelinesComponent,
	},
	{
		path: 'guidelines/accessibility',
		component: AccessibilityComponent,
	},
	{
		path: 'guidelines/branding',
		component: BrandingComponent,
	},
	{
		path: 'guidelines/colors',
		component: ColorsComponent,
	},
	{
		path: 'guidelines/content',
		component: ContentComponent,
	},
	{
		path: 'guidelines/data-visualization',
		component: DataVisualizationComponent,
	},
	{
		path: 'guidelines/formatting',
		component: FormattingComponent,
	},
	{
		path: 'guidelines/icons',
		component: IconsComponent,
	},
	{
		path: 'guidelines/motion',
		component: MotionComponent,
	},
	{
		path: 'guidelines/severity',
		component: SeverityGuidelineComponent,
	},
	{
		path: 'guidelines/spacing',
		component: SpacingComponent,
	},
	{
		path: 'guidelines/typography',
		component: TypographyComponent,
	},
	{
		path: 'components/overview',
		component: ComponentsComponent,
	},
	{
		path: 'components/accordion',
		component: AccordionComponent,
	},
	//{
	//	path: 'components/banner',
	//	component: BannerComponent,
	//},
	{
		path: 'components/badge',
		component: BadgeComponent,
	},
	{
		path: 'components/bar-chart',
		component: BarChartComponent,
	},
	{
		path: 'components/breadcrumb',
		component: BreadcrumbComponent,
	},
	{
		path: 'components/button',
		component: ButtonComponent,
	},
	{
		path: 'components/button-group',
		component: ButtonGroupComponent,
	},
	{
		path: 'components/checkbox',
		component: CheckboxComponent,
	},
	{
		path: 'components/combo-box',
		component: ComboBoxComponent,
	},
	{
		path: 'components/data-grid',
		component: DataGridComponent,
	},
	{
		path: 'components/data-table',
		component: DataTableComponent,
	},
	{
		path: 'components/date-picker',
		component: DatePickerComponent,
	},
	{
		path: 'components/donut-chart',
		component: DonutChartComponent,
	},
	//{
	//	path: 'components/drop-down',
	//	component: DropDownComponent,
	//},
	{
		path: 'components/file-upload',
		component: FileUploadComponent,
	},
	{
		path: 'components/help-text',
		component: HelpTextComponent,
	},
	{
		path: 'components/icon',
		component: IconComponent,
	},
	{
		path: 'components/label-value',
		component: LabelValueComponent,
	},
	{
		path: 'components/line-chart',
		component: LineChartComponent,
	},
	//{
	//	path: 'components/list',
	//	component: ListComponent,
	//},
	{
		path: 'components/menu',
		component: MenuComponent,
	},
	{
		path: 'components/modal',
		component: ModalComponent,
	},
	{
		path: 'components/number-field',
		component: NumberFieldComponent,
	},
	{
		path: 'components/pagination',
		component: PaginationComponent,
	},
	{
		path: 'components/password-field',
		component: PasswordFieldComponent,
	},
	{
		path: 'components/pie-chart',
		component: PieChartComponent,
	},
	//{
	//	path: 'components/popup',
	//	component: PopupComponent,
	//},
	{
		path: 'components/progress-indicator',
		component: ProgressIndicatorComponent,
	},
	{
		path: 'components/radio-button',
		component: RadioButtonComponent,
	},
	//{
	//	path: 'components/range-slider',
	//	component: RangeSliderComponent,
	//},
	{
		path: 'components/severity',
		component: SeverityComponent,
	},
	{
		path: 'components/slider',
		component: SliderComponent,
	},
	{
		path: 'components/switch',
		component: SwitchComponent,
	},
	{
		path: 'components/tab',
		component: TabComponent,
	},
	{
		path: 'components/tag',
		component: TagComponent,
	},
	{
		path: 'components/text-field',
		component: TextFieldComponent,
	},
	{
		path: 'components/text-link',
		component: TextLinkComponent,
	},
	{
		path: 'components/time-picker',
		component: TimePickerComponent,
	},
	{
		path: 'components/timeline',
		component: TimelineComponent,
	},
	{
		path: 'components/toast',
		component: ToastComponent,
	},
	{
		path: 'components/tooltip',
		component: TooltipComponent,
	},
	{
		path: 'patterns/overview',
		component: PatternsComponent,
	},
	//{
	//	path: 'patterns/application-header',
	//	component: ApplicationHeaderComponent,
	//},
	//{
	//	path: 'patterns/application-menu',
	//	component: ApplicationMenuComponent,
	//},
	//{
	//	path: 'patterns/card',
	//	component: CardComponent,
	//},
	//{
	//	path: 'patterns/dashboard',
	//	component: DashboardComponent,
	//},
	//{
	//	path: 'patterns/error-page',
	//	component: ErrorPageComponent,
	//},
	//{
	//	path: 'patterns/empty-state',
	//	component: EmptyStateComponent,
	//},
	{
		path: 'patterns/filter',
		component: FilterComponent,
	},
	//{
	//	path: 'patterns/form',
	//	component: FormComponent,
	//},
	//{
	//	path: 'patterns/login',
	//	component: LoginComponent,
	//},
	//{
	//	path: 'patterns/notification',
	//	component: NotificationComponent,
	//},
	//{
	//	path: 'patterns/panel',
	//	component: PanelComponent,
	//},
	//{
	//	path: 'patterns/patterns',
	//	component: PatternsComponent,
	//},
	//{
	//	path: 'patterns/tree-browser',
	//	component: TreeBrowserComponent,
	//},
	//{
	//	path: 'patterns/wizard',
	//	component: WizardComponent,
	//},
	{
		path: '**',
		redirectTo: '/',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			anchorScrolling: 'enabled',
			scrollOffset: [0, 75],
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
