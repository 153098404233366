import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shLink } from '@beyondtrust/shield-icons';

// Data Sources
import {
	modalStylesDefaultTheme,
	modalStylesDarkTheme,
	modalStylesTypography,
	modalStylesStructure,
	modalStylesTypographyButton,
	modalStylesStructureButton
} from './modal-styles';
import {
	buttonStylesDefaultThemePrimary,
	buttonStylesDefaultThemeSecondary,
	buttonStylesDefaultThemeDanger,
	buttonStylesDarkThemePrimary,
	buttonStylesDarkThemeSecondary,
	buttonStylesDarkThemeDanger
} from '../button/button-styles';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.less']
})
export class ModalComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public linkIcon = shLink;
	
	public openedModal = false;

	// Imported Style Variables
	public stylesDefault = modalStylesDefaultTheme;
	public stylesDefaultPrimary = buttonStylesDefaultThemePrimary;
	public stylesDefaultSecondary = buttonStylesDefaultThemeSecondary;
	public stylesDefaultDanger = buttonStylesDefaultThemeDanger;
	public stylesDarkPrimary = buttonStylesDarkThemePrimary;
	public stylesDarkSecondary = buttonStylesDarkThemeSecondary;
	public stylesDarkDanger = buttonStylesDarkThemeDanger;
	public stylesDark = modalStylesDarkTheme;
	public stylesTypography = modalStylesTypography;
	public stylesTypographyButton = modalStylesTypographyButton;
	public stylesStructure = modalStylesStructure;
	public stylesStructureButton = modalStylesStructureButton;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: []
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: []
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started'
				},
				{
					name: 'Code examples',
					value: 'code'
				},
				{
					name: 'API',
					value: 'api'
				}
			]
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: []
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Overlay',
					value: 'show'
				},
				{
					name: 'Actions',
					value: 'actions'
				}
			]
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: true,
			children: []
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general'
				},
				{
					name: 'Content',
					value: 'content'
				},
				{
					name: 'Placement',
					value: 'placement'
				}
			]
		},
		{
			name: 'Resources',
			value: 'resources',
			empty: false,
			children: []
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: []
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: []
		}
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public closeModal(status: string): void {
		console.log(`Dialog result: ${status}`);
		this.openedModal = false;
	}

	public openModal(): void {
		this.openedModal = true;
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url'
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public modalHTML = `
	<kendo-dialog
		*ngIf="openedModal"
		(close)="closeModal('cancel')"
		[minWidth]="250"
		[width]="450"
	>
		<div class="k-dialog-titlebar">
			<div class="k-dialog-title">
				<h2>Discard unsaved changes?</h2>
			</div>
		</div>
		<p style="margin: 30px; text-align: center">You can keep editing or
			discard the changes. Discarded changes cannot be recovered.</p>
		<kendo-dialog-actions [layout]="'end'">
			<button
				kendoButton
				(click)="closeModal('Keep Editing')"
				themeColor="secondary"
			>Keep Editing</button>
			<button
				kendoButton
				(click)="closeModal('Discard Changes')"
				themeColor="primary"
			>Discard Changes</button>
		</kendo-dialog-actions>
	</kendo-dialog>`;
}
