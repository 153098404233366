import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import * as ShieldIcons from '@beyondtrust/shield-icons';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import {
	iconStylesDarkTheme,
	iconStylesDarkThemeStatus,
	iconStylesDefaultTheme,
	iconStylesDefaultThemeStatus,
	iconStylesStructure,
	iconStylesTypography,
} from './icon-styles';
import { ChangelogService } from 'src/app/services/changelog.service';

@Component({
	selector: 'app-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.less'],
})
export class IconComponent implements OnDestroy {
	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public plusIcon = ShieldIcons.shPlus;
	public binoculars = ShieldIcons.shBinoculars;
	public bridge = ShieldIcons.shBridge;
	public report = ShieldIcons.shReport;

	public success = ShieldIcons.shCheck;
	public warning = ShieldIcons.shCircleExclamation;
	public error = ShieldIcons.shCircleSlash;
	public info = ShieldIcons.shCircleInfo;

	public btInsights = ShieldIcons.shBtInsights;
	public btPRA = ShieldIcons.shBtPra;
	public btEPMWinMac = ShieldIcons.shBtPmwm;
	public btEPMLinux = ShieldIcons.shBtPmul;

	public github = ShieldIcons.shGithub;
	public ping = ShieldIcons.shPingIdentity;
	public okta = ShieldIcons.shOkta;
	public linkIcon = ShieldIcons.shLink;

	public iconHTML = `
<kendo-svg-icon
	[icon]="success"
	themeColor="success"
	class="sh-large-icon"
></kendo-svg-icon>`;

	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefault = iconStylesDefaultTheme;
	public stylesDefaultStatus = iconStylesDefaultThemeStatus;
	public stylesDark = iconStylesDarkTheme;
	public stylesDarkStatus = iconStylesDarkThemeStatus;
	public stylesTypography = iconStylesTypography;
	public stylesStructure = iconStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Display only',
					value: 'display-only',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Size and style',
					value: 'size-and-style',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
