import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';

// Kendo imports
import { process } from '@progress/kendo-data-query';
import {
	DataBindingDirective,
	RowArgs,
	SelectAllCheckboxState,
	SelectableSettings,
	SelectionEvent,
} from '@progress/kendo-angular-grid';
import { IconThemeColor } from '@progress/kendo-angular-icons';
import { SVGIcon } from '@progress/kendo-svg-icons';

// Styles imports
import {
	checkboxStylesDefaultTheme,
	checkboxStylesDarkTheme,
} from '../checkbox/checkbox-styles';
import {
	dataGridStylesDefaultTheme,
	dataGridStylesDarkTheme,
	dataGridStylesTypography,
	dataGridStylesStructure,
	dataGridtextLinkStylesDefaultDarkTheme
} from './data-grid-styles';
import {
	paginationStylesDefaultTheme,
	paginationStylesDarkTheme,
} from '../pagination/pagination-styles';
import {
	iconStylesDefaultTheme,
	iconStylesDarkTheme,
} from '../icon/icon-styles';
import { textLinkStylesDarkTheme } from '../text-link/text-link-styles';

// Shield Icons
import * as ShieldIcons from '@beyondtrust/shield-icons';

import { ChangelogService } from 'src/app/services/changelog.service';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { products } from './products';

@Component({
	selector: 'app-data-grid',
	templateUrl: './data-grid.component.html',
	styleUrls: ['./data-grid.component.less'],
})
export class DataGridComponent implements OnInit, OnDestroy {
	@ViewChild('singleGridDataBinding')
	singleGridDataBinding: DataBindingDirective;
	@ViewChild('multiGridDataBinding')
	multiGridDataBinding: DataBindingDirective;
	public currLive = 'single';
	public currTheme = 'default';
	public currVisible = 'overview';
	public gridHeight = "100%";
	public gridData: unknown[] = products;
	public singleGridView: unknown[];
	public multiGridView: unknown[];
	public selectedRows: unknown[] = [];
	public selectAllState: SelectAllCheckboxState = 'unchecked';
	public singleSelectableSettings: SelectableSettings = {
		checkboxOnly: false,
		mode: 'single',
		drag: false,
	};
	public multiSelectableSettings: SelectableSettings = {
		checkboxOnly: false,
		mode: 'multiple',
		drag: false,
	};

	public pageSize = 25;
	public pageSizes = [10, 25, 50, 100, 200];

	// Imported Style Variables
	public stylesDefault = dataGridStylesDefaultTheme;
	public stylesDefaultCheckbox = checkboxStylesDefaultTheme;
	public stylesDefaultIcon = iconStylesDefaultTheme;
	public stylesDefaultPagination = paginationStylesDefaultTheme;
	public stylesDefaultTextLink = dataGridtextLinkStylesDefaultDarkTheme;
	public stylesDark = dataGridStylesDarkTheme;
	public stylesDarkCheckbox = checkboxStylesDarkTheme;
	public stylesDarkIcon = iconStylesDarkTheme;
	public stylesDarkPagination = paginationStylesDarkTheme;
	public stylesDarkTextLink = dataGridtextLinkStylesDefaultDarkTheme;
	public stylesTypography = dataGridStylesTypography;
	public stylesStructure = dataGridStylesStructure;

	public circleSlash = ShieldIcons.shCircleSlash;
	public columnsIcon = ShieldIcons.shColumns;
	public downloadIcon = ShieldIcons.shDownload;
	public filterIcon = ShieldIcons.shFilter;
	public minus = ShieldIcons.shCircleMinus;
	public searchIcon = ShieldIcons.shMagnifyingGlass;
	public settingsIcon = ShieldIcons.shGear;
	public linkIcon = ShieldIcons.shLink;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Empty state',
					value: 'empty',
				},
				{
					name: 'Loading state',
					value: 'loading',
				},
				{
					name: 'Row selection',
					value: 'row-selection',
				},
				{
					name: 'Row actions',
					value: 'row-actions',
				},
				{
					name: 'Bulk actions',
					value: 'bulk-actions',
				},
				{
					name: 'Grid actions',
					value: 'grid-actions',
				},
				{
					name: 'Expand / collapse',
					value: 'expand-collapse',
				},
				{
					name: 'Item and selection counts',
					value: 'item-selection-counts',
				},
				{
					name: 'Sorting',
					value: 'sorting',
				},
				{
					name: 'Column resizing',
					value: 'column-resizing',
				},
				{
					name: 'Column reordering',
					value: 'column-reordering',
				},
				{
					name: 'Hidden columns',
					value: 'hidden-columns',
				},
				{
					name: 'Scrolling',
					value: 'scrolling',
				},
				{
					name: 'Sticky and locked columns',
					value: 'sticky-locked-columns',
				},
				{
					name: 'Pagination',
					value: 'pagination',
				},
				{
					name: 'Disabled',
					value: 'disabled',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Single-select',
					value: 'single-select',
				},
				{
					name: 'Multi-select',
					value: 'multi-select',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Placement',
					value: 'placement',
				},
				{
					name: 'Content order',
					value: 'content-order',
				},
				{
					name: 'Cell alignment',
					value: 'cell-alignment',
				},
				{
					name: 'Data grid or data table',
					value: 'data-grid-vs-data-table',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		public changelogService: ChangelogService,
		private clipboardService: ClipboardService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onFilter(input: Event): void {
		const inputValue = input;
		const newData = process(this.gridData, {
			filter: {
				logic: 'or',
				filters: [
					{
						field: 'ProductName',
						operator: 'contains',
						value: inputValue,
					},
					{
						field: 'UnitsInStock',
						operator: 'contains',
						value: inputValue,
					},
					{
						field: 'UnitsOnOrder',
						operator: 'contains',
						value: inputValue,
					},
					{
						field: 'ReorderLevel',
						operator: 'contains',
						value: inputValue,
					},
				],
			},
		}).data;

		if (this.currLive === 'single') {
			this.singleGridView = newData;
			this.singleGridDataBinding.skip = 0;
		} else {
			this.multiGridView = newData;
			this.multiGridDataBinding.skip = 0;
		}
	}

	public isRowSelected = (row: RowArgs): boolean =>
		this.selectedRows.find(
			(sr) =>
				(sr as any).dataItem.ProductID ===
				(row as any).dataItem.ProductID
		) !== undefined;

	public selectionChange(event: SelectionEvent): void {
		let len;

		if (event.selectedRows.length > 0) {
			let previouslySelectedIndexes = this.selectedRows.map((row) => {
				return (row as any).dataItem.ProductID;
			});

			this.selectedRows = this.selectedRows.concat(
				event.selectedRows.filter(
					(row) =>
						!previouslySelectedIndexes.includes(
							row.dataItem.ProductID
						)
				)
			);
		}

		if (event.deselectedRows.length > 0) {
			let deselectedIndexes = event.deselectedRows.map(
				(row) => row.dataItem.ProductID
			);

			this.selectedRows = this.selectedRows.filter(
				(row) =>
					!deselectedIndexes.includes((row as any).dataItem.ProductID)
			);
		}

		len = this.selectedRows.length;

		if (len === 0) {
			this.selectAllState = 'unchecked';
		} else if (len > 0 && len < this.gridData.length) {
			this.selectAllState = 'indeterminate';
		} else {
			this.selectAllState = 'checked';
		}
	}

	public onSelectAllChange(checkedState: SelectAllCheckboxState): void {
		if (checkedState === 'checked') {
			this.selectedRows = this.multiGridView.map((row) => {
				return { dataItem: row };
			});
			this.selectAllState = 'checked';
		} else {
			this.selectedRows = [];
			this.selectAllState = 'unchecked';
		}
	}

	public severitySet(dataItem: any): string {
		switch (dataItem.Severity) {
			case 'Critical':
				return 'sh-severity-critical';
				break;
			case 'High':
				return 'sh-severity-high';
				break;
			case 'Moderate':
				return 'sh-severity-moderate';
				break;
			case 'Low':
				return 'sh-severity-low';
				break;
			case 'None':
				return 'sh-severity-none';
				break;
			default:
				return 'none';
				break;
		}
	}

	public typeColorSet(dataItem: any): string {
		switch (dataItem.ConnectorType) {
			case 'Microsoft Azure':
				return 'sh-tag-severity--high';
				break;
			case 'Okta':
				return 'sh-tag-severity--low';
				break;
			case 'Insights Collector':
				return 'sh-tag-severity--moderate';
				break;
			default:
				return '';
				break;
		}
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public ngOnInit(): void {
		this.singleGridView = this.gridData;
		this.multiGridView = this.gridData;
	}

	public singleGridHTML: any = `
<kendo-grid
	#singleGridDataBinding
	[class.page-live-show]="currLive === 'single'"
	[kendoGridBinding]="singleGridView"
	[selectable]="singleSelectableSettings"
	[navigable]="true"
	[height]="500"
	[pageSize]="25"
	[pageable]="{ type: 'input', pageSizes: [10, 25, 50] }"
	[sortable]="true"
	[reorderable]="true"
	[resizable]="true"
	[height]="500"
	[scrollable]="'none'">
	<ng-template kendoGridToolbarTemplate>
		<div class="sh-custom-toolbar">
			<div class="sh-toolbar-actions">
				<kendo-textbox
					[style.width.px]="250"
					placeholder="Quick Filter"
					(valueChange)="onFilter($event)">
					<ng-template kendoTextBoxPrefixTemplate>
						<kendo-svg-icon [icon]="searchIcon"></kendo-svg-icon>
					</ng-template>
				</kendo-textbox>
				<kendo-button [svgIcon]="downloadIcon"></kendo-button>
				<kendo-button [svgIcon]="columnsIcon"></kendo-button>
				<kendo-button [svgIcon]="settingsIcon"></kendo-button>
			</div>
			<div class="sh-item-count-container">
				<strong class="sh-item-count">{{ gridData.length }} items</strong>
			</div>
		</div>
	</ng-template>

	<kendo-grid-checkbox-column [width]="45" [showSelectAll]="false"></kendo-grid-checkbox-column>
	<kendo-grid-column field="ProductName" title="Product Name"></kendo-grid-column>
	<kendo-grid-column field="UnitsInStock" title="Units In Stock" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
	<kendo-grid-column field="UnitsOnOrder" title="Units On Order" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
	<kendo-grid-column field="ReorderLevel" title="Reorder Level" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
</kendo-grid>`;

	public multiGridHTML: any = `
<kendo-grid #multiGridDataBinding
	[kendoGridBinding]="multiGridView" [selectable]="multiSelectableSettings" [navigable]="true"
	[height]="500" [pageSize]="pageSize" [pageable]="true" [sortable]="true" [reorderable]="true"
	[resizable]="true" [height]="500" [rowSelected]="isRowSelected"
	(selectionChange)="selectionChange($event)" [scrollable]="'none'">
	<ng-template kendoGridToolbarTemplate>
		<div class="sh-custom-toolbar">
			<div class="sh-toolbar-actions">
				<kendo-textbox [style.width.px]="250" placeholder="Quick Filter"
					[clearButton]="true"
					(valueChange)="onFilter($event)">
					<ng-template kendoTextBoxPrefixTemplate>
						<kendo-svg-icon [icon]="filterIcon"></kendo-svg-icon>
					</ng-template>
				</kendo-textbox>
				<kendo-button [svgIcon]="downloadIcon"></kendo-button>
				<kendo-button [svgIcon]="columnsIcon"></kendo-button>
				<kendo-button [svgIcon]="settingsIcon"></kendo-button>
			</div>
			<div class="sh-item-count-container">
				<strong class="sh-item-count">{{ multiGridView.length }} items</strong>
				{{ '(' }}{{ selectedRows.length }} selected |
				<a class="sh-item-count-selection" tabindex="0" aria-disabled="false"
					aria-haspopup="false" type="button" (click)="
						onSelectAllChange(
							selectAllState === 'checked'
								? 'unchecked'
								: 'checked'
						)
					">{{ selectedRows.length === multiGridView.length
						? 'Deselect All'
						: 'Select all ' + multiGridView.length + ' rows' }}
				</a>{{ ')' }}
			</div>
		</div>
	</ng-template>
	<kendo-grid-checkbox-column [width]="45">
		<ng-template kendoGridHeaderTemplate>
			<input #selectAllCheckbox type="checkbox" kendoCheckBox id="selectAllCheckbox"
				kendoGridSelectAllCheckbox [state]="selectAllState"
				(selectAllChange)="onSelectAllChange($event)" />
			<label class="k-checkbox-label" for="selectAllCheckbox"></label>
		</ng-template>
	</kendo-grid-checkbox-column>
	<kendo-grid-column field="ProductName" title="Product Name"></kendo-grid-column>
	<kendo-grid-column field="UnitsInStock" title="Units In Stock" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
	<kendo-grid-column field="UnitsOnOrder" title="Units On Order" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
	<kendo-grid-column field="ReorderLevel" title="Reorder Level" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
	<ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
		<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
		<kendo-pager-input></kendo-pager-input>
		<kendo-pager-next-buttons></kendo-pager-next-buttons>
		<kendo-pager-info></kendo-pager-info>
		<kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
	</ng-template>
</kendo-grid>`;
}
