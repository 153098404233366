import { AfterViewInit, Component, QueryList, TemplateRef, ViewChild, ViewChildren, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { ChangelogService } from 'src/app/services/changelog.service';
import { CompositeFilterDescriptor, process } from '@progress/kendo-data-query';
import { FilterComponent as KendoFilterComponent, FilterExpression } from '@progress/kendo-angular-filter';
import { DataBindingDirective, SelectableSettings } from '@progress/kendo-angular-grid';
import { shLink } from '@beyondtrust/shield-icons';

import {
	dropDownStylesDarkTheme,
	dropDownStylesDefaultTheme,
	dropDownStylesTypography
} from 'src/app/components/drop-down/drop-down-styles';

import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import {
	dropDownFilterStylesStructureBasic,
	dropDownFilterStylesStructureAdvanced,
	filterStylesStructure,
	textFieldFilterStylesStructureBasic,
	textFieldFilterStylesStructureAdvanced,
	textFieldFilterStylesTypography
} from './filter-styles';

import {
	buttonStylesDefaultThemePrimary,
	buttonStylesDarkThemePrimary,
	buttonStylesDefaultThemeText,
	buttonStylesDarkThemeText,
	buttonStylesStructure,
	buttonStylesTypography
} from 'src/app/components/button/button-styles';

import {
	checkboxStylesDefaultTheme,
	checkboxStylesDarkTheme,
	checkboxStylesStructureAll,
	checkboxStylesTypographyAll
} from 'src/app/components/checkbox/checkbox-styles';

import { textFieldStylesDefaultTheme, textFieldStylesDarkTheme } from 'src/app/components/text-field/text-field-styles';

import { products } from './products';
// Shield Icons
import * as ShieldIcons from '@beyondtrust/shield-icons';

type ItemsArray = Array<{ text: string; value: any }>;

type DropDownListTextItem = {
	text: string;
	value: any;
	filter: string;
	textboxValue?: string;
};

type DropDownListSelectItem = {
	text: string;
	value: any;
	source: ItemsArray;
	data: ItemsArray;
	selectedItems?: ItemsArray;
};

type DropDownListItem = DropDownListTextItem | DropDownListSelectItem;

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.less']
})
export class FilterComponent implements AfterViewInit, OnDestroy {
	@ViewChild('textboxFilter') public textboxFilter: TextBoxComponent;
	@ViewChild('singleGridDataBinding')
	singleGridDataBinding: DataBindingDirective;
	@ViewChild('singleGridDataBinding2')
	singleGridDataBinding2: DataBindingDirective;
	@ViewChild('advFilterFieldTemplate')
	advFilterFieldTemplate: TemplateRef<any>;
	@ViewChild('ddl4')
	ddl4: DropDownListComponent;
	@ViewChild('advFilter')
	advFilter: KendoFilterComponent;
	@ViewChildren('subddl')
	public viewChildren: QueryList<DropDownListComponent>;
	public linkIcon = shLink;
	
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public shClearFilter = ShieldIcons.shClearFilter;
	public gridHeight = "100%";
	// Imported Style Variables
	public stylesStructure = filterStylesStructure;

	public stylesDefaultDropDown = dropDownStylesDefaultTheme;
	public stylesDarkDropDown = dropDownStylesDarkTheme;
	public stylesStructureDropDownBasic = dropDownFilterStylesStructureBasic;
	public stylesStructureDropDownAdvanced = dropDownFilterStylesStructureAdvanced;
	public stylesTypographyDropDown = dropDownStylesTypography;

	public stylesDefaultCheckbox = checkboxStylesDefaultTheme;
	public stylesDarkCheckbox = checkboxStylesDarkTheme;
	public stylesStructureCheckbox = checkboxStylesStructureAll;
	public stylesTypographyCheckbox = checkboxStylesTypographyAll;

	public stylesDefaultTextField = textFieldStylesDefaultTheme;
	public stylesDarkTextField = textFieldStylesDarkTheme;

	// Import truncated Text Field typography and structure styles (filter doesn't use them all)
	public stylesStructureTextFieldBasic = textFieldFilterStylesStructureBasic;
	public stylesStructureTextFieldAdvanced = textFieldFilterStylesStructureAdvanced;
	public stylesTypographyTextField = textFieldFilterStylesTypography;

	public stylesDefaultPrimaryButton = buttonStylesDefaultThemePrimary;
	public stylesDarkPrimaryButton = buttonStylesDarkThemePrimary;
	public stylesDefaultTextButton = buttonStylesDefaultThemeText;
	public stylesDarkTextButton = buttonStylesDarkThemeText;
	public stylesStructureButton = buttonStylesStructure;
	public stylesTypographyButton = buttonStylesTypography;

	public now: Date = new Date();
	public source: Array<DropDownListItem> = [
		{
			text: 'Checkboxes',
			value: 1,
			source: [
				{
					text: 'Drop Down',
					value: 12
				},
				{
					text: 'Example',
					value: 11
				},
				{
					text: 'Values',
					value: 13
				}
			],
			data: []
		},
		{
			text: 'Date',
			value: 3,
			source: [
				{
					text: 'Last Hour',
					value: 31
				},
				{
					text: 'Last 8 Hours',
					value: 32
				},
				{
					text: 'Last 24 Hours',
					value: 33
				},
				{
					text: 'Last 7 Days',
					value: 34
				},
				{
					text: 'Last 30 Days',
					value: 35
				},
				{
					text: 'Last 90 Days',
					value: 36
				}
			],
			data: []
		},
		{
			text: 'Text',
			value: 2,
			filter: '',
			textboxValue: ''
		}
	];
	public source2: Array<DropDownListItem> = [
		{
			text: 'Category',
			value: 4,
			source: [
				{ text: 'Beverages', value: 41 },
				{ text: 'Condiments', value: 42 },
				{ text: 'Confections', value: 43 },
				{ text: 'Dairy Products', value: 44 },
				{ text: 'Grains/Cereals', value: 45 },
				{ text: 'Meat/Poultry', value: 46 },
				{ text: 'Produce', value: 47 },
				{ text: 'Seafood', value: 48 }
			],
			data: []
		},
		{
			text: 'First Ordered On',
			value: 6,
			source: [
				{
					text: 'Last Hour',
					value: this.subtractHours(1, this.now)
				},
				{
					text: 'Last 8 Hours',
					value: this.subtractHours(8, this.now)
				},
				{
					text: 'Last 24 Hours',
					value: this.subtractHours(24, this.now)
				},
				{
					text: 'Last 7 Days',
					value: this.subtractDays(7, this.now)
				},
				{
					text: 'Last 30 Days',
					value: this.subtractDays(30, this.now)
				},
				{
					text: 'Last 90 Days',
					value: this.subtractDays(90, this.now)
				}
			],
			data: []
		},
		{
			text: 'Product Name',
			value: 5,
			filter: '',
			textboxValue: ''
		}
	];
	public source3: Array<DropDownListItem> = [
		{
			text: 'Category',
			value: 7,
			source: [
				{ text: 'Beverages', value: 51 },
				{ text: 'Condiments', value: 52 },
				{ text: 'Confections', value: 53 },
				{ text: 'Dairy Products', value: 54 },
				{ text: 'Grains/Cereals', value: 55 },
				{ text: 'Meat/Poultry', value: 56 },
				{ text: 'Produce', value: 57 },
				{ text: 'Seafood', value: 58 }
			],
			data: []
		},
		{
			text: 'First Ordered On',
			value: 8,
			source: [
				{
					text: 'Today',
					value: this.subtractMinutes(0, this.now).setHours(0, 0, 0, 0)
				},
				{
					text: 'Last 7 Days',
					value: this.subtractDays(7, this.now).setHours(0, 0, 0, 0)
				},
				{
					text: 'Last 30 Days',
					value: this.subtractDays(30, this.now).setHours(0, 0, 0, 0)
				},
				{
					text: 'Last 90 Days',
					value: this.subtractDays(90, this.now).setHours(0, 0, 0, 0)
				}
			],
			data: []
		},
		{
			text: 'Product Name',
			value: 9,
			filter: '',
			textboxValue: ''
		}
	];
	public selectedItems: Array<DropDownListItem> = [];
	public selectedItems2: Array<DropDownListItem> = [];
	public selectedItems3: Array<DropDownListItem> = [];
	public listItems: Array<DropDownListItem>;
	public listItems2: Array<DropDownListItem>;
	public listItems3: Array<DropDownListItem>;

	public gridData: unknown[] = products;
	public singleGridView: unknown[];
	public singleGridView2: unknown[];
	public singleSelectableSettings: SelectableSettings = {
		checkboxOnly: false,
		mode: 'single',
		drag: false
	};

	public filterIcon = ShieldIcons.shFilter;
	public searchIcon = ShieldIcons.shMagnifyingGlass;
	public downloadIcon = ShieldIcons.shDownload;
	public columnsIcon = ShieldIcons.shColumns;
	public settingsIcon = ShieldIcons.shGear;
	public xMark = ShieldIcons.shXmark;
	public showModal = false;

	public activeFilterType: string = 'basic';
	public advFilterValues: CompositeFilterDescriptor = {
		logic: 'and',
		filters: []
	};

	public advFilters: FilterExpression[] = [
		{
			field: 'ProductName',
			title: 'Product Name',
			editor: 'string',
			operators: ['neq', 'eq', 'contains']
		},
		{
			field: 'Category',
			editor: 'string',
			operators: ['neq', 'eq', 'contains']
		},
		{
			field: 'FirstOrderedOn',
			title: 'First Ordered On',
			editor: 'date',
			operators: ['eq', 'gt', 'gte', 'lt', 'lte']
		}
	];

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: []
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: []
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code'
				},
				{
					name: 'API',
					value: 'api'
				}
			]
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: []
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Adding a filter',
					value: 'adding-filter'
				},
				{
					name: 'Applying a filter',
					value: 'applying-filter'
				},
				{
					name: 'Removing a filter',
					value: 'removing-filter'
				},
				{
					name: 'Default',
					value: 'default'
				},
				{
					name: 'Refreshing',
					value: 'refreshing'
				}
			]
		},

		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Basic filter',
					value: 'basic-filter'
				},
				{
					name: 'Advanced filter',
					value: 'advanced-filter'
				}
			]
		},
		{
			name: 'Selection methods',
			value: 'selection-methods',
			empty: false,
			children: [
				{
					name: 'Single-select',
					value: 'single-select'
				},
				{
					name: 'Multi-select',
					value: 'multi-select'
				},
				{
					name: 'Text based',
					value: 'text-based'
				},
				{
					name: 'Optional',
					value: 'optional'
				},
				{
					name: 'Required',
					value: 'required'
				}
			]
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Content',
					value: 'content'
				}
			]
		},
		{
			name: 'Resources',
			value: 'resources',
			empty: false,
			children: []
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: []
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: []
		}
	];

	public filterHTML: any = `
<ng-container *ngFor="let selectedItem of selectedItems">
	<kendo-dropdownlist
		#subddl
		*ngIf="isCheckboxItem(selectedItem)"
		class="sh-filter-dropdown sh-filter-optional"
		textField="text"
		valueField="value"
		[data]="$any(selectedItem).data"
		[filterable]="true"
		[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }"
		(filterChange)="onSubFilterChange($event, selectedItem)"
		(valueChange)="onSubValueChange($event, subddl, selectedItem)"
		(open)="onOpen(subddl)"
		(close)="onClose($event)"
		(blur)="onBlur(subddl)"
		rounded="medium"
	>
		<ng-template kendoDropDownListValueTemplate>
			<b>{{ selectedItem.text }}: </b>
			<ng-container
				*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl"
			>
				<span><ng-container
						*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last"
					>{{
						v.text }}{{ isLast ? "" : ", " }}</ng-container></span>
			</ng-container>
			<ng-template #allTpl>All</ng-template>
			<kendo-svg-icon
				kendoTooltip
				[icon]="xMark"
				(click)="removeFilter(selectedItem.text)"
				title="Remove Filter"
			></kendo-svg-icon>
		</ng-template>
		<ng-template
			kendoDropDownListItemTemplate
			let-dataItem
		>
			<input
				#checkbox
				type="checkbox"
				kendoCheckBox
				[checked]="$any(selectedItem).selectedItems?.includes(dataItem)"
				class="sh-filter-item-checkbox"
			/>
			<kendo-label
				[for]="checkbox"
				[text]="dataItem.text"
				class="k-checkbox-label"
			></kendo-label>
		</ng-template>
		<ng-template kendoDropDownListNoDataTemplate>No results were
			found.</ng-template>
	</kendo-dropdownlist>

	<kendo-dropdownlist
		#subddl
		*ngIf="isTextItem(selectedItem)"
		class="sh-filter-dropdown sh-text-filter sh-filter-optional"
		textField="text"
		valueField="value"
		[data]="[{}]"
		[popupSettings]="{ popupClass: 'sh-filter-popup sh-text-filter-popup', width: 'auto' }"
		(open)="onOpen(subddl); $any(selectedItem).textboxValue = $any(selectedItem).filter"
		(close)="onClose($event)"
		rounded="medium"
	>
		<ng-template kendoDropDownListValueTemplate>
			<b>{{ selectedItem.text }}: </b> <span>{{
				$any(selectedItem).filter || "All" }}</span>
			<kendo-svg-icon
				kendoTooltip
				[icon]="xMark"
				(click)="removeFilter(selectedItem.text)"
				title="Remove Filter"
			></kendo-svg-icon>
		</ng-template>
		<ng-template
			kendoDropDownListItemTemplate
			let-dataItem
		>
			<kendo-textbox
				#textboxFilter
				[(value)]="$any(selectedItem).textboxValue"
				[clearButton]="true"
				fillMode="outline"
				(keydown)="$any($event).key === 'Enter' ? updateFilter(selectedItem, subddl) : true"
				(blur)="updateFilter(selectedItem, subddl)"
			>
			</kendo-textbox>
		</ng-template>
		<ng-template kendoDropDownListFooterTemplate>
			<div class="sh-filter-dropdown-buttons">
				<kendo-button
					themeColor="primary"
					(click)="updateFilter(selectedItem, subddl)"
				>Update</kendo-button>
				<kendo-button
					themeColor="primary"
					(click)="onBlur(subddl)"
				>Close</kendo-button>
			</div>
		</ng-template>
		<ng-template kendoDropDownListNoDataTemplate></ng-template>
	</kendo-dropdownlist>

	<kendo-dropdownlist
		#subddl
		*ngIf="isDateItem(selectedItem)"
		class="sh-filter-dropdown sh-filter-optional"
		textField="text"
		valueField="value"
		[data]="$any(selectedItem).data"
		[filterable]="true"
		[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }"
		(filterChange)="onSubFilterChange($event, selectedItem)"
		(valueChange)="onSubValueChange($event, subddl, selectedItem)"
		(open)="onOpen(subddl)"
		(close)="onClose($event)"
		(blur)="onBlur(subddl)"
		rounded="medium"
	>
		<ng-template kendoDropDownListValueTemplate>
			<b>{{ selectedItem.text }}: </b>
			<ng-container
				*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl"
			>
				<span><ng-container
						*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last"
					>{{
						v.text }}{{ isLast ? "" : ", " }}</ng-container></span>
			</ng-container>
			<ng-template #allTpl>All</ng-template>
			<kendo-svg-icon
				kendoTooltip
				[icon]="xMark"
				(click)="removeFilter(selectedItem.text)"
				title="Remove Filter"
			></kendo-svg-icon>
		</ng-template>
		<ng-template kendoDropDownListNoDataTemplate>No results were
			found.</ng-template>
	</kendo-dropdownlist>
</ng-container>

<div class="sh-filter-buttons-container">
	<kendo-dropdownlist
		#ddl
		class="sh-filter-button"
		textField="text"
		valueField="value"
		[data]="listItems"
		[filterable]="true"
		[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }"
		(open)="onOpen(ddl)"
		(close)="onClose($event)"
		(blur)="onBlur(ddl)"
		(filterChange)="onFilterChange($event)"
		(valueChange)="onValueChange($event, ddl)"
	>
		<ng-template kendoDropDownListValueTemplate>
			<kendo-button
				themeColor="primary"
				[svgIcon]="filterIcon"
			>Add Filters</kendo-button>
		</ng-template>
		<ng-template
			kendoDropDownListItemTemplate
			let-dataItem
		>
			<input
				#checkbox
				type="checkbox"
				kendoCheckBox
				[checked]="selectedItems.includes(dataItem)"
				class="sh-filter-item-checkbox"
			/>
			<kendo-label
				[for]="checkbox"
				[text]="dataItem.text"
				class="k-checkbox-label"
			></kendo-label>
		</ng-template>
		<ng-template kendoDropDownListNoDataTemplate>No results were
			found.</ng-template>
	</kendo-dropdownlist>

	<kendo-button
		themeColor="primary"
		fillMode="flat"
		class="sh-filter-reset-button"
		*ngIf="selectedItems.length > 0"
		(click)="resetFilters()"
	>Reset Filters</kendo-button>
</div>`;

	public filterWithGridHTML: any = `
<ng-container *ngFor="let selectedItem of selectedItems2">
	<kendo-dropdownlist
		#subddl
		*ngIf="selectedItem.text === 'Category'"
		class="sh-filter-dropdown sh-filter-optional"
		textField="text"
		valueField="value"
		[data]="$any(selectedItem).data"
		[filterable]="true"
		[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }"
		(filterChange)="onSubFilterChange($event, selectedItem)"
		(valueChange)="onSubValueChange($event, subddl, selectedItem)"
		(open)="onOpen(subddl)"
		(close)="onClose($event)"
		(blur)="onBlur(subddl)"
		rounded="medium"
	>
		<ng-template kendoDropDownListValueTemplate>
			<b>{{ selectedItem.text }}: </b>
			<ng-container
				*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl"
			>
				<span><ng-container
						*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last"
					>{{
						v.text }}{{ isLast ? "" : ", " }}</ng-container></span>
			</ng-container>
			<ng-template #allTpl>All</ng-template>
			<kendo-svg-icon
				kendoTooltip
				[icon]="xMark"
				(click)="removeFilter(selectedItem.text)"
				title="Remove Filter"
			></kendo-svg-icon>
		</ng-template>
		<ng-template
			kendoDropDownListItemTemplate
			let-dataItem
		>
			<input
				#checkbox
				type="checkbox"
				kendoCheckBox
				[checked]="$any(selectedItem).selectedItems?.includes(dataItem)"
				class="sh-filter-item-checkbox"
			/>
			<kendo-label
				[for]="checkbox"
				[text]="dataItem.text"
				class="k-checkbox-label"
			></kendo-label>
		</ng-template>
		<ng-template kendoDropDownListNoDataTemplate>No results were
			found.</ng-template>
	</kendo-dropdownlist>

	<kendo-dropdownlist
		#subddl
		*ngIf="selectedItem.text === 'Product Name'"
		class="sh-filter-dropdown sh-text-filter sh-filter-optional"
		textField="text"
		valueField="value"
		[data]="[{}]"
		[popupSettings]="{ popupClass: 'sh-filter-popup sh-text-filter-popup', width: 'auto' }"
		(open)="onOpen(subddl);$any(selectedItem).textboxValue = $any(selectedItem).filter"
		(close)="onClose($event)"
		rounded="medium"
	>
		<ng-template kendoDropDownListValueTemplate>
			<b>{{ selectedItem.text }}: </b> <span>{{
				$any(selectedItem).filter || "All" }}</span>
			<kendo-svg-icon
				kendoTooltip
				[icon]="xMark"
				(click)="removeFilter(selectedItem.text)"
				title="Remove Filter"
			></kendo-svg-icon>
		</ng-template>
		<ng-template
			kendoDropDownListItemTemplate
			let-dataItem
		>
			<kendo-textbox
				#textboxFilter
				[(value)]="$any(selectedItem).textboxValue"
				[clearButton]="true"
				fillMode="outline"
				(keydown)="$any($event).key === 'Enter' ? updateFilter(selectedItem, subddl) : true"
				(blur)="updateFilter(selectedItem, subddl)"
			> </kendo-textbox>
		</ng-template>
		<ng-template kendoDropDownListFooterTemplate>
			<div class="sh-filter-dropdown-buttons">
				<kendo-button
					(click)="updateFilter(selectedItem, subddl)">Update</kendo-button>
				<kendo-button (click)="onBlur(subddl)">Close</kendo-button>
			</div>
		</ng-template>
		<ng-template kendoDropDownListNoDataTemplate></ng-template>
	</kendo-dropdownlist>

	<kendo-dropdownlist
		#subddl
		*ngIf="selectedItem.text === 'First Ordered On'"
		class="sh-filter-dropdown  sh-filter-optional"
		textField="text"
		valueField="value"
		[data]="$any(selectedItem).data"
		[filterable]="true"
		[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }"
		(filterChange)="onSubFilterChange($event, selectedItem)"
		(valueChange)="onSubValueChange($event, subddl, selectedItem)"
		(open)="onOpen(subddl)"
		(close)="onClose($event)"
		(blur)="onBlur(subddl)"
		rounded="medium"
	>
		<ng-template kendoDropDownListValueTemplate>
			<b>{{ selectedItem.text }}: </b>
			<ng-container
				*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl"
			>
				<span><ng-container
						*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last"
					>{{
						v.text }}{{ isLast ? "" : ", " }}</ng-container></span>
			</ng-container>
			<ng-template #allTpl>All</ng-template>
			<kendo-svg-icon
				kendoTooltip
				[icon]="xMark"
				(click)="removeFilter(selectedItem.text)"
				title="Remove Filter"
			></kendo-svg-icon>
		</ng-template>
		<ng-template kendoDropDownListNoDataTemplate>No results were
			found.</ng-template>
	</kendo-dropdownlist>
</ng-container>

<div class="sh-filter-buttons-container">
	<kendo-dropdownlist
		#ddl2
		class="sh-filter-button"
		textField="text"
		valueField="value"
		[data]="listItems2"
		[filterable]="true"
		[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }"
		(open)="onOpen(ddl2)"
		(close)="onClose($event)"
		(blur)="onBlur(ddl2)"
		(filterChange)="onFilterChange($event)"
		(valueChange)="onValueChange($event, ddl2)"
	>
		<ng-template kendoDropDownListValueTemplate>
			<kendo-button [svgIcon]="filterIcon">Add
				Filters</kendo-button>
		</ng-template>
		<ng-template
			kendoDropDownListItemTemplate
			let-dataItem
		>
			<input
				#checkbox
				type="checkbox"
				kendoCheckBox
				[checked]="selectedItems2.includes(dataItem)"
				class="sh-filter-item-checkbox"
			/>
			<kendo-label
				[for]="checkbox"
				[text]="dataItem.text"
				class="k-checkbox-label"
			></kendo-label>
		</ng-template>
		<ng-template kendoDropDownListNoDataTemplate>No results were
			found.</ng-template>
	</kendo-dropdownlist>

	<kendo-button
		themeColor="primary"
		fillMode="flat"
		class="sh-filter-reset-button"
		*ngIf="selectedItems2.length > 0"
		(click)="resetFilters()"
	>Reset Filters</kendo-button>
</div>

<kendo-grid
	#singleGridDataBinding
	[style.margin-top.px]="15"
	[kendoGridBinding]="singleGridView"
	[selectable]="singleSelectableSettings"
	[navigable]="true"
	[height]="500"
	[pageSize]="25"
	[pageable]="$any(singleGridView).length === 0
										? false
										: {
											type: 'input',
											pageSizes: [10, 25, 50]
										}"
	[sortable]="true"
	[reorderable]="true"
	[resizable]="true"
	[height]="500"
>
	<ng-template kendoGridToolbarTemplate>
		<div class="sh-custom-toolbar">
			<div class="sh-toolbar-actions">
				<kendo-textbox
					[style.width.px]="0"
					[style.border-width]="0"
					placeholder="Quick Filter"
					[clearButton]="true"
				>
					<ng-template kendoTextBoxPrefixTemplate>
						<kendo-svg-icon [icon]="searchIcon"></kendo-svg-icon>
					</ng-template>
				</kendo-textbox>
				<kendo-button [svgIcon]="downloadIcon"></kendo-button>
				<kendo-button [svgIcon]="columnsIcon"></kendo-button>
				<kendo-button [svgIcon]="settingsIcon"></kendo-button>
			</div>
			<div class="sh-item-count-container">
				<strong class="sh-item-count">{{
					singleGridView.length }} items</strong>
			</div>
		</div>
	</ng-template>

	<kendo-grid-checkbox-column
		[width]="45"
		[showSelectAll]="false"
	></kendo-grid-checkbox-column>
	<kendo-grid-column
		field="ProductName"
		title="Product Name"
	></kendo-grid-column>
	<kendo-grid-column
		field="Category"
		title="Category"
	></kendo-grid-column>
	<kendo-grid-column
		field="FirstOrderedOn"
		title="First Ordered On"
	><ng-template
			kendoGridCellTemplate
			let-dataItem
		>
			{{dataItem.FirstOrderedOn | date: 'MM/dd/yyyy hh:mm a'}}
		</ng-template></kendo-grid-column>
</kendo-grid>`;

	public advancedFilterWithGridHTML = `
<div class="sh-filter-toolbar">
	<div *ngIf="activeFilterType === 'basic'">
		<ng-container *ngFor="let selectedItem of selectedItems3">
			<kendo-dropdownlist
				#subddl
				*ngIf="selectedItem.text === 'Category'"
				class="sh-filter-dropdown sh-filter-optional"
				textField="text"
				valueField="value"
				[data]="$any(selectedItem).data"
				[filterable]="true"
				[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }"
				(filterChange)="onSubFilterChange($event, selectedItem)"
				(valueChange)="onSubValueChange($event, subddl, selectedItem)"
				(open)="onOpen(subddl)"
				(close)="onClose($event)"
				(blur)="onBlur(subddl)"
				rounded="medium"
			>
				<ng-template kendoDropDownListValueTemplate>
					<b>{{ selectedItem.text }}: </b>
					<ng-container
						*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl"
					>
						<span><ng-container
								*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last"
							>{{
								v.text }}{{ isLast ? "" : ", "
								}}</ng-container></span>
					</ng-container>
					<ng-template #allTpl>All</ng-template>
					<kendo-svg-icon
						kendoTooltip
						[icon]="xMark"
						(click)="removeFilter(selectedItem.text)"
						title="Remove Filter"
					></kendo-svg-icon>
				</ng-template>
				<ng-template
					kendoDropDownListItemTemplate
					let-dataItem
				>
					<input
						#checkbox
						type="checkbox"
						kendoCheckBox
						[checked]="$any(selectedItem).selectedItems?.includes(dataItem)"
						class="sh-filter-item-checkbox"
					/>
					<kendo-label
						[for]="checkbox"
						[text]="dataItem.text"
						class="k-checkbox-label"
						style="pointer-events: none;"
					></kendo-label>
				</ng-template>
				<ng-template kendoDropDownListNoDataTemplate>No
					results were found.</ng-template>
			</kendo-dropdownlist>

			<kendo-dropdownlist
				#subddl
				*ngIf="selectedItem.text === 'Product Name'"
				class="sh-filter-dropdown sh-text-filter sh-filter-optional"
				textField="text"
				valueField="value"
				[data]="[{}]"
				[popupSettings]="{ popupClass: 'sh-filter-popup sh-text-filter-popup', width: 'auto' }"
				(open)="onOpen(subddl);$any(selectedItem).textboxValue = $any(selectedItem).filter"
				(close)="onClose($event)"
				rounded="medium"
			>
				<ng-template kendoDropDownListValueTemplate>
					<b>{{ selectedItem.text }}: </b> <span>{{
						$any(selectedItem).filter || "All" }}</span>
					<kendo-svg-icon
						kendoTooltip
						[icon]="xMark"
						(click)="removeFilter(selectedItem.text)"
						title="Remove Filter"
					></kendo-svg-icon>
				</ng-template>
				<ng-template
					kendoDropDownListItemTemplate
					let-dataItem
				>
					<kendo-textbox
						#textboxFilter
						[(value)]="$any(selectedItem).textboxValue"
						[clearButton]="true"
						fillMode="outline"
						(keydown)="$any($event).key === 'Enter' ? updateFilter(selectedItem, subddl) : true"
						(blur)="updateFilter(selectedItem, subddl)"
					> </kendo-textbox>
				</ng-template>
				<ng-template kendoDropDownListFooterTemplate>
					<div class="sh-filter-dropdown-buttons">
						<kendo-button
							(click)="updateFilter(selectedItem, subddl)"
						>Update</kendo-button>
						<kendo-button
							themeColor="secondary"
							(click)="onBlur(subddl)"
						>Close</kendo-button>
					</div>
				</ng-template>
				<ng-template kendoDropDownListNoDataTemplate></ng-template>
			</kendo-dropdownlist>

			<kendo-dropdownlist
				#subddl
				*ngIf="selectedItem.text === 'First Ordered On'"
				class="sh-filter-dropdown  sh-filter-optional"
				textField="text"
				valueField="value"
				[data]="$any(selectedItem).data"
				[filterable]="true"
				[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }"
				(filterChange)="onSubFilterChange($event, selectedItem)"
				(valueChange)="onSubValueChange($event, subddl, selectedItem)"
				(open)="onOpen(subddl)"
				(close)="onClose($event)"
				(blur)="onBlur(subddl)"
				rounded="medium"
			>
				<ng-template kendoDropDownListValueTemplate>
					<b>{{ selectedItem.text }}: </b>
					<ng-container
						*ngIf="$any(selectedItem).selectedItems && $any(selectedItem).selectedItems.length > 0; else allTpl"
					>
						<span><ng-container
								*ngFor="let v of $any(selectedItem).selectedItems; let isLast = last"
							>{{
								v.text }}{{ isLast ? "" : ", "
								}}</ng-container></span>
					</ng-container>
					<ng-template #allTpl>All</ng-template>
					<kendo-svg-icon
						kendoTooltip
						[icon]="xMark"
						(click)="removeFilter(selectedItem.text)"
						title="Remove Filter"
					></kendo-svg-icon>
				</ng-template>
				<ng-template kendoDropDownListNoDataTemplate>No
					results were found.</ng-template>
			</kendo-dropdownlist>
		</ng-container>
		<div class="sh-filter-buttons-container">
			<kendo-dropdownlist
				#ddl3
				class="sh-filter-button"
				textField="text"
				valueField="value"
				[data]="listItems3"
				[filterable]="true"
				[popupSettings]="{ popupClass: 'sh-filter-popup', width: 'auto' }"
				(open)="onOpen(ddl3)"
				(close)="onClose($event)"
				(blur)="onBlur(ddl3)"
				(filterChange)="onFilterChange($event)"
				(valueChange)="onValueChange($event, ddl3)"
			>
				<ng-template kendoDropDownListValueTemplate>
					<kendo-button [svgIcon]="filterIcon">Add
						Filters</kendo-button>
				</ng-template>
				<ng-template
					kendoDropDownListItemTemplate
					let-dataItem
				>
					<input
						#checkbox
						type="checkbox"
						kendoCheckBox
						[checked]="selectedItems3.includes(dataItem)"
						class="sh-filter-item-checkbox"
					/>
					<kendo-label
						[for]="checkbox"
						[text]="dataItem.text"
						class="k-checkbox-label"
						style="pointer-events: none;"
					></kendo-label>
				</ng-template>
				<ng-template kendoDropDownListNoDataTemplate>No
					results were found.</ng-template>
			</kendo-dropdownlist>

			<kendo-button
				themeColor="primary"
				fillMode="flat"
				class="sh-filter-reset-button"
				*ngIf="selectedItems3.length > 0"
				(click)="resetFilters()"
			>Reset Filters</kendo-button>
		</div>
	</div>

	<!--This has to be placed outside the kendo-filter below since it lives in a popup and the popup is rendered outside scope of this component-->
	<ng-template
		let-currentItem
		#advFilterFieldTemplate
	>
		<kendo-dropdownlist
			[data]="['Beverages',
														'Condiments',
														'Confections',
														'Dairy Products',
														'Grains/Cereals',
														'Meat/Poultry',
														'Produce',
														'Seafood']"
			[value]="currentItem.value"
			(valueChange)="editorValueChange(currentItem, $event)"
		>
		</kendo-dropdownlist>
	</ng-template>
	<ng-container *ngIf="activeFilterType === 'advanced'">
		<div class="sh-filter-buttons-container">
			<kendo-dropdownlist
				#ddl4
				class="sh-filter-button"
				[data]="[]"
				[popupSettings]="{popupClass: 'sh-advanced-filter-popup'}"
				(close)="onClose($event)"
			>
				<ng-template kendoDropDownListValueTemplate>
					<kendo-button [svgIcon]="filterIcon">Add
						Filters</kendo-button>
				</ng-template>
				<ng-template kendoDropDownListNoDataTemplate>
					<button
						kendoTooltip
						kendoButton
						style="float: right;"
						title="Close"
						[svgIcon]="xMark"
						(click)="onBlur(ddl4)"
					></button>

					<kendo-filter
						#advFilter
						class="sh-filter"
						[filters]="advFilters"
						[value]="advFilterValues"
					>
					</kendo-filter>
					<kendo-button (click)="applyAdvFilters(ddl4)">Apply
						Changes</kendo-button>
					<kendo-button
						themeColor="primary"
						fillMode="flat"
						(click)="onBlur(ddl4)"
					>Discard Changes</kendo-button>
				</ng-template>
			</kendo-dropdownlist>

			<kendo-button
				themeColor="primary"
				fillMode="flat"
				class="sh-filter-reset-button"
				*ngIf="advFilterValues.filters.length > 0"
				(click)="resetFilters()"
			>Reset Filters</kendo-button>
		</div>
	</ng-container>

	<kendo-button
		themeColor="primary"
		fillMode="flat"
		class="sh-filter-switch-button"
		(click)="switchFilters()"
	>Switch to {{ activeFilterType === 'basic' ? 'Advanced' : 'Basic' }}</kendo-button>
	<kendo-dialog title="Confirm switch filter type" *ngIf="showModal" (close)="closeModal()">
		<p>Warning: you will lose all applied filters if switching to {{activeFilterType
			=== 'basic' ? 'Advanced' : 'Basic' }}. Are you sure you want to continue?
		</p>
		<kendo-dialog-actions>
			<button
				kendoButton
				(click)="switchFilters()"
				themeColor="primary"
			>Yes</button>
			<button
				kendoButton
				(click)="closeModal()"
				themeColor="secondary"
			>No</button>
		</kendo-dialog-actions>
	</kendo-dialog>
</div>

<kendo-grid
	#singleGridDataBinding2
	[style.margin-top.px]="15"
	[kendoGridBinding]="singleGridView2"
	[selectable]="singleSelectableSettings"
	[navigable]="true"
	[height]="500"
	[pageSize]="25"
	[pageable]="$any(singleGridView2).length === 0
															? false
															: {
																type: 'input',
																pageSizes: [10, 25, 50]
															}"
	[sortable]="true"
	[reorderable]="true"
	[resizable]="true"
	[height]="500"
>
	<ng-template kendoGridToolbarTemplate>
		<div class="sh-custom-toolbar">
			<div class="sh-toolbar-actions">
				<kendo-textbox
					[style.width.px]="0"
					[style.border-width]="0"
					placeholder="Quick Filter"
					[clearButton]="true"
				>
					<ng-template kendoTextBoxPrefixTemplate>
						<kendo-svg-icon [icon]="searchIcon"></kendo-svg-icon>
					</ng-template>
				</kendo-textbox>
				<kendo-button [svgIcon]="downloadIcon"></kendo-button>
				<kendo-button [svgIcon]="columnsIcon"></kendo-button>
				<kendo-button [svgIcon]="settingsIcon"></kendo-button>
			</div>
			<div class="sh-item-count-container">
				<strong class="sh-item-count">{{
					singleGridView2.length }} items</strong>
			</div>
		</div>
	</ng-template>

	<kendo-grid-checkbox-column
		[width]="45"
		[showSelectAll]="false"
	></kendo-grid-checkbox-column>
	<kendo-grid-column
		field="ProductName"
		title="Product Name"
	></kendo-grid-column>
	<kendo-grid-column
		field="Category"
		title="Category"
	></kendo-grid-column>
	<kendo-grid-column
		field="FirstOrderedOn"
		title="First Ordered On"
	><ng-template
			kendoGridCellTemplate
			let-dataItem
		>
			{{dataItem.FirstOrderedOn | date:
			'MM/dd/yyyy'}}
		</ng-template></kendo-grid-column>
</kendo-grid>`;

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
		this.source.forEach(value => {
			if ('data' in value) {
				value.data = value.source.slice();
			}
		});
		this.source2.forEach(value => {
			if ('data' in value) {
				value.data = value.source.slice();
			}
		});
		this.source3.forEach(value => {
			if ('data' in value) {
				value.data = value.source.slice();
			}
		});
		this.listItems = this.source.slice();
		this.listItems2 = this.source2.slice();
		this.listItems3 = this.source3.slice();
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
		this.setDates();
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url'
		});
	}
	
	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public get hasFilters() {
		return Object.keys(this.currentSelection).length > 0;
	}

	public get currentSelection() {
		const selectedItems =
			this.currLive === 'default'
				? this.selectedItems
				: this.currLive === 'filter-with-grid'
				? this.selectedItems2
				: this.selectedItems3;

		return selectedItems.reduce((acc, item) => {
			acc[item.text] =
				('data' in item ? (item.selectedItems?.length > 0 ? item.selectedItems : 'All') : item.filter) || 'All';
			return acc;
		}, {});
	}

	public isCheckboxItem(item: DropDownListItem): boolean {
		return item.text === 'Checkboxes';
	}

	public isTextItem(item: DropDownListItem): boolean {
		return item.text === 'Text';
	}

	public isDateItem(item: DropDownListItem): boolean {
		return item.text === 'Date';
	}

	public onFilterChange(value: string) {
		if (this.currLive === 'default')
			this.listItems = this.source.filter(item => item.text.toLowerCase().indexOf(value) !== -1);
		else if (this.currLive === 'filter-with-grid')
			this.listItems2 = this.source2.filter(item => item.text.toLowerCase().indexOf(value) !== -1);
		else this.listItems3 = this.source3.filter(item => item.text.toLowerCase().indexOf(value) !== -1);
	}

	public onValueChange(value: any, ddl: DropDownListComponent) {
		const selectedItems =
			this.currLive === 'default'
				? this.selectedItems
				: this.currLive === 'filter-with-grid'
				? this.selectedItems2
				: this.selectedItems3;

		if (selectedItems.includes(value)) {
			let removedItem = selectedItems.splice(selectedItems.indexOf(value), 1);

			//item was removed
			if (!removedItem || removedItem.length === 1) {
				let oldWidth: number = 0;
				let newWidth = 0;
				let oldMarginLeft = ddl.popupRef ? ddl.popupRef.popupElement.style.marginLeft : null;
				let newMarginLeft;

				this.viewChildren.toArray().forEach(child => {
					oldWidth += Number(child.width.max.replace('px', ''));
				});
				if ('data' in removedItem[0]) {
					removedItem[0].selectedItems = [];
				} else {
					removedItem[0].filter = '';
				}
				ddl.popupRef.popupPositionChange.subscribe(() => {
					this.viewChildren.toArray().forEach(child => {
						newWidth += Number(child.width.max.replace('px', ''));
					});
					if (oldMarginLeft) {
						newMarginLeft =
							Number(Number(oldWidth - newWidth) + Number(oldMarginLeft.replace('px', ''))) + 'px';
					} else {
						newMarginLeft = oldWidth - newWidth + 'px';
					}
					ddl.popupRef.popupElement.style.marginLeft = newMarginLeft;
					ddl.focus();
				});
			}
		} else {
			this.currLive === 'default'
				? this.selectedItems.push(value)
				: this.currLive === 'filter-with-grid'
				? this.selectedItems2.push(value)
				: this.selectedItems3.push(value);
			ddl.toggle(false);
		}

		ddl.reset();
	}

	public onSubFilterChange(value: string, item: DropDownListItem) {
		if ('data' in item) {
			item.data = item.source.filter(val => val.text.toLowerCase().indexOf(value) !== -1);
		} else {
			item.filter = value;
		}
	}

	public updateFilter(item: DropDownListItem, ddl: DropDownListComponent) {
		if ('filter' in item) item.filter = item.textboxValue;
		this.onBlur(ddl);
		this.applyBasicFilters();
	}

	public onSubValueChange(value: any, subddl: DropDownListComponent, item: DropDownListItem) {
		if ('data' in item) {
			if (item.text === 'Checkboxes' || item.text === 'Category') {
				if (!item.selectedItems) item.selectedItems = [];
				if (item.selectedItems.includes(value)) {
					item.selectedItems.splice(item.selectedItems.indexOf(value), 1);
				} else {
					item.selectedItems.push(value);
				}
				subddl.reset();
				subddl.focus();
			} else if (item.text === 'Date' || item.text === 'First Ordered On') {
				item.selectedItems = [value];
				subddl.focus();
				subddl.blur(); //close drop down after item selection
			}
		}

		this.applyBasicFilters();
	}

	public onOpen(ddl: DropDownListComponent) {
		this.viewChildren.toArray().forEach(child => {
			if (child !== ddl) child.toggle(false);
		});
	}

	public onClose(event: any) {
		event.preventDefault();
	}

	public onBlur(ddl: DropDownListComponent) {
		const timeout = setTimeout(() => {
			ddl.toggle(false);
		}, 200);
		const checkboxes = ddl.popupRef.popupElement.getElementsByClassName('sh-filter-item-checkbox');

		//checkbox clicks in drop down list trigger blur
		//this prevents that
		Array.from(checkboxes).forEach((checkbox: HTMLInputElement) => {
			checkbox.onclick = () => {
				clearTimeout(timeout);
			};
		});
	}

	public removeFilter(filterName: string) {
		if (this.currLive === 'default') {
			this.selectedItems = this.selectedItems.filter(item => {
				const removeItem = item.text === filterName;

				if (removeItem) this.resetFilter(item);

				return !removeItem;
			});
		} else if (this.currLive === 'filter-with-grid') {
			this.selectedItems2 = this.selectedItems2.filter(item => {
				const removeItem = item.text === filterName;

				if (removeItem) this.resetFilter(item);

				return !removeItem;
			});
		} else if (this.currLive === 'advanced-filter') {
			this.selectedItems3 = this.selectedItems3.filter(item => {
				const removeItem = item.text === filterName;

				if (removeItem) this.resetFilter(item);

				return !removeItem;
			});
		}
		this.applyBasicFilters();
	}

	public resetFilters() {
		this.selectedItems.forEach(item => {
			this.resetFilter(item);
		});
		this.selectedItems2.forEach(item => {
			this.resetFilter(item);
		});
		this.selectedItems3.forEach(item => {
			this.resetFilter(item);
		});
		this.selectedItems = [];
		this.selectedItems2 = [];
		this.selectedItems3 = [];
		this.applyBasicFilters();
		if (this.currLive === 'advanced-filter') {
			this.resetAdvancedFilter();
		}
	}

	public resetFilter(item) {
		if ('data' in item) {
			item.selectedItems = [];
		} else {
			item.filter = '';
			item.textboxValue = '';
		}
	}

	public resetAdvancedFilter(): void {
		this.advFilterValues.filters = [];
	}

	public applyBasicFilters(): void {
		const productNameFilter: any = (this.currLive === 'filter-with-grid'
			? this.selectedItems2
			: this.selectedItems3
		).find(item => item.text === 'Product Name') || { filter: '' };
		const categoryFilter: any = (
			this.currLive === 'filter-with-grid' ? this.selectedItems2 : this.selectedItems3
		).find(item => item.text === 'Category');
		const firstOrderedOnFilter: any = (this.currLive === 'filter-with-grid'
			? this.selectedItems2
			: this.selectedItems3
		).find(item => item.text === 'First Ordered On') || {
			selectedItems: [{ value: '' }]
		};
		let newData;

		if (!firstOrderedOnFilter.selectedItems || !firstOrderedOnFilter.selectedItems.length) {
			firstOrderedOnFilter.selectedItems = [{ value: '' }];
		}

		newData = process(this.gridData, {
			filter: {
				logic: 'and',
				filters: [
					{
						field: 'ProductName',
						operator: 'contains',
						value: productNameFilter.filter
					},
					{
						field: 'Category',
						operator: val => {
							return (
								!categoryFilter ||
								!categoryFilter.selectedItems.length ||
								categoryFilter.selectedItems.map(i => i.text).includes(val)
							);
						}
					},
					{
						field: 'FirstOrderedOn',
						operator: 'gte',
						value: firstOrderedOnFilter.selectedItems[0].value
					}
				]
			}
		}).data;

		this.singleGridView = newData;
		this.singleGridDataBinding.skip = 0;
		this.singleGridView2 = newData;
		this.singleGridDataBinding2.skip = 0;
	}

	public applyAdvFilters(ddl: DropDownListComponent = null): void {
		this.advFilterValues = this.advFilter.value;
		this.singleGridView2 = process(this.gridData, {
			filter: this.advFilterValues
		}).data;

		//alert(this.advFilter.value.filters.length);

		if (ddl) {
			ddl.toggle(false);
		}
	}

	public editorValueChange(currentItem: any, value: any): void {
		currentItem.value = value;
	}

	public switchFilters() {
		if (this.activeFilterType === 'basic') {
			this.activeFilterType = 'advanced';
		} else {
			this.activeFilterType = 'basic';
		}
		this.resetFilters();
		this.closeModal();
	}

	public closeModal(): void {
		this.showModal = false;
	}

	public subtractMinutes(minutes: number, date: Date) {
		return new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			date.getHours(),
			date.getMinutes() - minutes
		);
	}

	public subtractHours(hours: number, date: Date) {
		return new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			date.getHours() - hours,
			date.getMinutes()
		);
	}

	public subtractDays(days: number, date: Date) {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate() - days, date.getHours(), date.getMinutes());
	}

	public setDates(): void {
		let i = 0;

		this.gridData.forEach(row => {
			if (typeof row['Category'] === 'object') row['Category'] = row['Category'].CategoryName;

			//last hour (3 records)
			if (i < 3) row['FirstOrderedOn'] = this.subtractMinutes(5 * i, this.now);
			//last 8 hours (5 records)
			else if (i < 8) row['FirstOrderedOn'] = this.subtractHours(i - 2, this.now);
			//last 24 hours (10 records)
			else if (i < 18) row['FirstOrderedOn'] = this.subtractHours(i + 1, this.now);
			//last 7 days (14 records)
			else if (i < 32) row['FirstOrderedOn'] = this.subtractHours(25 + (i - 18) * 10, this.now);
			//last 30 days (15 records)
			else if (i < 47) row['FirstOrderedOn'] = this.subtractDays(8 + (i - 32), this.now);
			//last 90 days (25 records)
			else if (i < 72) row['FirstOrderedOn'] = this.subtractDays(31 + (i - 47), this.now);
			//beyond 90 days (5 records)
			else row['FirstOrderedOn'] = this.subtractDays(91 + (i - 72), this.now);

			//advanced filter can't handle times by default
			if (this.currLive === 'advanced-filter') {
				row['FirstOrderedOn'].setHours(0, 0, 0, 0);
			}
			i++;
		});

		this.singleGridView = this.gridData;
		this.singleGridView2 = this.gridData;
	}

	public ngOnInit(): void {
		this.setDates();
	}

	public ngAfterViewInit(): void {
		let lastLength = 0;
		this.viewChildren.changes.subscribe((viewChildren: QueryList<DropDownListComponent>) => {
			if (viewChildren.length > lastLength) {
				viewChildren.last.toggle(true);
				viewChildren.last.opened.subscribe(() => {
					if (this.textboxFilter) {
						this.textboxFilter.focus();
					}
				});
				viewChildren.last.focus();
			}
			lastLength = viewChildren.length;
		});
		this.advFilters[1].editorTemplate = this.advFilterFieldTemplate;
	}
}
