<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Line Chart'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="default">Default</option>
					<option value="severity">Severity</option>
					<option value="status">Status</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<ng-container *ngIf="currLive == 'default'">
					<kendo-chart
						[transitions]="true"
						[ngClass]="{ 'hand-cursor': isOverLegend }"
						(legendItemHover)="isOverLegend = true"
						(legendItemLeave)="isOverLegend = false"
						[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }"
						class="show-default-light"
					>
						<kendo-chart-value-axis>
							<kendo-chart-value-axis-item [title]="{ text: 'Count' }">
							</kendo-chart-value-axis-item>
					  	</kendo-chart-value-axis>
						<kendo-chart-category-axis>
							<kendo-chart-category-axis-item
								[categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']"
								[majorGridLines]="{visible: false}"
								[title]="{ text: 'Months' }"
							> </kendo-chart-category-axis-item>
						</kendo-chart-category-axis>
						<kendo-chart-series>
							<kendo-chart-series-item
								*ngFor="let item of defaultLightSeries"
								[type]="'line'"
								[name]="item.name"
								[data]="item.data"
								[dashType]="item.dashType"
								[color]="item.color"
							>
								<kendo-chart-series-item-tooltip>
									<ng-template
										let-category="category"
										let-value="value"
										let-series="series"
									>
										<div class="toolTip">
											<div>{{ category }} {{ series.name }}</div>
											<div>
												{{ value }}
											</div>
										</div>
									</ng-template>
								</kendo-chart-series-item-tooltip>
							</kendo-chart-series-item>
						</kendo-chart-series>
						<kendo-chart-legend
							position="right"
							orientation="vertical"
						>
							<kendo-chart-legend-item
								[visual]="labelsVisual"
								cursor="pointer"
							> </kendo-chart-legend-item>
						</kendo-chart-legend>
					</kendo-chart>
					
					<kendo-chart
						[transitions]="true"
						[ngClass]="{ 'hand-cursor': isOverLegend }"
						(legendItemHover)="isOverLegend = true"
						(legendItemLeave)="isOverLegend = false"
						[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }"
						class="show-default-dark"
					>
						<kendo-chart-value-axis>
							<kendo-chart-value-axis-item [title]="{ text: 'Count' }">
							</kendo-chart-value-axis-item>
					  </kendo-chart-value-axis>
						<kendo-chart-category-axis>
							<kendo-chart-category-axis-item
								[categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']"
								[majorGridLines]="{visible: false}"
								[title]="{ text: 'Months' }"
							> </kendo-chart-category-axis-item>
						</kendo-chart-category-axis>
						<kendo-chart-series>
							<kendo-chart-series-item
								*ngFor="let item of defaultLightSeries"
								[type]="'line'"
								[name]="item.name"
								[data]="item.data"
								[dashType]="item.dashType"
								[color]="item.color"
							>
								<kendo-chart-series-item-tooltip>
									<ng-template
										let-category="category"
										let-value="value"
										let-series="series"
									>
										<div class="toolTip">
											<div>{{ category }} {{ series.name }}</div>
											<div>
												{{ value }}
											</div>
										</div>
									</ng-template>
								</kendo-chart-series-item-tooltip>
							</kendo-chart-series-item>
						</kendo-chart-series>
						<kendo-chart-legend
							position="right"
							orientation="vertical"
						>
							<kendo-chart-legend-item
								[visual]="labelsVisual"
								cursor="pointer"
							> </kendo-chart-legend-item>
						</kendo-chart-legend>
					</kendo-chart>
				</ng-container>
				<ng-container *ngIf="currLive == 'severity'">
					<kendo-chart
						[transitions]="true"
						[ngClass]="{ 'hand-cursor': isOverLegend }"
						(legendItemHover)="isOverLegend = true"
						(legendItemLeave)="isOverLegend = false"
						[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }"
						class="show-severity-light"
					>
						<kendo-chart-value-axis>
							<kendo-chart-value-axis-item [title]="{ text: 'Detection count' }">
							</kendo-chart-value-axis-item>	
						</kendo-chart-value-axis>
						<kendo-chart-category-axis>
							<kendo-chart-category-axis-item
								[categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']"
								[majorGridLines]="{visible: false}"
								[title]="{ text: 'Months' }"
							> </kendo-chart-category-axis-item>
						</kendo-chart-category-axis>
						<kendo-chart-series>
							<kendo-chart-series-item
								*ngFor="let item of severityLightSeries"
								[type]="'line'"
								[name]="item.name"
								[data]="item.data"
								[dashType]="item.dashType"
								[color]="item.color"
							>
								<kendo-chart-series-item-tooltip>
									<ng-template
										let-category="category"
										let-value="value"
										let-series="series"
									>
										<div class="toolTip">
											<div>{{ category }} {{ series.name }}</div>
											<div>
												{{ value }}
											</div>
										</div>
									</ng-template>
								</kendo-chart-series-item-tooltip>
							</kendo-chart-series-item>
						</kendo-chart-series>
						<kendo-chart-legend
							position="right"
							orientation="vertical"
						>
							<kendo-chart-legend-item
								[visual]="labelsVisual"
								cursor="pointer"
							> </kendo-chart-legend-item>
						</kendo-chart-legend>
					</kendo-chart>
					
					<kendo-chart
						[transitions]="true"
						[ngClass]="{ 'hand-cursor': isOverLegend }"
						(legendItemHover)="isOverLegend = true"
						(legendItemLeave)="isOverLegend = false"
						[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }"
						class="show-severity-dark"
					>
						<kendo-chart-value-axis>
							<kendo-chart-value-axis-item [title]="{ text: 'Detection count' }">
							</kendo-chart-value-axis-item>	
						</kendo-chart-value-axis>
						<kendo-chart-category-axis>
							<kendo-chart-category-axis-item
								[categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']"
								[majorGridLines]="{visible: false}"
								[title]="{ text: 'Months' }"
							> </kendo-chart-category-axis-item>
						</kendo-chart-category-axis>
						<kendo-chart-series>
							<kendo-chart-series-item
								*ngFor="let item of severityLightSeries"
								[type]="'line'"
								[name]="item.name"
								[data]="item.data"
								[dashType]="item.dashType"
								[color]="item.color"
							>
								<kendo-chart-series-item-tooltip>
									<ng-template
										let-category="category"
										let-value="value"
										let-series="series"
									>
										<div class="toolTip">
											<div>{{ category }} {{ series.name }}</div>
											<div>
												{{ value }}
											</div>
										</div>
									</ng-template>
								</kendo-chart-series-item-tooltip>
							</kendo-chart-series-item>
						</kendo-chart-series>
						<kendo-chart-legend
							position="right"
							orientation="vertical"
						>
							<kendo-chart-legend-item
								[visual]="labelsVisual"
								cursor="pointer"
							> </kendo-chart-legend-item>
						</kendo-chart-legend>
					</kendo-chart>
				</ng-container>
				<ng-container *ngIf="currLive == 'status'">
					<kendo-chart
						[transitions]="true"
						[ngClass]="{ 'hand-cursor': isOverLegend }"
						(legendItemHover)="isOverLegend = true"
						(legendItemLeave)="isOverLegend = false"
						[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }"
						class="show-status-light"
					>
						<kendo-chart-value-axis>
							<kendo-chart-value-axis-item [title]="{ text: 'Connector count' }">
							</kendo-chart-value-axis-item>
						</kendo-chart-value-axis>
						<kendo-chart-category-axis>
							<kendo-chart-category-axis-item
								[categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']"
								[majorGridLines]="{visible: false}"
								[title]="{ text: 'Months' }"
							> </kendo-chart-category-axis-item>
						</kendo-chart-category-axis>
						<kendo-chart-series>
							<kendo-chart-series-item
								*ngFor="let item of statusLightSeries"
								[type]="'line'"
								[name]="item.name"
								[data]="item.data"
								[dashType]="item.dashType"
								[color]="item.color"
							>
								<kendo-chart-series-item-tooltip>
									<ng-template
										let-category="category"
										let-value="value"
										let-series="series"
									>
										<div class="toolTip">
											<div>{{ category }} {{ series.name }}</div>
											<div>
												{{ value }}
											</div>
										</div>
									</ng-template>
								</kendo-chart-series-item-tooltip>
							</kendo-chart-series-item>
						</kendo-chart-series>
						<kendo-chart-legend
							position="right"
							orientation="vertical"
						>
							<kendo-chart-legend-item
								[visual]="labelsVisual"
								cursor="pointer"
							> </kendo-chart-legend-item>
						</kendo-chart-legend>
					</kendo-chart>
					
					<kendo-chart
						[transitions]="true"
						[ngClass]="{ 'hand-cursor': isOverLegend }"
						(legendItemHover)="isOverLegend = true"
						(legendItemLeave)="isOverLegend = false"
						[popupSettings]="{ popupClass: 'kendo-chart-tooltip' }"
						class="show-status-dark"
					>
						<kendo-chart-value-axis>
							<kendo-chart-value-axis-item [title]="{ text: 'Connector count' }">
							</kendo-chart-value-axis-item>
						</kendo-chart-value-axis>
						<kendo-chart-category-axis>
							<kendo-chart-category-axis-item
								[categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']"
								[majorGridLines]="{visible: false}"
								[title]="{ text: 'Months' }"
							> </kendo-chart-category-axis-item>
						</kendo-chart-category-axis>
						<kendo-chart-series>
							<kendo-chart-series-item
								*ngFor="let item of statusLightSeries"
								[type]="'line'"
								[name]="item.name"
								[data]="item.data"
								[dashType]="item.dashType"
								[color]="item.color"
							>
								<kendo-chart-series-item-tooltip>
									<ng-template
										let-category="category"
										let-value="value"
										let-series="series"
									>
										<div class="toolTip">
											<div>{{ category }} {{ series.name }}</div>
											<div>
												{{ value }}
											</div>
										</div>
									</ng-template>
								</kendo-chart-series-item-tooltip>
							</kendo-chart-series-item>
						</kendo-chart-series>
						<kendo-chart-legend
							position="right"
							orientation="vertical"
						>
							<kendo-chart-legend-item
								[visual]="labelsVisual"
								cursor="pointer"
							> </kendo-chart-legend-item>
						</kendo-chart-legend>
					</kendo-chart>
				</ng-container>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-started">
			<h3 id="subSectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<p>Added to your project's app.module.ts file:</p>
			<code style="margin-top: 10px">import &#123; ChartsModule &#125; from
				'&#64;progress/kendo-angular-charts';</code>
			<code style="margin-top: 10px">import 'hammerjs';</code>
		</section>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<div class="content">
				<pre prism class="dark">
					{{ lineChartHTML }}
				</pre>
				<pre prism class="dark">
					{{ lineChartTs }}
				</pre>
			</div>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/charts/api/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Charting API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/line-chart#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>

					<span class="page-style-list-title">Default</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultSeverity" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Status</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultStatus" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Tooltip</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesTooltipDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<span class="page-style-list-title">Default</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Severity</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkSeverity" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Status</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkStatus" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Tooltip</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesTooltipDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Tooltip</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTooltipTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Tooltip</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTooltipStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-legend-visibility">
			<h3 id="subSectionTitleBehaviorsLegend" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-legend-visibility')">
				Legend visibility <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					The chart’s legend displays the names of the data series. Selecting a legend
					item toggles the visibility of that data series' data points and line. When
					a data series is hidden, its legend item appears greyed out with a
					strikethrough.
				</li>
				<li class="page-section-list-item">
					All data should be displayed by default. Users can then decide what they
					want to hide or show.
				</li>
				<li class="page-section-list-item">
					Always show the legend, unless there's only one data series. In that case,
					if you choose to not show the legend, make sure to use a title for the chart
					that describes what the data represents.
				</li>
				<li class="page-section-list-item">
					For more information, read <a
						class="page-kendo-link"
						target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/charts/elements/legend/"
					><svg
							xmlns="http://www.w3.org/2000/svg"
							width="18px"
							height="18"
							viewBox="0 0 49 60.3"
						>
							<path
								fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z"
							>
							</path>
							<path
								fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z"
							>
							</path>
						</svg>Kendo's legend guide</a>.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-tooltip">
			<h3 id="subSectionTitleBehaviorsTooltip" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-tooltip')">
				Tooltip <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Hovering over a line displays a tooltip for the data point closest to the
					mouse on that line.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-do-list-title">Do use line charts:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							If you have a continuous dataset that changes over time
						</li>
						<li class="page-section-list-item">
							If your dataset is too big for a bar chart
						</li>
						<li class="page-section-list-item">
							If you want to display multiple series for the same timeline
						</li>
						<li class="page-section-list-item">
							If you want to visualize trends instead of exact values 
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top: 5px">
					<span class="page-section-do-list-title">Don’t use line charts:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							If you have a small dataset
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Labels</span>
					Line chart labels should be short, while still clearly describing the data.
					Use sentence case.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Tooltips</span>
					Line chart tooltips should contain the data value and name.
					<a href="components/tooltip#variations-chart-tooltips">Review tooltip guidance</a> for more best practices.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-severity">
			<h3 id="subSectionTitleUsageSeverity" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-severity')">
				Severity <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					If you’re using a line chart to visualize severity, use the labels outlined in <a
						href="guidelines/severity">severity guidelines</a> and the colors in the <a
						href="components/line-chart#styles">styles section</a>.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-status">
			<h3 id="subSectionTitleUsageStatus" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-status')">
				Status <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					If you’re using a line chart to visualize statuses, the statuses are
					success, warning, error,
					and informational. Refer to the colors in the <a href="components/line-chart#styles">styles
						section</a>.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/bar-chart">Bar chart component</a>
			</li>
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="guidelines/data-visualization">Data visualization guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/severity">Severity guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tooltip">Tooltip component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Line Chart Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>