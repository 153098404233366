<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Icon'" [includeDescription]="true"
			[otherNames]="false"></app-external-resource>
	</section>

	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="default">Default - 48px</option>
					<option value="status">Status color - 48px</option>
					<option value="product">Product icon - 48px</option>
					<option value="branded">Branded icon - 48px</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<div class="live-example" [class.page-live-show]="currLive === 'default'">
					<kendo-svg-icon [icon]="binoculars" class="sh-large-icon"></kendo-svg-icon>
					<kendo-svg-icon [icon]="bridge" class="sh-large-icon"></kendo-svg-icon>
					<kendo-svg-icon [icon]="report" class="sh-large-icon"></kendo-svg-icon>
				</div>
				<div class="live-example" [class.page-live-show]="currLive === 'status'">
					<kendo-svg-icon [icon]="success" themeColor="success" class="sh-large-icon"></kendo-svg-icon>
					<kendo-svg-icon [icon]="warning" themeColor="warning" class="sh-large-icon"></kendo-svg-icon>
					<kendo-svg-icon [icon]="error" themeColor="error" class="sh-large-icon"></kendo-svg-icon>
					<kendo-svg-icon [icon]="info" themeColor="info" class="sh-large-icon"></kendo-svg-icon>
				</div>
				<div class="live-example" [class.page-live-show]="currLive === 'product'">
					<kendo-svg-icon [icon]="btInsights" class="sh-large-icon"></kendo-svg-icon>
					<kendo-svg-icon [icon]="btPRA" class="sh-large-icon"></kendo-svg-icon>
					<kendo-svg-icon [icon]="btEPMWinMac" class="sh-large-icon"></kendo-svg-icon>
					<kendo-svg-icon [icon]="btEPMLinux" class="sh-large-icon"></kendo-svg-icon>
				</div>
				<div class="live-example" [class.page-live-show]="currLive === 'branded'">
					<kendo-svg-icon [icon]="github" class="sh-large-icon"></kendo-svg-icon>
					<kendo-svg-icon [icon]="ping" class="sh-large-icon"></kendo-svg-icon>
					<kendo-svg-icon [icon]="okta" class="sh-large-icon"></kendo-svg-icon>
				</div>
			</div>
		</form>
	</section>

	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>

			<pre prism class="dark">
				{{ iconHTML }}
			</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/icons/api/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Icon API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/icon#behaviors">behaviors section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>

	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Status</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultStatus" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Status</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkStatus" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-display-only">
			<h3 id="subSectionTitleBehaviorsDisplayOnly" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-display-only')">
				Display only <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Selectable icons are
					<a href="components/button">buttons</a>. Unlike selectable
					icons, display-only icons can't be interacted with in any
					way.
				</li>
			</ul>
		</section>
	</section>

	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneralUse" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Use icons that are in our icon library. Icons should be used
					to increase comprehension, not for decoration. Don't clutter
					the screen by overusing icons or rely on them alone to
					communicate.
				</li>
			</ul>
		</section>
		
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Icons should be accompanied by meaningful and complementary
					text. If there is no visible text displayed next to the
					icon, an icon must have an aria-label.
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
									width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
									<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
										fill-rule="evenodd">
										<path
											d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
											id="" fill="#000000" />
									</g>
								</svg>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										<kendo-svg-icon [icon]="plusIcon" class="sh-small-icon"></kendo-svg-icon>
										Add to library
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
									width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
									<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
										fill-rule="evenodd">
										<path
											d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
											id="add2" fill="#000000"
											transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
									</g>
								</svg>
								Not This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										<kendo-svg-icon [icon]="plusIcon" class="sh-small-icon"></kendo-svg-icon>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					For more guidance on selectable icons, refer to the icon
					variation of the
					<a href="components/button">button component</a>. For all
					other icon help, refer to the
					<a href="guidelines/icons">icon guidelines</a>.
				</li>
			</ul>
		</section>

		<section class="page-sub-section" id="usage-size-and-style">
			<h3 id="subSectionTitleUsageSizeAndStyle" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-size-and-style')">
				Size and style <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Icons can be small, medium, or large. Medium icons are 24px
					and are typically used in menus. Smaller icons can be used
					in data grids, instructional text, and input fields. Icons
					shouldn't be smaller than 16px to keep them accessible.
					Larger icons can be used for wayfinding and emphasis when
					there is enough space.
				</li>
				<li class="page-section-list-item">
					Icons should be filled and need to have a contrast ratio of
					4:5:1 to be accessible.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/help-text">Help text component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/icons">Icon guidelines</a>
			</li>
		</ul>
		<span class="page-related-list-title">Components and patterns that use icons:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/breadcrumb">Breadcrumb component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/button-group">Button group component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/data-grid">Data grid component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/date-picker">Date picker component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/progress-indicator">Progress indicator component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tab">Tab component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tag">Tag component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/text-field">Text field component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/text-link">Text link component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/time-picker">Time picker component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/toast">Toast component</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
					let subItem of changelogService.getChangelogBySection(
						'Icon Component'
					)
				">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
						subItem.workId
					">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>