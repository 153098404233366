<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Severity'" [includeDescription]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="default">Default</option>
					<option value="stacked">Stacked</option>
					<option value="count">Count</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<div class="sh-severity-example" [class.page-live-show]="currLive === 'default'">
					<div class="sh-severity-dots sh-severity-critical">
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">Critical Severity</span>
					</div>

					<div class="sh-severity-dots sh-severity-high">
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">High Severity</span>
					</div>

					<div class="sh-severity-dots sh-severity-moderate">
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">Moderate Severity</span>
					</div>

					<div class="sh-severity-dots sh-severity-low">
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">Low Severity</span>
					</div>

					<div class="sh-severity-dots">
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">No Severity</span>
					</div>
				</div>

				<div class="sh-severity-example" [class.page-live-show]="currLive === 'stacked'">
					<div class="sh-severity-dots sh-severity-critical sh-severity-stacked">
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">Critical Severity</span>
					</div>

					<div class="sh-severity-dots sh-severity-high sh-severity-stacked">
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">High Severity</span>
					</div>

					<div class="sh-severity-dots sh-severity-moderate sh-severity-stacked">
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">Moderate Severity</span>
					</div>

					<div class="sh-severity-dots sh-severity-low sh-severity-stacked">
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">Low Severity</span>
					</div>

					<div class="sh-severity-dots sh-severity-stacked">
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">No Severity</span>
					</div>
				</div>

				<div class="sh-severity-example" [class.page-live-show]="currLive === 'count'">
					<div class="sh-severity-dots sh-severity-critical sh-severity-count">
						<span class="sh-severity-number">72</span>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">Critical Severity</span>
					</div>

					<div class="sh-severity-dots sh-severity-high sh-severity-count">
						<span class="sh-severity-number">144</span>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">High Severity</span>
					</div>

					<div class="sh-severity-dots sh-severity-moderate sh-severity-count">
						<span class="sh-severity-number">253</span>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">Moderate Severity</span>
					</div>

					<div class="sh-severity-dots sh-severity-low sh-severity-count">
						<span class="sh-severity-number">721</span>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">Low Severity</span>
					</div>

					<div class="sh-severity-dots sh-severity-count">
						<span class="sh-severity-number">5</span>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<div class="dot" aria-hidden="true"></div>
						<span class="sh-severity-label">No Severity</span>
					</div>
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark" [hidden]="this.currLive !== 'default'">
				{{ severityDefaultHTML }}
			</pre>
			<pre prism class="dark" [hidden]="this.currLive !== 'stacked'">
				{{ severityStackedHTML }}
			</pre>
			<pre prism class="dark" [hidden]="this.currLive !== 'count'">
				{{ severityCountHTML }}
			</pre>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title page-section-title-empty"
			title="Copy Link to Clipboard" (click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-section-content">
			There are no behaviors for this component.
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-default">
			<h3 id="sectionTitleVariationsDefault" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-default')">
				Default <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Indicator dots appear to the left of the supporting text.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-stacked">
			<h3 id="sectionTitleVariationsStacked" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-stacked')">
				Stacked <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Indicator dots appear above the supporting text.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-count">
			<h3 id="sectionTitleVariationsCount" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-count')">
				Count <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Indicator dots can appear alongside a number. This is the
					numerical count of instances with the corresponding
					severity. The dots are to the right of the number and the
					supporting label is below both the dots and the number.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-content">
			<h3 id="sectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When using the dots to display severity, use these labels
					for the corresponding score:
					<ul class="bulleted-list">
						<li>Low</li>
						<li>Moderate</li>
						<li>High</li>
						<li>Critical</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					When using the dots to display privilege, use these labels
					for the corresponding level:
					<ul class="bulleted-list">
						<li>No Privilege</li>
						<li>Low</li>
						<li>Moderate</li>
						<li>High</li>
						<li>Highest</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Undetermined can be used when there is not enough
					information to display an accurate reading.
				</li>
				<li class="page-section-list-item">
					When there is no data at all to determine severity, a null value is displayed
					using two dashes “--” or a helpful empty state message, depending on the
					context.
				</li>
				<li class="page-section-list-item">
					Like in the <b>No Privilege</b> example above, a second word can be added to the label
					to increase understanding.
				</li>
				<li class="page-section-list-item">
					Read more about communicating severity in
					<a href="guidelines/severity">the guidelines</a>.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="guidelines/severity">Severity guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>
		<span class="page-related-list-title">Components and patterns that use severity:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/bar-chart">Bar chart component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/data-grid">Data grid component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/donut-chart">Donut chart component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/pie-chart">Pie chart component</a>
			</li>
			<!-- <li class="page-related-list-item">
				<a href="components/tags">Tag component</a>
			</li> -->
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
					let subItem of changelogService.getChangelogBySection(
						'Severity Component'
					)
				">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
						subItem.workId
					">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
