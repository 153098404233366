<main class="page-main">
	<section class="page-section" id="principles">
		<h2
			id="sectionTitlePrinciples"
			class="page-section-title"
			title="Copy Link to Clipboard"
			(click)="copyToClipboard('principles')"
		>
			Changelog <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li *ngFor="let item of changelogService.getChangelogDates()">
				<span style="
						font-size: 18px;
						font-family: 'OpenSans-Semibold';
						margin-bottom: 5px;
					"
				>
					{{ changelogService.formatDate(item) }}
				</span>
				<ul class="changelog-list">
					<li
						class="changelog-list-item"
						*ngFor="
							let subItem of changelogService.getChangelogByDate(
								item
							)
						"
					>
						<a
							class="changelog-list-item-link"
							target="_blank"
							*ngIf="subItem.workId != ''"
							[attr.href]="
								'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
								subItem.workId
							"
							>[{{ subItem.workId }}]</a
						>
						{{ subItem.changeName }} 
						<span *ngIf="subItem.sectionName"> - </span>
						<span class="changelog-list-item-title">{{
							subItem.sectionName
						}}</span>
					</li>
				</ul>
			</li>
		</ul>
	</section>
</main>
