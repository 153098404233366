import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCheck, shXmark, shLink } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-severity',
	templateUrl: './severity.component.html',
	styleUrls: ['./severity.component.less'],
})
export class SeverityGuidelineComponent {
	public currVisible = 'overview';

	public doThisIcon = shCheck;
	public notThisIcon = shXmark;
	public linkIcon = shLink;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Principles',
			value: 'principles',
			empty: false,
			children: [],
		},
		{
			name: 'Demonstrating severity',
			value: 'demonstrating-severity',
			empty: false,
			children: [
				{
					name: 'Communicating severity with color',
					value: 'with-color',
				},
				{
					name: 'Communicating severity with words',
					value: 'with-words',
				},
				{
					name: 'Communicating severity with components',
					value: 'with-components',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
