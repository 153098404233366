import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import {
	severityStylesDefaultTheme,
	severityStylesDarkTheme,
	severityStylesTypography,
	severityStylesStructure,
} from './severity-styles';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shLink } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-severity',
	templateUrl: './severity.component.html',
	styleUrls: ['./severity.component.less'],
})
export class SeverityComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public linkIcon = shLink;
	
	// Imported Style Variables
	public stylesDefault = severityStylesDefaultTheme;
	public stylesDark = severityStylesDarkTheme;
	public stylesTypography = severityStylesTypography;
	public stylesStructure = severityStylesStructure;
	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: true,
			children: [],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Default',
					value: 'default',
				},
				{
					name: 'Stacked',
					value: 'stacked',
				},
				{
					name: 'Count',
					value: 'count',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Content',
					value: 'content',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	public severityDefaultHTML: any = `
<div class="sh-severity-dots sh-severity-critical">
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">Critical Severity</span>
</div>

<div class="sh-severity-dots sh-severity-high">
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">High Severity</span>
</div>

<div class="sh-severity-dots sh-severity-moderate">
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">Moderate Severity</span>
</div>

<div class="sh-severity-dots sh-severity-low">
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">Low Severity</span>
</div>

<div class="sh-severity-dots">
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">No Severity</span>
</div>`;

	public severityStackedHTML: any = `
<div class="sh-severity-dots sh-severity-critical sh-severity-stacked">
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">Critical Severity</span>
</div>

<div class="sh-severity-dots sh-severity-high sh-severity-stacked">
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">High Severity</span>
</div>

<div class="sh-severity-dots sh-severity-moderate sh-severity-stacked">
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">Moderate Severity</span>
</div>

<div class="sh-severity-dots sh-severity-low sh-severity-stacked">
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">Low Severity</span>
</div>

<div class="sh-severity-dots sh-severity-stacked">
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">No Severity</span>
</div>`;

	public severityCountHTML: any = `
<div class="sh-severity-dots sh-severity-critical sh-severity-count">
	<span class="sh-severity-number">72</span>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">Critical Severity</span>
</div>

<div class="sh-severity-dots sh-severity-high sh-severity-count">
	<span class="sh-severity-number">144</span>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">High Severity</span>
</div>

<div class="sh-severity-dots sh-severity-moderate sh-severity-count">
	<span class="sh-severity-number">253</span>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">Moderate Severity</span>
</div>

<div class="sh-severity-dots sh-severity-low sh-severity-count">
	<span class="sh-severity-number">721</span>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">Low Severity</span>
</div>

<div class="sh-severity-dots sh-severity-count">
	<span class="sh-severity-number">5</span>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<div class="dot" aria-hidden="true"></div>
	<span class="sh-severity-label">No Severity</span>
</div>`;

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}
	
	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
