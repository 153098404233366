import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { shLink } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-data-visualization',
	templateUrl: './data-visualization.component.html',
	styleUrls: ['./data-visualization.component.less'],
})
export class DataVisualizationComponent {
	public currVisible = 'overview';
	public linkIcon = shLink;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Principles',
			value: 'principles',
			empty: false,
			children: [],
		},
		{
			name: 'Choosing the right chart',
			value: 'choosing-the-right-chart',
			empty: false,
			children: [
				{
					name: 'What type of data is it?',
					value: 'data-type',
				},
				{
					name: 'What are you trying to say about it?',
					value: 'trying-to-say',
				},
				{
					name: 'Bar',
					value: 'bar',
				},
				{
					name: 'Donut and pie',
					value: 'donut-and-pie',
				},
				{
					name: 'Line',
					value: 'line',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		public changelogService: ChangelogService,
		private clipboardService: ClipboardService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
