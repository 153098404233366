<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Motion, or animation, is the speed at which UI elements change.
			</li>
		</ul>
	</section>
	<section class="page-section" id="howto">
		<h2 id="sectionTitleHowTo" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('howto')">
			How to use motion  <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="howto-consistently">
			<h3 id="subSectionTitleFirstConsistently" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('howto-consistently')">
				Consistently <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Most of our animations have a duration of 250 milliseconds. Use this speed
					consistently, unless the element has a long way to travel. Read below.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="howto-variably">
			<h3 id="subSectionTitleFirsyVariably" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('howto-variably')">
				Variably <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					The longer something has to travel, the slower it should animate. It’s jarring
					to have an animation speed of 250 milliseconds for something that has to move
					across the entire screen. This typically does not happen until after 500px of movement.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="howto-limitedly">
			<h3 id="subSectionTitleFirstLimitedly" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('howto-limitedly')">
				Limitedly <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Use animation only for interactions — do not animate page transitions. Motion should
					enhance the user experience by making interactions more fluid, not be used for decoration.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Motion Guidelines'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
